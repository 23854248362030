import React from "react";
import UserCard from "./UserCard.jsx";

const UserCards = (props) => {
  return (
    <div className={`w-full ${props.size === "sm" ? "h-[210px]" : "h-[240px]"} relative`}>
      <UserCard
        transform="scale(0.8) translateY(-60%)"
        right="-5%"
        blur="2px"
        size={props.size}
      />
      <UserCard
        transform="scale(0.9) translateY(-55%)"
        right="5%"
        blur="2px"
        size={props.size}
      />
      <UserCard
        transform="scale(0.8) translateY(-60%)"
        left="-5%"
        blur="2px"
        size={props.size}
      />
      <UserCard
        transform="scale(0.9) translateY(-55%)"
        left="5%"
        blur="2px"
        size={props.size}
      />
      <UserCard left="15%" size={props.size} />
    </div>
  );
};

export default UserCards;
