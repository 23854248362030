import React from "react";
import "../../styles/Versions.css";
import { useDispatch, useSelector } from "react-redux";
import { setImageVersion } from "store/postSlice";

const ImageVersions = ({ isVertical }) => {
  const dispatch = useDispatch();
  const { imageVersion, post } = useSelector((state) => state.post);
  const images = post?.aiImages || [];
  const versionsLength = images?.length;
  const versionArray = new Array(versionsLength).fill(null);
  const changeVersion = (newVersion) => {
    if (newVersion === imageVersion) return;
    dispatch(setImageVersion(newVersion));
  };
  return (
    <div
      className={`version-container ${isVertical ? "vertical" : "horizontal"}`}
    >
      {versionArray.length > 1 &&
        versionArray.map((_, index) => (
          <div
            key={index}
            style={{
              display: "inherit",
              alignItems: "inherit",
              gap: "inherit",
              flexDirection: "inherit",
            }}
          >
            <div
              className={`version-bubble link ${
                imageVersion === index ? "active" : ""
              }`}
              onClick={(e) => changeVersion(index)}
            >
              V{index + 1}
            </div>
            {versionsLength - 1 > index && <div className="version-line"></div>}
          </div>
        ))}
    </div>
  );
};

export default ImageVersions;
