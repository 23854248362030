import { useCallback } from "react";

const useError = () => {
  const isErrorStatus = useCallback(async (error, status = 401, callback = async () => {}) => {
    if (error?.response?.status === status) {
      await callback();
      return true
    } 
    return false
  }, [])
  return {
    isErrorStatus,
  };
};

export default useError;
