import { INTERCOM_EVENTS, PAGE_TYPES } from "Utils";
import { SERVER_URL } from "api";
import axios from "axios";
import useUserInfo from "hooks/useUserInfo";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";

const Intercom = ({ page = PAGE_TYPES.REGISTRATION }) => {
  const { update, trackEvent } = useIntercom();
  const [hash, setHash] = useState(null);
  const { email, firstName: name, linkedin } = useUserInfo().profile;
  const { step } = useSelector((state) => state.register);
  const post = useSelector((state) => state.post);

  useEffect(() => {
    if (page === PAGE_TYPES.MEETING) update({ hideDefaultLauncher: true });
    else update({ hideDefaultLauncher: false });
  }, [page, update]);

  useEffect(() => {
    if (page === PAGE_TYPES.POSTS && post.topic) {
      trackEvent(INTERCOM_EVENTS.visitPostPage, { topic: post.topic });
    }
  }, [page, post.topic, trackEvent]);

  useEffect(() => {
    const getHash = async (value) => {
      try {
        const response = await axios.post(
          `${SERVER_URL}/services/getUserHash`,
          { email: value }
        );
        setHash(response.data);
      } catch (error) {
        console.log(`failed to fetch user's hash : ${error}`);
      }
    };
    if (email && (page !== PAGE_TYPES.REGISTRATION || step > 0)) getHash(email);
  }, [email, step, page]);

  useEffect(() => {
    if (page === PAGE_TYPES.REGISTRATION) {
      if (step > 0 && hash) {
        update({
          email,
          name,
          userHash: hash,
          customAttributes: {
            linkedin,
            app: "Jessica",
          },
        });
      }
    }
  }, [page, step, hash, email, name, linkedin, update]);

  useEffect(() => {
    if (page !== PAGE_TYPES.REGISTRATION && email && hash) {
      if (hash) {
        update({
          email,
          name,
          userHash: hash,
          customAttributes: {
            linkedin,
            app: "Jessica",
          },
        });
      }
    }
  }, [page, hash, email, name, linkedin, update]);

  return <></>;
};

export default Intercom;
