import React from "react";

const Input = ({ value, onChange, label, placeholder, className }) => {
  return (
    <div className={`w-full relative ${className}`}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="bg-transparent w-full pr-11 py-4 pl-4 outline-none border border-other-outlined-border rounded-full"
      />
      <div className="absolute top-0 left-4 -translate-y-1/2 text-text-secondary bg-off-white-light">
        {label}
      </div>
    </div>
  );
};

export default Input;
