import React from "react";
import linkedin from "assets/images/register/linkedin.svg";
import dots from "assets/images/register/dots-horizontal.svg";
import avatar from "assets/images/register/Avatar.png";
import arrow from "assets/images/register/arrow-narrow-up-right.svg";
import message from "assets/images/register/message-square-02.svg";
import fire from "assets/images/register/fire.svg";
import eye from "assets/images/register/eye.svg";
import thumb from "assets/images/register/thumbs-up.svg";

const UserCard = ({
  blur = false,
  left = "auto",
  right = "auto",
  transform = "scale(1) translateY(-50%)",
  size = "lg",
}) => {
  return (
    <div
      className={`p-4 flex flex-col ${
        size === "lg" ? "gap-3 2xl:gap-5" : "gap-1"
      } rounded-[12px] items-center bg-white max-w-[100%] absolute top-1/2 w-[70%]`}
      style={{
        border: "0.853px solid rgba(19, 19, 19, 0.20)",
        boxShadow:
          "0px 17.056px 20.467px -3.411px rgba(16, 24, 40, 0.08), 0px 6.822px 6.822px -3.411px rgba(16, 24, 40, 0.03)",
        transform,
        right,
        left,
        filter: `blur(${blur})`,
      }}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex gap-2 items-center">
          <div className="text-blue-grey font-inter text-[10px] leading-[15px]">
            Posted on:
          </div>
          <img src={linkedin} alt="linkedin" className="h-5 w-5" />
          <div className="text-blue-grey font-inter text-[10px] leading-[15px]">
            Dec 18
          </div>
        </div>
        <div className="p-1.5 text-[#F2F4F7]">
          <img src={dots} alt="dots" className="w-2.5 h-2.5" />
        </div>
      </div>
      <div
        className={`flex flex-col ${
          size === "lg" ? "gap-2 2xl:gap-4" : "gap-0.5"
        } items-start w-full`}
      >
        <div className="flex flex-row gap-1.5 w-full">
          <img src={avatar} alt="user" className="w-7 h-7" />
          <div className="flex flex-col justify-center items-start flex-1 w-[calc(100%-34px)]">
            <div className="text-black font-inter text-[10px] font-semibold leading-4">
              Noah Pierre
            </div>
            <div className="w-full truncate self-stretch overflow-hidden text-blue-grey font-inter text-[10px] leading-[15px]">
              Skilled People Leadership, Data-driven Marketer, Martech,Lead
              Generator, Content Strategist, Brand Builder, Skilled in PR &
              Communications
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start w-full">
          <div className="self-stretch text-black-bg font-inter text-[12px] font-semibold leading-[17px] truncate w-full">
            Thoughtlessness. The era of thought is ended.
          </div>
          <div className="self-stretch text-black-bg font-inter text-[10px] leading-[15px] h-[30px] text-ellipsis overflow-hidden">
            Nunc proin eu eu est risus aliquet aenean nisl. Vel vitae
            consectetur nullam neque quis id congue gravida elituem dolar
          </div>
        </div>
        <div className="gap-1 flex flex-row items-center">
          <div className="text-blue-grey font-inter text-[10px] leading-[15px]">
            Based On:
          </div>
          <div className="flex px-1.5 py-0.5 items-center gap-1 rounded-[14px] bg-primary-50 mix-blend-multiply">
            <img src={arrow} alt="arrow" className="w-2.5 h-2.5" />
            <div className="text-primary-700 text-[10px]">Trending</div>
          </div>
          <div className="px-1.5 py-0.5 items-center gap-1 rounded-[14px] bg-primary-50 mix-blend-multiply text-primary-700 text-[10px] hidden sm:block md:hidden lg:block">
            #thoughtlessness
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <div className="gap-1 flex items-center">
            <img src={message} alt="message" className="w-3.5 h-3.5" />
            <span className="text-center text-warning-500 text-[10.23px] font-normal font-inter leading-none">
              179
            </span>
            <img src={fire} alt="fire" className="h-2.5 w-auto" />
          </div>
          <div className="gap-1 flex items-center">
            <img src={eye} alt="eye" />
            <span className="text-center text-blue-grey text-[10.23px] font-normal font-inter leading-none">
              1.31M
            </span>
          </div>
          <div className="gap-1 flex items-center">
            <img src={thumb} alt="thumb-up" />
            <span className="text-center text-blue-grey text-[10.23px] font-normal font-inter leading-none">
              1.1K
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
