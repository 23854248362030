import React from "react";
import "./DateSelector.css";
import { useDispatch, useSelector } from "react-redux";
import { setMonth, setYear, setDate } from "store/preparationSlice";
import { getCurrentDateTime } from "Utils";

const getMonthMatrix = (month, year) => {
  const firstDayOfMonth = new Date(year, month, 1);
  const startingDay = firstDayOfMonth.getDay();
  const totalDays = new Date(year, month + 1, 0).getDate();

  const matrix = [];
  let day = 1,
    nextMonthStart = 1;

  const prevMonthTotalDays = new Date(year, month, 0).getDate();
  const prevMonthStart = prevMonthTotalDays - startingDay + 1;

  for (let week = 0; week < 6; week++) {
    const row = [];

    if (week === 0) {
      for (let i = 0; i < startingDay; i++) {
        row.push({ date: prevMonthStart + i, month: month - 1 });
      }
    }

    for (let weekday = week === 0 ? startingDay : 0; weekday < 7; weekday++) {
      if (day <= totalDays) {
        row.push({ date: day, month });
        day++;
      } else {
        row.push({ date: nextMonthStart, month: month + 1 });
        nextMonthStart++;
      }
    }

    matrix.push(row);

    if (day > totalDays) {
      break;
    }
  }

  // Fill the remaining days from the next month
  if (matrix.length < 6) {
    const nextMonthCount = 6 - matrix.length;

    for (let week = 0; week < nextMonthCount; week++) {
      const row = [];

      for (let weekday = 0; weekday < 7; weekday++) {
        if (day <= totalDays) {
          row.push(day);
          day++;
        } else {
          row.push({ date: nextMonthStart, month: month + 1 });
          nextMonthStart++;
        }
      }
      matrix.push(row);
    }
  }
  return matrix;
};

const DateSelector = () => {
  const dispatch = useDispatch();
  const { year, month, date, timezone } = useSelector(
    (state) => state.preparation
  );

  const today = getCurrentDateTime(timezone);

  const isToday = (date, month, year) =>
    date === today.day && month === today.month && year === today.year;

  const monthMatrix = getMonthMatrix(month, year);

  return (
    <div className="date-selector">
      <div className="calendar-days">
        <div>Su</div>
        <div>M</div>
        <div>Tu</div>
        <div>W</div>
        <div>Th</div>
        <div>Fri</div>
        <div>Sa</div>
      </div>
      {monthMatrix.map((row, rowIndex) => (
        <div key={rowIndex} className="calendar-week">
          {row.map((day, dayIndex) => (
            <div
              key={dayIndex}
              className={`date ${day.month !== month ? "notThisMonth" : ""} ${
                day.month !== month ? "notThisMonth" : ""
              } ${isToday(day.date, day.month, year) ? "today" : ""} ${
                day.date === date && day.month === month ? "selected" : ""
              }`}
              onClick={(e) => {
                if (
                  !(
                    year === today.year &&
                    ((day.month === today.month && day.date < today.day) ||
                      day.month < today.month)
                  )
                )
                  dispatch(setDate(day.date));

                if (day.month !== month) {
                  if (year === today.year && day.month < today.month) return;
                  if (day.month === 12) {
                    dispatch(setYear(year + 1));
                    dispatch(setMonth(0));
                  } else if (day.month === -1) {
                    dispatch(setYear(year - 1));
                    dispatch(setMonth(11));
                  } else {
                    dispatch(setMonth(day.month));
                  }
                }
              }}
            >
              {day.date}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DateSelector;
