import React, { useEffect, useRef, useState } from "react";
import AudioProgressBar from "components/AudioPlayer/AudioProgressBar";
import Button, { ButtonVariant } from "components/Button";
import PauseCircle from "assets/images/podcast/PauseCircle.svg";
import PlayCircle from "assets/images/podcast/PlayCircle.svg";

const PodcastAudio = ({
    className = "",
    src,
    IsPlay = false,
    setIsPlay = () => { },
    onChangeDuration = () => { },
    length = 0
}) => {
    const audioRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [volume] = useState(1);
    const [currentProgress, setCurrentProgress] = useState(0);
    const [buffered, setBuffered] = useState(0);
    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current?.pause();
            setIsPlaying(false);
            setIsPlay(false);
        } else {
            audioRef.current?.play();
            setIsPlaying(true);
            setIsPlay(true);
        }
    };

    useEffect(() => {
        if (length) setDuration((prev) => prev || length);
    }, [length]);

    useEffect(() => {
        setIsPlaying(IsPlay);
        if (audioRef.current) {
            if (audioRef.current.paused && IsPlay) {
                audioRef.current.play();
            } else if (!audioRef.current.paused && !IsPlay) {
                audioRef.current.pause();
            }
        }
    }, [IsPlay]);

    const handleBufferProgress = (e) => {
        const audio = e.currentTarget;
        const dur = audio.duration;
        if (dur > 0) {
            for (let i = 0; i < audio.buffered.length; i++) {
                if (
                    audio.buffered.start(audio.buffered.length - 1 - i) <
                    audio.currentTime
                ) {
                    const bufferedLength = audio.buffered.end(
                        audio.buffered.length - 1 - i
                    );
                    setBuffered(bufferedLength);
                    break;
                }
            }
        }
    };
    return (
        <div
            className={`w-full flex sm:gap-2.5 items-center px-2 sm:px-5 ${className}`}
        >
            <audio
                ref={audioRef}
                preload="metadata"
                className="hidden"
                onDurationChange={(e) => {
                    if (Number.isFinite(e.currentTarget?.duration)) {
                        onChangeDuration(e.currentTarget.duration);
                        setDuration((prev) => prev || e.currentTarget?.duration || 0);
                    }
                }}
                onCanPlay={(e) => {
                    e.currentTarget.volume = volume;
                    setIsReady(true);
                }}
                onPlaying={() => {
                    setIsPlaying(true);
                    setIsPlay(true);
                }}
                onPause={() => {
                    setIsPlaying(false);
                    setIsPlay(false);
                }}
                onTimeUpdate={(e) => {
                    setCurrentProgress(e.currentTarget.currentTime);
                    handleBufferProgress(e);
                }}
                onProgress={handleBufferProgress}
            >
                <source type="audio/mpeg" src={src} />
            </audio>
            <Button variant={ButtonVariant.GHOST} icon={isPlaying ? PauseCircle : PlayCircle} alt={isPlaying ? "pause" : "play"} iconClassName={`size-[50px] flex-shrink-0 ${isPlaying ? "p-0" : "p-[5px]"}`} disabled={!isReady}
                onClick={togglePlayPause} />
            <AudioProgressBar
                className="flex-1"
                duration={duration}
                currentProgress={currentProgress}
                buffered={buffered}
                setCurrentProgress={(value) => {
                    if (!audioRef.current) return;
                    audioRef.current.currentTime = value;
                    setCurrentProgress(value);
                }}
                showThumb
                isBlue
            />
        </div>
    );
};

export default PodcastAudio;
