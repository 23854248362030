// import { Checkbox, FormControlLabel } from "@mui/material";
import { BASE_SERVER_URL } from "api";
import axios from "axios";
import useCookie from "hooks/useCookie";
import useUserInfo from "hooks/useUserInfo";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuth } from "store/mainSlice";
import {
  setMyPosts,
  setPersonalizationSource,
  setPersonalizationStep,
} from "store/personalizeSlice";
import {
  AUTH_STEP,
  PAGE_TYPES,
  PERSONALIZATION_SOURCE,
  PERSONALIZATION_STEP,
  showErrorMessage,
} from "Utils";

const AuthForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { getCookieEmail } = useCookie();
  const { authStep, pageOn } = useSelector((state) => state.main.status);
  const { isAuth, signIn, signUp, profile } = useUserInfo();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [remember, setRemember] = useState(false);
  const [wait, setWait] = useState(false);
  const [ableToEditEmail, setAbleToEditEmail] = useState(true);

  useEffect(() => {
    if (
      (pageOn === PAGE_TYPES.SCHEDULE ||
        pageOn === PAGE_TYPES.REGISTRATION ||
        pageOn === PAGE_TYPES.MANAGE) &&
      profile.email
    ) {
      setEmail(profile.email);
      setAbleToEditEmail(false);
    }
  }, [pageOn, profile.email]);

  useEffect(() => {
    if (profile.email === getCookieEmail() && (pageOn === PAGE_TYPES.POSTS || pageOn === PAGE_TYPES.PERSONALIZATION)) {
      setEmail(profile.email);
      setAbleToEditEmail(false);
    }
  }, [email, getCookieEmail, pageOn, profile.email]);

  const { step, selectedSource } = useSelector((state) => state.personalize);

  const startPersonalize = async () => {
    if (step === PERSONALIZATION_STEP.selectingSource) {
      try {
        if (selectedSource === PERSONALIZATION_SOURCE.myPosts) {
          const response = await axios.get(
            `${BASE_SERVER_URL}/api/v2/feeds/content/profile-posts?profileUrl=https://www.linkedin.com/in/${profile.linkedin}/&forceCollecting=true`
          );
          dispatch(setMyPosts(response.data));
          if (response.data.length === 0) {
            dispatch(
              setPersonalizationSource(PERSONALIZATION_SOURCE.OthersPosts)
            );
          } else {
            dispatch(setPersonalizationSource(PERSONALIZATION_SOURCE.myPosts));
          }
        } else {
          dispatch(setPersonalizationSource(PERSONALIZATION_SOURCE.OthersPosts));
        }
        dispatch(setPersonalizationStep(PERSONALIZATION_STEP.selectingPosts));
      } catch (error) {
        console.log(error);
        showErrorMessage("Failed to get your posts. Please try again");
        return;
      }
    }
  };

  const submit = async (e) => {
    if (isAuth) return;
    e.preventDefault();
    if (email !== profile.email && profile.email && location.href !== "/") {
      showErrorMessage("Please input the email you used to register.");
      return;
    }
    setWait(true);
    if (authStep === AUTH_STEP.sign_in)
      await signIn({ email, password }, () => {
        if (pageOn === PAGE_TYPES.REGISTRATION) navigate("/schedule");
        if (pageOn === PAGE_TYPES.PERSONALIZATION) startPersonalize();
      });
    else
      await signUp({ password }, () => {
        if (pageOn === PAGE_TYPES.REGISTRATION) navigate("/schedule");
        if (pageOn === PAGE_TYPES.PERSONALIZATION) startPersonalize();
      });
    setWait(false);
  };

  const goToForgotPassword = () => {
    dispatch(setAuth(AUTH_STEP.forgotPassword));
  };

  return (
    <div className="auth-right">
      <div className="auth-title">
        {authStep === AUTH_STEP.sign_up ? "Sign up" : "Sign in"}
      </div>
      {authStep === AUTH_STEP.sign_up && (
        <div className="auth-description">
          Secure your content by signing up
        </div>
      )}
      <form onSubmit={submit}>
        <div className="auth-field">
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly={!ableToEditEmail}
          />
          {/* <img src={HelpCircle} alt="help" className="auth-input-help" /> */}
        </div>
        <div className="auth-field">
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <img src={HelpCircle} alt="help" className="auth-input-help" /> */}
        </div>
        {/* <div className="auth-field">
          <FormControlLabel
            control={<Checkbox sx={{paddingTop: 0, paddingBottom: 0, paddingRight: "8px" }}/>}
            label="Remember me"
          />
        </div> */}
        <button
          type="submit"
          className={"auth-submit" + (wait ? " wait" : "")}
          disabled={wait}
        >
          Continue
        </button>
        {authStep === AUTH_STEP.sign_in && (
          <div
            className="w-full underline text-primary-900 text-center font-inter text-[14px] font-[400] leading-[143%] cursor-pointer"
            onClick={goToForgotPassword}
          >
            Forgot your password?
          </div>
        )}
        <div style={{ flex: 1 }}></div>
      </form>
    </div>
  );
};

export default AuthForm;
