import React, { useRef } from "react";
import CloseIcon from "assets/images/post-edit/x-close.svg";
import Check from "assets/images/personalize/check.svg";
// import user from "assets/images/users/Avatar Image.png";
// import ReactMarkdown from "react-markdown";
import reaction1 from "assets/images/personalize/react1.svg";
import reaction2 from "assets/images/personalize/react2.png";
import reaction3 from "assets/images/personalize/react3.svg";
import reaction4 from "assets/images/personalize/react4.svg";
import reaction5 from "assets/images/personalize/react5.svg";
import reaction6 from "assets/images/personalize/react6.png";
import reactIcon1 from "assets/images/personalize/likeIcon.svg";
import reactIcon2 from "assets/images/personalize/commentIcon.svg";
import reactIcon3 from "assets/images/personalize/repeatIcon.svg";
import reactIcon4 from "assets/images/personalize/sendIcon.svg";
import useUserInfo from "hooks/useUserInfo";
import defaultAvatar from "assets/images/default-user.png";

const PostPopup = ({ setOpenPopup, selected, select, selectable, post }) => {
  const popupRef = useRef(null);
  const closePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target))
      setOpenPopup(false);
  };
  const reactions = [
    reaction1,
    reaction2,
    reaction3,
    reaction4,
    reaction5,
    reaction6,
  ];
  const { profile } = useUserInfo();
  const { officialFirstName, officialLastName, headline } = profile;

  //   const content = `**Maximizing Productivity in Remote Work Environments**

  // Specificity is the key determinant in unlocking the full potential of AI-generated solutions.
  // The level of specificity in prompts is directly proportional to the quality and relevance of the responses generated by AI systems.
  // Are you ready to shape your AI conversations for unprecedented productivity?

  // Do you think focusing on specific details in your prompts is what really unlocks the potential of AI?

  // The level of specificity in prompts is directly proportional to the quality and relevance of the responses generated by AI systems.

  // Are you ready to shape your AI conversations for unprecedented productivity?
  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex items-center justify-center z-[100] min-h-max p-4 top-0 left-0"
      onClick={closePopup}
    >
      <div
        className="relative rounded-[20px] overflow-hidden bg-white w-[652px] max-w-full h-[663px] max-h-full flex flex-col items-start gap-4 p-4 sm:p-6"
        style={{
          boxShadow:
            "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
        }}
        ref={popupRef}
      >
        <div className="absolute right-5 top-5 cursor-pointer z-[11]">
          <img
            src={CloseIcon}
            alt="close"
            onClick={() => setOpenPopup(false)}
            className="flex-shrink-0"
          />
        </div>
        <div className="flex gap-4 items-center px-6 py-2">
          <div
            className="avatar w-[50px] h-[50px] rounded-full flex-shrink-0"
            style={{
              backgroundImage: `url(${profile.picture || defaultAvatar})`,
            }}
          ></div>
          <div className="flex flex-col h-full justify-around font-inter text-[12px] font-[500] leading-[18px] text-gray-500">
            <span className="text-[14px] font-[600] font-figtree text-gray-900">
              {`${officialFirstName} ${officialLastName}`}
            </span>
            <span>{headline}</span>
            <span>Now</span>
          </div>
        </div>
        <div className="w-full flex-1 relative">
          <div className="absolute top-0 left-0 h-full w-full overflow-auto">
            {/* <ReactMarkdown
              children={post.text}
              className="w-full text-black p-2.5 font-inter text-[14px] leading-[150%]"
            /> */}
            <pre className="w-full text-black p-2.5 font-inter text-[14px] leading-[150%] text-wrap">
              {post.text}
            </pre>
            <div className="flex flex-wrap">
              {post.images?.map((image, index) => (
                <img
                  src={image}
                  key={index}
                  alt="attach"
                  className={`h-auto ${
                    post.images.length === 1
                      ? "w-full"
                      : post.images.length === 2
                      ? "w-1/2"
                      : "w-1/3"
                  }`}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center">
          {reactions.map((reaction, index) => (
            <img
              src={reaction}
              alt="react"
              key={index}
              className="-mr-2 w-[25px] h-[25px]"
            />
          ))}
        </div>
        <div className="flex justify-around items-center w-full text-[#666] font-arial font-[700] tracking-[0.46px]">
          <div className="flex items-center gap-1 px-3 py-2">
            <img src={reactIcon1} alt="like" className="w-6 h-6" />
            <span className="hidden sm:inline">Like</span>
          </div>
          <div className="flex items-center gap-1 px-3 py-2">
            <img src={reactIcon2} alt="comment" className="w-6 h-6" />
            <span className="hidden sm:inline">Comment</span>
          </div>
          <div className="flex items-center gap-1 px-3 py-2">
            <img src={reactIcon3} alt="repeat" className="w-6 h-6" />
            <span className="hidden sm:inline">Repeat</span>
          </div>
          <div className="flex items-center gap-1 px-3 py-2">
            <img src={reactIcon4} alt="send" className="w-6 h-6" />
            <span className="hidden sm:inline">Send</span>
          </div>
        </div>
        <div className="w-full flex items-center justify-start">
          {selected ? (
            <button
              className="rounded-full text-primary-main border border-primary-main py-1 px-4 font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px] interact flex items-center gap-2"
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={select}
            >
              <img src={Check} alt="check" className="w-[18px] h-[18px]" />
              <span>Selected</span>
            </button>
          ) : (
            <button
              className={`rounded-full ${
                selectable ? "bg-primary-main interact" : "bg-gray-500"
              } py-1 px-4 font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px]`}
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              disabled={!selectable}
              onClick={select}
            >
              Select
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostPopup;
