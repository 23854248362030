import React, { useRef } from "react";
import "./AuthPopup.css";
import Jessica from "assets/images/book.png";
import linkedin from "assets/images/linkedin-black.svg";
// import HelpCircle from "assets/images/help-circle.svg";
import { useDispatch, useSelector } from "react-redux";
import { AUTH_STEP } from "Utils";
import { setAuth } from "store/mainSlice";
import CloseIcon from "assets/images/close.svg";
import AuthForm from "./forms/AuthForm";
import ForgotPassword from "./forms/ForgotForm";
import ResetForm from "./forms/ResetForm";

const AuthPopup = () => {
  const dispatch = useDispatch();
  const { authStep } = useSelector((state) => state.main.status);

  const popupRef = useRef(null);

  const closePopup = (e, force = false) => {
    if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
      dispatch(setAuth(AUTH_STEP.no_auth));
    }
  };
  return (
    <div className="auth-popup-container" onClick={closePopup}>
      <div className="auth-popup" ref={popupRef}>
        <div className="auth-close">
          <img
            src={CloseIcon}
            alt="close"
            onClick={(e) => closePopup(e, true)}
          />
        </div>
        <div className="auth-left">
          <div className="auth-img">
            <img src={Jessica} alt="jessica" />
          </div>
          <div className="auth-banners">
            <div>Trending topics in your niche</div>
            <div>
              Hands-off content creation{" "}
              <img src={linkedin} alt="linkedin" className="auth-linkedin" />
            </div>
            <div>A week of content from a 5 minute interview</div>
          </div>
        </div>
        {(authStep === AUTH_STEP.sign_in || authStep === AUTH_STEP.sign_up) && (
          <AuthForm />
        )}
        {authStep === AUTH_STEP.forgotPassword && <ForgotPassword />}
        {authStep === AUTH_STEP.resetPassword && <ResetForm />}
      </div>
    </div>
  );
};

export default AuthPopup;
