import Button, { ButtonVariant } from "components/Button";
import React, { useEffect, useRef, useState } from "react";
import PodcastPlayer from "./PodcastPlayer";
import ArrowBack from "assets/images/podcast/ArrowBack.svg";
import { useDispatch, useSelector } from "react-redux";
import useInterview from "hooks/useInterview";
import axios from "axios";
import { SERVER_URL } from "api";
import PodcastImage from "./PodcastImage";
import PodcastInput from "./PodcastInput";
import { setPodcast } from "store/postSlice";
import { showErrorMessage } from "Utils";
import { setPodcastUrl } from "store/interviewSlice";
import Loader from "components/Loader";

const PodcastPopup = ({ setOpenPopup }) => {
  const dispatch = useDispatch();
  const { podcast } = useSelector(state => state.post);
  const { interviewId } = useInterview()
  const [image, setImage] = useState(null);
  const [wait, setWait] = useState(false);
  const popupRef = useRef(null);
  const imageRef = useRef(null);
  const extensionRef = useRef(null);
  const closePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target))
      setOpenPopup(false);
  };
  const [step, setStep] = useState(0);
  const handleConfirm = async () => {
    if (step === 0) {
      setStep(1);
    } else {
      if (wait) return;
      if (!podcast.podcast_title?.trim()) {
        showErrorMessage("Podcast title is required")
        return;
      }
      setWait(true);
      let expert_image = podcast?.expert_image;
      if (imageRef.current) {
        try {
          let response = await axios.get(
            `${SERVER_URL}/posts/${interviewId}/${podcast}/signed-url`,
            {
              params: {
                fileExtension: extensionRef.current,
              },
            }
          );
          const signedUrl = response.data;
          await axios.put(signedUrl, imageRef.current, {
            headers: {
              "Content-Type": imageRef.current.type,
            },
            body: imageRef.current,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // setUploadProgress(percentCompleted);
              console.log(`Upload Progress: ${percentCompleted}%`);
            },
          });
          imageRef.current = null;
          expert_image = signedUrl.split("?").shift()
          const newPodcast = { ...podcast, expert_image }
          dispatch(setPodcast(newPodcast))
        } catch (error) {
          console.log(error);
          if (error.response?.status === 401) {
            showErrorMessage("Your session is expired. Please sign in again.");
          } else
            showErrorMessage("Failed to create your podcast. Please try again");
        }
      }
      const newPodcast = { ...podcast, expert_image }
      try {
        const response = await axios.post(`${SERVER_URL}/interview-sessions/${interviewId}/podcast/approve`, newPodcast);
        dispatch(setPodcastUrl(response.data.podcastUrl))
        window.open(response.data.podcastUrl, '_blank');
        setOpenPopup(false);
      } catch (error) {
        console.log(error);
        if (error.response?.status === 401) {
          showErrorMessage("Your session is expired. Please sign in again.");
        } else
          showErrorMessage("Failed to create your podcast. Please try again");
      }
      setWait(false);
    }
  }
  const goBack = () => {
    setStep(0);
  }

  useEffect(() => {
    if (podcast?.expert_image) {
      setImage(podcast?.expert_image)
    }
  }, [podcast, setImage]);
  const updatePodcast = (newPair) => {
    dispatch(setPodcast({ ...(podcast || {}), ...newPair }))
  }

  return <div
    className="fixed w-full h-full bg-[#131313aa] flex items-center justify-center z-[100] min-h-max p-4 top-0 left-0"
    onClick={closePopup}
  >
    <div
      className="relative h-full w-[1105px] max-w-full rounded-[30px] overflow-hidden bg-off-white-light  flex flex-col items-center divide-y divide-[#98A2B3]"
      style={{
        boxShadow:
          "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
      }}
      ref={popupRef}
    >
      <div className="relative flex-1 w-full h-[120px]" style={{ background: "linear-gradient(180deg, #FFF 0%, #D2D9F4 100%)" }}>
        <div className="absolute top-0 left-0 w-full h-full overflow-y-auto px-4 py-12 scroll-dark">
          <div className="relative left-1/2 -translate-x-1/2 flex flex-col items-start gap-12 w-[700px] max-w-full min-h-full">
            <div className="text-center w-full font-figtree text-[24px] md:text-[30px] font-[500] leading-[120%] md:leading-[147%]">Review the information that will be displayed on your page.</div>
            <div className="font-figtree text-[20px] font-[500] leading-[147%]">{step === 0 ? "1. Confirm the audios" : "2. Confirm other details"}</div>
            {podcast?.highlight_audio_url && podcast?.full_audio_url ? (
              <>
                {step === 0 && (
                  <>
                    <PodcastPlayer url={podcast.highlight_audio_url} label="1 min highlight" />
                    <PodcastPlayer url={podcast.full_audio_url} label="Full podcast" />
                  </>
                )}
                
                {step === 1 && (
                  <>
                    <PodcastImage image={image} setImage={setImage} imageRef={imageRef} extensionRef={extensionRef} />
                    <PodcastInput text={podcast.podcast_quote} setText={(newText) => updatePodcast({ podcast_quote: newText })} label="Podcast Quote" />
                    <PodcastInput text={podcast.podcast_desc} setText={(newText) => updatePodcast({ podcast_desc: newText })} label="Podcast Description" isTextArea rows={8} />
                    <PodcastInput text={podcast.expert_name} setText={(newText) => updatePodcast({ expert_name: newText })} label="Expert’s name" />
                    <PodcastInput text={podcast.expert_info} setText={(newText) => updatePodcast({ expert_info: newText })} label="Expert’s Info" />
                    <PodcastInput text={podcast.expert_about} setText={(newText) => updatePodcast({ expert_about: newText })} label="Expert’s about" />
                    <PodcastInput text={podcast.audio_title} setText={(newText) => updatePodcast({ audio_title: newText })} label="Audio Title" />
                    <PodcastInput text={podcast.podcast_quote} setText={(newText) => updatePodcast({ podcast_quote: newText })} label="Expert’s role" />
                    <PodcastInput text={podcast.twitter_link} setText={(newText) => updatePodcast({ twitter_link: newText })} label="Twitter Link" />
                    <PodcastInput text={podcast.linkedin_link} setText={(newText) => updatePodcast({ linkedin_link: newText })} label="LinkedIn Link" />
                    <PodcastInput text={podcast.podcast_title} setText={(newText) => updatePodcast({ podcast_title: newText })} label="Podcast Title" />
                    <PodcastInput text={podcast.industry?.join(",")} setText={(newText) => updatePodcast({ industry: newText.split(/,/) })} label="Industry" placeholder="Separate industries with commas(,)" />
                    <PodcastInput text={podcast.profession?.join(",")} setText={(newText) => updatePodcast({ profession: newText.split(/,/) })} label="Profession" placeholder="Separate your professions with commas(,)" />
                    {/* Uncomment if needed
                      <PodcastInput text={podcast.podcast_desc} setText={(newText) => updatePodcast({ podcast_desc: newText })} label="Highlight list" isTextArea rows={3} /> 
                    */}
                  </>
                )}
              </>
            ) : (
              <div className="w-full flex-1 flex items-center justify-center">
                <Loader />
              </div>
            )}
          </div>
          {step > 0 && <Button text="Back" icon={ArrowBack} alt="back" iconClassName="size-5 flex-shrink-0" variant={ButtonVariant.GHOST} onClick={goBack} className="gap-2 absolute z-1 top-4 md:top-6 left-6" />}
        </div>
      </div>
      <div className="w-full flex items-center justify-center px-2 py-2 sm:py-4 bg-[#D5DCF5]">
        <Button text="Confirm" variant={ButtonVariant.FILL} className="py-2 px-4 w-[320px] font-figtree text-[15px] leading-[173%] tracking-[0.46px] max-w-full" onClick={handleConfirm} disabled={wait || !(podcast?.highlight_audio_url && podcast?.full_audio_url)} />
      </div>
    </div>
  </div>

}

export default PodcastPopup;
