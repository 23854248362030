import React from "react";
import CloseIcon from "assets/images/checkout-failed/close.svg";
import CardIcon from "assets/images/checkout-failed/add_card.svg";
import AlertIcon from "assets/images/checkout-failed/alert-triangle.svg";
import BankIcon from "assets/images/checkout-failed/bank.svg";
import RefreshIcon from "assets/images/checkout-failed/refresh-cw-01.svg";
import useRedirect from "hooks/useRedirect";

const CheckoutFailed = () => {
  const { redirectOrNavigate, navigateWithRedirect } = useRedirect();

  return (
    <div className="absolute w-full min-h-full p-4 pb-8 flex items-center">
      <div className="w-full min-h-full flex items-center justify-center gap-8">
        <div
          className="flex w-[554px] max-w-full p-6 items-center gap-8 rounded-xl bg-white flex-col"
          style={{
            boxShadow:
              "box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);",
          }}
        >
          <div className="flex justify-end w-full">
            <img
              src={CloseIcon}
              alt="close"
              onClick={() => redirectOrNavigate("/subscribe")}
            />
          </div>
          <div className="flex w-full flex-col items-center gap-6">
            <div
              className="p-5 rounded-full"
              style={{ backgroundColor: "rgba(215, 15, 39, 0.30)" }}
            >
              <img src={AlertIcon} alt="" />
            </div>
            <div className="flex flex-col gap-7">
              <div className="flex flex-col gap-3">
                <div className="self-stretch text-black text-center font-figtree text-[18px] font-semibold leading-[28px]">
                  Uh oh! Payment Failed.
                </div>
                <div className="self-stretch text-blue-grey text-center font-figtree text-[14px] font-semibold leading-[20px]">
                  Don't worry, these things happen sometimes! <br />
                  Here are a few things you can try...
                </div>
              </div>
              <div className="pl-4 flex flex-col gap-5">
                <div className="flex gap-3 items-start">
                  <div className="flex w-6 h-6 justify-center items-center bg-[#EFF8FF] rounded-full shrink-0">
                    <img src={RefreshIcon} alt="refresh" className="w-4 h-4" />
                  </div>
                  <div className="text-blue-grey font-figtree text-[14px] leading-[21px]">
                    <strong>Try again:</strong> Sometimes it's just a temporary
                    glitch. Click the button below to retry your payment.
                  </div>
                </div>
                <div className="flex gap-3 items-start">
                  <div className="flex w-6 h-6 justify-center items-center bg-[#EFF8FF] rounded-full shrink-0">
                    <img src={BankIcon} alt="bank" className="w-4 h-4" />
                  </div>
                  <div className="text-blue-grey font-figtree text-[14px] leading-[21px]">
                    <strong>Check details:</strong> Double-check all your card
                    and billing information for any typos. Make sure your
                    billing address matches what your bank has on file.
                  </div>
                </div>
                <div className="flex gap-3 items-start">
                  <div className="flex w-6 h-6 justify-center items-center bg-[#EFF8FF] rounded-full shrink-0">
                    <img src={CardIcon} alt="card" className="w-4 h-4" />
                  </div>
                  <div className="text-blue-grey font-figtree text-[14px] leading-[21px]">
                    <strong>Use different payment method:</strong> Maybe your
                    current card has expired, doesn't have enough funds, or has
                    reached its transaction limit.
                  </div>
                </div>
                <div className="self-stretch text-blue-grey font-figtree text-[14px] leading-[28px]">
                  Still having trouble?{" "}
                  <a className="text-m-dark-blue underline" href="/">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-full py-2.5 px-4.5 rounded-[40px] bg-cta-blue text-white font-figtree text-[16px] font-semibold uppercase text-center"
            style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
            onClick={() => navigateWithRedirect("/subscribe")}
          >
            TRY AGAIN
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFailed;
