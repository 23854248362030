import React, { useState } from "react";
import PodcastInput from "./PodcastInput";
import PodcastAudio from "./PodcastAudio";

const PodcastPlayer = ({ label = "", url = "" }) => {
  const [duration, setDuration] = useState(0);
  function formatDurationDisplay(duration) {
    if (!Number.isFinite(duration)) return "0.00sec";
    return duration >= 60
      ? `${(duration / 60).toFixed(2)}min`
      : `${duration.toFixed(2)}sec`;
  }
  const durationDisplay = formatDurationDisplay(duration);
  return <div className="w-full space-y-5">
    <PodcastInput label={label} text={url} readonly />
    <div className="w-full flex flex-col items-end">
      <PodcastAudio src={url} onChangeDuration={(value) => setDuration(value)} length={duration} className="flex items-center gap-2.5" />
      <span className="font-figtree text-[14px] font-[500] leading-[120%] text-right px-6">{durationDisplay}</span>
    </div>
  </div>
}

export default PodcastPlayer;
