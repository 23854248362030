import React, { useEffect, useRef, useState } from "react";
import jessica from "assets/images/personalize/jessica.png";
import polygon from "assets/images/personalize/Polygon 1.svg";
import { showErrorMessage, sleep } from "Utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SERVER_URL } from "api";
import useUserInfo from "hooks/useUserInfo";
import AnimationImage1 from "assets/images/personalize/Pressed1.png";
import AnimationImage2 from "assets/images/personalize/Pressed2.png";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "store/mainSlice";
import { setFetchedPosts } from "store/postSlice";

const PostLinkInput = ({ value, onChange }) => {
  return (
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder="linkedin.com/posts/…."
      className="w-full bg-transparent outline-none rounded-full border border-blue500 px-3 py-4 font-figtree text-[16px] leading[150%] tracking-[0.15px] lowercase"
    />
  );
};

const isValidPost = (postLink) => {
  const postRegex = /https:\/\/www.linkedin.com\/posts\//i;
  const feedRegex = /https:\/\/www.linkedin.com\/feed\//i;
  try {
    new URL(postLink);
    if (!postRegex.test(postLink) && !feedRegex.test(postLink)) {
      console.log("Url must contain /posts/ or /feed/");
      return false;
    }
  } catch (error) {
    console.log(`${postLink} is not a valid url`);
    return false;
  }
  return true;
};

const PostsInput = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [post1, setPost1] = useState("");
  const [post2, setPost2] = useState("");
  const [post3, setPost3] = useState("");
  const [wait, setWait] = useState(false);
  const { profile } = useUserInfo();
  const { myPosts } = useSelector((state) => state.personalize);
  const handleSubmit = async () => {
    if (wait) return;
    if (
      post1.trim().length === 0 &&
      post2.trim().length === 0 &&
      post3.trim().length === 0
    ) {
      showErrorMessage("Please input all three post links.");
      return;
    }
    setWait(true);
    try {
      const validResults = [
        isValidPost(post1),
        isValidPost(post2),
        isValidPost(post3),
      ];
      let invalidIndex = 0;
      if (!validResults[0]) {
        invalidIndex = 1;
      } else if (!validResults[1]) {
        invalidIndex = 2;
      } else if (!validResults[2]) {
        invalidIndex = 3;
      }
      const orderNumberStrings = ["First", "Second", "Third"];
      if (invalidIndex > 0) {
        const invalidPostNum = orderNumberStrings[invalidIndex - 1];
        showErrorMessage(
          `${invalidPostNum} post link is invalid. Please verify it and input a valid post link.`
        );
        setWait(false);
        return;
      }
      await axios.post(`${SERVER_URL}/personalization/registerUrls`, {
        email: profile.email,
        urls: [post1, post2, post3],
      });
      while (true) {
        const response = await axios.get(
          `${SERVER_URL}/personalization/check`,
          { params: { email: profile.email } }
        );
        if (response.data.isPersonalized) break;
        await sleep(2000);
      }
      dispatch(setProfile({ personalized: true }));
      const urlParams = new URLSearchParams(window.location.search);
      const interviewId = urlParams.get("interviewId");
      await axios.post(`${SERVER_URL}/personalization/generate`, {
        interviewId,
      });
      dispatch(setFetchedPosts(false));
      navigate(
        interviewId
          ? `/complete-personalize?interviewId=${interviewId}`
          : "/complete-personalize"
      );
    } catch (error) {
      console.log(error);
      if (error.response?.status === 404) {
        const errors = error.response.data.errors;
        let errorIndex1 = errors.findIndex((error) => error.url === post1);
        let errorIndex2 = errors.findIndex((error) => error.url === post2);
        let errorIndex3 = errors.findIndex((error) => error.url === post3);

        if (errorIndex1 > -1) {
          showErrorMessage(
            `First post url is invalid. ${errors[errorIndex1]?.reason}.`
          );
        } else if (errorIndex2 > -1) {
          showErrorMessage(
            `Second post url is invalid. ${errors[errorIndex2]?.reason}.`
          );
        } else if (errorIndex3 > -1) {
          showErrorMessage(
            `Third post url is invalid. ${errors[errorIndex3]?.reason}.`
          );
        }
      } else {
        showErrorMessage("Failed to personalize your posts. Please try again.");
      }
    }
    setWait(false);
  };

  const animation1Ref = useRef(null);
  const animation2Ref = useRef(null);
  const pressedRef = useRef(false);
  useEffect(() => {
    const timer = setInterval(() => {
      if (pressedRef.current) {
        animation1Ref.current.style.opacity = 1;
        animation2Ref.current.style.opacity = 0;
      } else {
        animation1Ref.current.style.opacity = 0;
        animation2Ref.current.style.opacity = 1;
      }

      pressedRef.current = !pressedRef.current;
    }, 1500);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="flex-1 flex flex-col justify-center items-center p-4 gap-10">
      {myPosts && myPosts.length === 0 && (
        <div className="text-off-white-base text-center font-figtree text-[20px] leading-[220%] w-[960px] max-w-full">
          We couldn’t find any LinkedIn posts on your account. Instead, extract
          your ideal tone of voice from the posts of LinkedIn users whose style
          you admire!
        </div>
      )}
      <div className="flex items-center gap-2.5">
        <img
          src={jessica}
          alt="jessica"
          className="w-[73px] h-[84px] flex-shrink-0"
        />
        <div className="flex items-center">
          <img src={polygon} alt="polygon" className="w-[22px] h-[29px]" />
          <div className=" px-5 py-2.5 bg-[#2028E6] text-white font-figtree text-[16px] sm:text-[20px] leading-[200%] tracking-[-0.2px] rounded-[40px] -ml-1 text-center">
            Add links to the LinkedIn posts of people whose writing you admire
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center gap-8 md:gap-16 flex-wrap max-w-full">
        <div className="relative max-w-full w-[550px] h-auto">
          <img
            src={AnimationImage1}
            className="w-full h-auto transition-opacity duration-[500] ease-linear"
            ref={animation1Ref}
            alt="image1"
          />
          <img
            src={AnimationImage2}
            className="absolute top-0 left-0 w-full h-full transition-opacity duration-[1000] ease-linear"
            ref={animation2Ref}
            alt="image2"
          />
        </div>
        <div className="flex flex-col gap-9 items-center max-w-full">
          <div className="w-[496px] max-w-full flex flex-col gap-4">
            <PostLinkInput
              value={post1}
              onChange={(event) => setPost1(event.target.value)}
            />
            <PostLinkInput
              value={post2}
              onChange={(event) => setPost2(event.target.value)}
            />
            <PostLinkInput
              value={post3}
              onChange={(event) => setPost3(event.target.value)}
            />
          </div>
          <button
            className="link interact w-[215px] max-w-full rounded-[40px] bg-primary-main py-2 px-5 text-white font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
            style={{
              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
            onClick={handleSubmit}
            disabled={wait}
          >
            {wait ? "Wait..." : "Share"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostsInput;
