import React from "react";
import Avatar1 from "assets/images/billing/avatar1.png";
import Avatar2 from "assets/images/billing/avatar2.png";
import Avatar3 from "assets/images/billing/avatar3.png";
import linkedin from "assets/images/billing/linkedin_3.svg";
import star from "assets/images/billing/star-05.svg";
import Eye from "assets/images/billing/eye_3.svg";
import LayoutIcon from "assets/images/billing/layout-alt-02.svg";
import UserAvatar from "assets/images/default-user.png";
import useUserInfo from "hooks/useUserInfo";

const LeftCard = () => {
  const banners = [
    {
      name: "Ross Pitterson",
      headline: "Founder, Social Media Expert",
      followers: 17,
      posts: 280,
      picture: Avatar1,
      rotate: 0,
      top: 0,
      left: 0,
    },
    {
      name: "Sander Toriss",
      headline: "Silicon Valley Entrepreneur, Writer",
      followers: 2.7,
      posts: 170,
      picture: Avatar2,
      rotate: "-10.676deg",
      top: "25px",
      left: 0,
    },
    {
      name: "Amanda Brown",
      headline: "Product Marketing Expert",
      followers: 21,
      posts: 305,
      picture: Avatar3,
      rotate: 0,
      top: "30px",
      left: "120px",
    },
  ];
  const { picture, firstName } = useUserInfo().profile;
  return (
    <div className="rounded-[30px] bg-off-white-light p-8 w-[470px] 2xl:w-[557px] h-[660px] hidden lg:flex justify-center flex-col">
      <div className="flex gap-5">
        <div className="text-black-bg font-figtree text-[20px] 2xl:text-[26px] flex-1 leading-[26px] 2xl:leading-[34px]">
          <strong>
            <span className="capitalize">{firstName}</span>, create authentic content with effortless interviews.
          </strong>{" "}
          Get more exposure for your insights.{" "}
        </div>
        <div className="flex-1 pt-16 2xl:pt-20">
          {picture ? (
            <img src={picture} alt="avatar" className="rounded-full w-[180px] 2xl:w-[200px]"/>
          ) : (
            <img src={UserAvatar} alt="user" className="rounded-full w-[180px] 2xl:w-[200px]"/>
          )}
        </div>
      </div>
      <div className="relative mt-[-30px] 2xl:mt-[-50px] pb-10">
        {banners.map((banner, index) => (
          <div
            key={index}
            className="p-2 2xl:p-4 bg-white rounded-2xl flex items-start gap-1 2xl:gap-[9px] self-stretch flex-col relative w-fit"
            style={{
              top: banner.top,
              left: banner.left,
              transform: `rotate(${banner.rotate})`,
            }}
          >
            <div className="flex items-center gap-2 self-stretch">
              <div className="relative">
                <img
                  src={banner.picture}
                  alt="avatar"
                  className="w-9 2xl:w-[46px] h-auto"
                />
                <img
                  src={linkedin}
                  alt=""
                  className="absolute right-0 bottom-1"
                />
              </div>
              <div className="px-[2px] py-2 relative w-56 2xl:w-[260.29px]">
                <div className="absolute right-1 top-1 w-[102.70px] h-[20.19px] pl-[6.49px] pr-[8.65px] py-[2.16px] mix-blend-multiply bg-off-white-dark rounded-[17.30px] justify-start items-center gap-[4.32px] inline-flex text-nowrap">
                  <img src={star} alt="star" />
                  <div className="text-center text-slate-950 text-[10.81px] font-inter leading-tight ">
                    Potential lead
                  </div>
                </div>
                <div className="font-inter text-black-bg text-[16px] 2xl:text-[20px] leading-[22px]">
                  {banner.name}
                </div>
                <div className="text-gray-500 overflow-ellipsis whitespace-nowrap font-inter text-[9px] leading-[13px] overflow-hidden">
                  {banner.headline}
                </div>
              </div>
            </div>
            <div className="h-3.5 justify-start items-center gap-[14.96px] inline-flex">
              <div className="justify-start items-center gap-[2.99px] flex">
                <img className="w-3 h-3 relative" src={Eye} alt="eye"></img>
                <div className="text-center text-gray-500 text-xs font-normal font-inter leading-[13.47px] text-nowrap">
                  {banner.followers}K followers
                </div>
              </div>
              <div className="justify-start items-center gap-[2.99px] flex">
                <img className="w-3 h-3 relative" src={LayoutIcon} alt="post"></img>
                <div className="text-center text-gray-500 text-xs font-normal font-inter leading-[13.47px] text-nowrap">
                  {banner.posts} posts
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeftCard;
