import React, { useEffect, useRef, useState } from "react";
// import HelpCircle from "assets/images/help-circle.svg";
import { isValidEmail, showErrorMessage } from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import { useDispatch, useSelector } from "react-redux";
import { setGuestMode, setIsSettingUpGuestMode } from "store/preparationSlice";
import Input from "components/Input";
import useUserInfo from "hooks/useUserInfo";

const SetGuestModePopup = () => {
  const dispatch = useDispatch();
  const { guestMode } = useSelector((state) => state.preparation);
  const { defaultAssistant } = useUserInfo();
  const popupRef = useRef(null);
  const [wait, setWait] = useState(false);
  const [assistant, setAssistant] = useState({ name: "", picture: "" });
  const [linkedin, setLinkedin] = useState("");
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (defaultAssistant) {
      setAssistant({ ...defaultAssistant });
    } else {
      setAssistant({ name: "", picture: "" });
    }
  }, [defaultAssistant]);

  useEffect(() => {
    if (guestMode) {
      setAssistant({ ...guestMode.assistant });
      setLinkedin(`https://www.linkedin.com/in/${guestMode.guest.linkedin}`);
    }
  }, [guestMode]);

  const closePopup = (e, force = false) => {
    if (wait) return;
    if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
      dispatch(setIsSettingUpGuestMode(false));
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    if (step === 0) {
      setStep(1);
      return;
    }
    if (wait) return;
    
    if (linkedin.trim() === "") {
      showErrorMessage("LinkedIn URL is required");
      return;
    }
    if (email.trim() === "") {
      showErrorMessage("LinkedIn URL is required");
      return;
    }
    if (!isValidEmail(email.trim())) {
      showErrorMessage("Invalid email format");
      return;
    }
    
    setWait(true);

    let cutLinkedin = linkedin
      .trim()
      .replace(/^\/+|\/+$/g, "")
      .replace(/\s/g, "")
      .split("/")
      .pop();

    setWait(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/services/linkedin/${cutLinkedin}`
      );
      const {
        officialFirstName,
        officialLastName,
        company,
        picture,
        headline,
      } = response.data;
      dispatch(
        setGuestMode({
          assistant,
          guest: {
            firstName: officialFirstName,
            lastName: officialLastName,
            company,
            picture,
            headline,
            linkedin: cutLinkedin,
            email,
          },
        })
      );
      closePopup({}, true);
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to set up your audience, please try again");
    }
    setWait(false);
  };

  const disabled = wait;

  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex flex-col items-center z-[100] p-2.5 top-0 left-0 overflow-auto"
      onClick={closePopup}
    >
      <div className="flex-1 flex items-center max-w-full" id="audience-popup">
        <div
          className={`rounded-[20px] sm:rounded-[30px] border-[0.59px] border-solid bg-[#f9f1ed] w-[972px] max-w-full p-4 pt-8 md:p-8 md:pt-16 ${
            step === 0 ? "gap-4 md:gap-8" : "gap-8 md:gap-16"
          } flex flex-col items-center`}
          id="audience-popup"
          style={{
            boxShadow:
              "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
          }}
          ref={popupRef}
        >
          <div>
            <div className="text-black-bg text-center text-[22px] sm:text-[24px] leading-[170%]">
              {step === 0
                ? "✒️ Customize Your Interview Agent"
                : "✒️ Guest Information"}
            </div>
            <div className="text-center text-text-primary font-figtree text-[14px] leading-[140%] max-w-full w-[645px]">
              {step === 0
                ? "Create a personalized interview experience by customizing the agent. Change the agent’s name and photo to represent your brand or agency. The interview will appear as if it’s hosted by your custom agent."
                : "To personalize the interview for your guest, please provide their LinkedIn profile URL"}
            </div>
          </div>
          <div className="w-[469px] max-w-full flex flex-col gap-4 md:gap-9">
            {step === 0 ? (
              <>
                <Input
                  placeholder="Enter the name of your custom interviewer"
                  label="Agent Name"
                  value={assistant.name}
                  onChange={(e) =>
                    setAssistant((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
                <Input
                  placeholder="Direct URL photo of the agent"
                  label="Agent Photo"
                  value={assistant.picture}
                  onChange={(e) =>
                    setAssistant((prev) => ({
                      ...prev,
                      picture: e.target.value,
                    }))
                  }
                />
              </>
            ) : (
              <>
                <Input
                  placeholder="Enter or paste guest's email"
                  label="Guest’s Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  placeholder="Enter or paste LinkedIn profile link"
                  label="Guest’s LinkedIn URL"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </>
            )}
          </div>
          <div className="flex w-full justify-center gap-4 flex-nowrap">
            <button
              type="submit"
              className="link interact max-w-full w-[157px] py-2 sm:py-3 text-primary-main bg-transparent border border-primary-main font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]"
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={() => closePopup({}, true)}
            >
              {"Cancel"}
            </button>
            <button
              type="submit"
              className={`max-w-full w-[157px] py-2 sm:py-3 ${
                disabled
                  ? "bg-action-disabled-background text-action-disabled border"
                  : "bg-primary-main text-white border border-primary-main interact"
              } font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]`}
              style={{
                boxShadow: disabled
                  ? "none"
                  : "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              disabled={disabled}
              onClick={submit}
            >
              {wait ? "Wait..." : "Save"}
            </button>
          </div>
          {step === 0 && (
            <div className="text-text-primary text-center font-figtree italic leading-[140%]">
              Note: If you leave this blank, Jessica will be used as the default
              agent.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetGuestModePopup;
