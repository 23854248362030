import React from "react";
import "./style.css";
import Card from "components/Card";
import img from "assets/images/book.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { INTERVIEW_STATUS, getCurrentDateTime, meetingMinutes } from "Utils";
import useUserInfo from "hooks/useUserInfo";
import useRedirect from "hooks/useRedirect";

const Confirm = () => {
  const navigate = useNavigate();
  const {
    min,
    hour,
    date,
    month,
    year,
    timezone,
    interviewId,
    interviewStatus,
    recurring,
  } = useSelector((state) => state.manage);
  const { profile, isAuth, interviews } = useUserInfo();
  const { redirectOrNavigate } = useRedirect();
  const { firstName } = profile;
  const needToRecreate =
    interviewId && interviewStatus === INTERVIEW_STATUS.DONE && recurring;
  const getDateTimeString = () => {
    let startDate = new Date(year, month, date, hour, min);
    let endDate = new Date(year, month, date, hour, min + meetingMinutes);

    if (hour < 0 || needToRecreate) {
      const today = getCurrentDateTime(timezone);
      startDate = new Date(
        today.year,
        today.month,
        today.day,
        today.hour,
        today.minute
      );
      endDate = new Date(
        today.year,
        today.month,
        today.day,
        today.hour,
        today.minute + meetingMinutes
      );
    }

    const formatDate = (date) => {
      const options = { weekday: "long", month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);

      const suffix = getNumberSuffix(date.getDate());

      return `${formattedDate}${suffix}, ${year}`;
    };

    const getNumberSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }

      const lastDigit = day % 10;

      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedStartDate = `${startDate.getHours()}:${String(
      startDate.getMinutes()
    ).padStart(2, "0")}`;
    const formattedEndDate = `${endDate.getHours()}:${String(
      endDate.getMinutes()
    ).padStart(2, "0")}`;

    const result = `${formattedStartDate} - ${formattedEndDate} ${formatDate(
      startDate
    )}`;
    return result;
  };

  const finishedInterviews = interviews.filter(
    (interview) => interview.status === INTERVIEW_STATUS.DONE
  );

  const goToCreate = () => navigate("/schedule");

  const review = () => {
    let url = "/schedule";

    if (finishedInterviews.length > 0) {
      url = `/posts/${finishedInterviews[finishedInterviews.length - 1]._id}`;
    }
    redirectOrNavigate(url);
  };

  return (
    <div className="schedule">
      <div className="main">
        <div className="done">
          {recurring
            ? "Your weekly calls are scheduled"
            : "Your call is scheduled"}
        </div>
        <div className="right-box">
          <div className="interviewer">
            <img src={img} alt="schedule" />
            <div>
              <div className="name capitalize">
                {firstName} &lt;&gt; Jessica
              </div>
              <div className="title">
                Personalized content creation interview
              </div>
            </div>
          </div>
          <Card>
            <div className="scheduled-card">
              <div className="time">{getDateTimeString()}</div>
              <div className="duration-description">
                <div className="duration">{meetingMinutes} minutes</div>
                <div className="description">
                  Voice interview, no need for a web camera
                </div>
              </div>
              <div className="timezone">
                <strong>Time Zone</strong> {timezone}
              </div>
            </div>
          </Card>
        </div>
        <div className="button-box">
          {isAuth && finishedInterviews.length > 0 ? (
            <>
              <button className="continue" onClick={goToCreate}>
                Schedule Another Call
              </button>
              <button onClick={review}>Review & edit your posts</button>
            </>
          ) : (
            <button onClick={goToCreate}>Schedule Another Call</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Confirm;
