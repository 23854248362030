import React, { useRef } from "react";
import DefaultUser from "assets/images/default-user.png";

const Avatar = ({ className, alt, src, defaultUser = DefaultUser }) => {
  const handleError = () => {
    imgRef.current.src = defaultUser;
  };
  const imgRef = useRef(null);
  return (
    <img
      src={src || defaultUser}
      alt={alt}
      className={`avatar ${className}`}
      onError={handleError}
      ref={imgRef}
    />
  );
};

export default Avatar;
