import { createSlice } from "@reduxjs/toolkit";
import { AUTH_STEP, PAGE_TYPES, /* RECURRING_VALUES, */ toUtcDate } from "Utils";

// const initialState = { // for test
//   firstName: "John",
//   officialFirstName: "John",
//   officialLastName: "Doe",
//   company: null,
//   email: "john@email.com",
//   picture: null,
//   linkedin: "john_linkedin",
//   headline: "123456789012345678901234567890121345678901234567890",
//   userId: "null",
//   interviews: [],
//   authStep: AUTH_STEP.no_auth,
//   showUpgrade: false,
//   subscribed: true
// };

const initialState = {
  profile: {
    firstName: "",
    officialFirstName: "",
    officialLastName: "",
    company: null,
    email: "",
    picture: null,
    linkedin: "",
    headline: "",
    linkedinConnected: false,
    personalized: false,
    interviews: [],
    // schedules: [{
    //   topic: "awef wefi bn weif weofnwoe weofwpeof wpeof",
    //   interviews: ["qwefq", "qwefq", "wefgq"],
    //   recurring: RECURRING_VALUES.WEEKLY,
    //   schedule: {
    //     year: 2024,
    //     month: 11,
    //     date: 23,
    //     hour: 12,
    //     min: 24
    //   },
    //   firstName: "Tom",
    // }],
    schedules: [],
    audience: null,
  },
  registerEmail: "",
  billingInfo: null,
  defaultAssistant: null,
  status: {
    isAuth: false,
    isSignedUp: false,
    isCheckingAuth: true,
    isCheckingSubscription: true,
    isCheckingActivity: true,
    isCheckingInterview: false,
    isCheckingSchedules: true,
    authStep: AUTH_STEP.no_auth,
    showUpgrade: false,
    checkoutCongrats: false,
    redirectAfterSignIn: "",
    pageOn: PAGE_TYPES.REGISTRATION,
    isCheckingAudience: true,
  },
  theme: {
    isSlim: true,
  },
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.status.isAuth = !!action.payload;
    },
    setIsCheckingAuth: (state, action) => {
      state.status.isCheckingAuth = !!action.payload;
    },
    setIsCheckingSubscription: (state, action) => {
      state.status.isCheckingSubscription = !!action.payload;
    },
    setIsCheckingActivity: (state, action) => {
      state.status.isCheckingActivity = !!action.payload;
    },
    init: () => ({ ...initialState }),
    initProfile: (state, action) => {
      state.profile = { ...initialState.profile };
    },
    setProfile: (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    },
    setAuth: (state, action) => {
      state.status.authStep = action.payload;
    },
    setShowUpgrade: (state, action) => {
      state.status.showUpgrade = !!action.payload;
    },
    setBillingInfo: (state, action) => {
      state.billingInfo = action.payload;
    },
    setInterviews: (state, action) => {
      state.profile.interviews = action.payload.sort(
        (a, b) => toUtcDate(b.schedule) - toUtcDate(a.schedule)
      );
    },
    setIsSignedUp: (state, action) => {
      state.status.isSignedUp = !!action.payload;
    },
    setRegisterEmail: (state, action) => {
      state.registerEmail = action.payload;
    },
    setCheckoutCongrats: (state, action) => {
      state.status.checkoutCongrats = !!action.payload;
    },
    setRedirectAfterSignIn: (state, action) => {
      state.status.redirectAfterSignIn = action.payload;
    },
    setPageOn: (state, action) => {
      state.status.pageOn = action.payload;
    },
    setPersonalized: (state, action) => {
      state.profile.personalized = !!action.payload;
    },
    setIsCheckingInterview: (state, action) => {
      state.status.isCheckingInterview = !!action.payload;
    },
    setIsSlim: (state, action) => {
      state.theme.isSlim = !!action.payload;
    },
    setAudience: (state, action) => {
      state.profile.audience = action.payload;
    },
    setIsCheckingAudience: (state, action) => {
      state.status.isCheckingAudience = !!action.payload;
    },
    setDefaultAssistant: (state, action) => {
      state.defaultAssistant = action.payload;
    },
    setIsCheckingSchedules: (state, action) => {
      state.status.isCheckingSchedules = !action.payload
    },
    setSchedules: (state, action) => {
      state.profile.schedules = action.payload;
    }
  },
});

export const {
  init,
  setIsAuth,
  setProfile,
  setAuth,
  setShowUpgrade,
  setBillingInfo,
  setInterviews,
  setIsSignedUp,
  initProfile,
  setIsCheckingActivity,
  setIsCheckingAuth,
  setIsCheckingSubscription,
  setIsCheckingInterview,
  setRegisterEmail,
  setCheckoutCongrats,
  setRedirectAfterSignIn,
  setPageOn,
  setPersonalized,
  setIsSlim,
  setAudience,
  setIsCheckingAudience,
  setDefaultAssistant,
  setIsCheckingSchedules,
  setSchedules,
} = mainSlice.actions;

export default mainSlice.reducer;
