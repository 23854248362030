import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import JessicaLogo from "assets/images/meeting/jessica-mishmish.png";
import AnimationBack from "../animations/Square Conf.json";
import "../styles/Congrats.css";
import useUserInfo from "hooks/useUserInfo";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalize, INTERVIEW_STATUS } from "../../../Utils";
import Avatar from "components/Avatar";
import useInterview from "hooks/useInterview";

const Congrats = () => {
  const navigate = useNavigate();
  const { profile } = useUserInfo();
  const { interviewId } = useSelector((state) => state.interview);
  const { guestMode } = useInterview();
  const goToSchedule = () => {
    navigate("/schedule");
  };
  const goToPosts = () => {
    const finishedInterviews = profile.interviews.filter(
      (interview) => interview.status === INTERVIEW_STATUS.DONE
    );
    if (profile.personalized) navigate(`/posts/${interviewId}?skip`);
    else if (
      finishedInterviews.length === 0 ||
      (finishedInterviews.length === 1 &&
        finishedInterviews[0]._id === interviewId)
    )
      navigate(`/personalize/${interviewId}`);
    else navigate(`/posts/${interviewId}`);
  };
  return (
    <div className="congrats-container">
      <div className="congrats back">
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
        <Player autoplay loop src={AnimationBack} className="conf" />
      </div>
      <div className="congrats front flex items-center justify-center p-4">
        <div className="max-w-full w-[800px] max-h-[100%] h-750px rounded-[40px] bg-cta-blue flex flex-col items-center justify-center gap-6 sm:gap-10 p-4 sm:p-10">
          <div className="flex">
            <Avatar
              src={guestMode?.assistant?.picture || JessicaLogo}
              alt="jessica"
              className="rounded-full w-[126px] h-[133px] sm:w-[155px] sm:h-[163px] md:w-[180px] md:h-[193px] -mr-7"
              defaultUser={JessicaLogo}
            />
            <Avatar
              className="avatar w-[133px] h-[133px] sm:w-[163px] sm:h-[163px] md:w-[193px] md:h-[193px]"
              src={guestMode?.guest ? guestMode?.guest?.picture : profile.picture}
              alt="user"
            />
          </div>
          <div className="font-figtree text-[26px] sm:text-[32px] lg:text-[40px] font-[700] leading-[50%] tracking-[-0.4px] text-center">{`Great job, ${capitalize(
            guestMode?.guest?.firstName || profile.firstName
          )} 🎉`}</div>
          {guestMode?.guest?.firstName ? (
            <>
              <div className="font-figtree text-[16px] sm:text-[18px] lg:text-[24px] font-[400] leading-[137.5%] tracking-[-0.24px] text-center px-0">
                <span className="block">Thanks for your interview!</span>
                <span className="block">
                  You provided all the insights needed to create some hit
                  content!
                </span>
                <span className="block">
                  will get back to you with the results.
                </span>
              </div>
              <div className="flex flex-wrap w-full justify-center gap-2.5 font-inter text-[16px] leading-[150%]">
                You can close the tab
              </div>
            </>
          ) : (
            <>
              <div className="font-figtree text-[16px] sm:text-[18px] lg:text-[24px] font-[400] leading-[137.5%] tracking-[-0.24px] text-center px-0 lg:px-10">
                I’m calculating your interview score & creating your content. A
                high score (longer & more detailed answers) indicates strong
                potential for engaging content.
              </div>
              <div className="flex flex-wrap w-full justify-center gap-2.5">
                <button
                  className="link interact flex-1 max-w-[235px] text-nowrap border border-white rounded-full px-5 py-2 font-inter text-[16px] font-[600] leading-[150%]"
                  onClick={goToSchedule}
                >
                  Schedule Another Call
                </button>
                <button
                  className="link interact flex-1 max-w-[235px] text-nowrap border border-white rounded-full px-10 py-2 text-cta-blue bg-white font-inter text-[16px] font-[600] leading-[150%]"
                  style={{ color: "#0e24d6" }}
                  onClick={goToPosts}
                >
                  View Your Posts
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Congrats;
