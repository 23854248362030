import React from "react";

const DraftTypeCard = ({
  icon,
  text,
  isPodcast = false,
  onClick = () => {},
  disabled,
}) => {
  return (
    <div
      className={`py-2 px-4 flex items-center gap-2 rounded-[20px] border  flex-shrink-0 no-select border-light-blue ${disabled ? "cursor-not-allowed opacity-60" : "interact link "}`}
      style={{
        background: isPodcast
          ? "linear-gradient(180deg, #FEFFFF 0%, #D8DDF8 100%)"
          : "",
      }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <img src={icon} alt="icon" className="w-6 h-6 flex-shrink-0 no-select" />
      <span className="font-figtree text-black leading-[21px]">{text}</span>
    </div>
  );
};

export default DraftTypeCard;
