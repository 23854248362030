import React, { useEffect, useRef, useState } from "react";
import Gif from "assets/images/register/animation.gif";
import VolumeX from "assets/images/register/volume-x.svg";
import Speaker from "assets/images/register/speaker.png";
import JessicaImageCard from "./JessicaImage";
import AudioFile from "assets/audios/register/Voice-over-signup.mp3";

const AnimationCard = ({ ...props }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(AudioFile));

  const handleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const endAudio = () => {
      setIsPlaying(false);
    };
    const audio = audioRef.current;
    audio.addEventListener("ended", endAudio);
    return () => {
      audio.removeEventListener("ended", endAudio);
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    return () => {
      if (!audio.paused) {
        audio.pause();
      }
    };
  }, []);
  

  return (
    <div className={`w-full flex ${props.className}`}>
      <div className="h-full flex-1 rounded-[30px] lg:rounded-[45.497px] bg-off-white-super-light md:p-2 lg:p-5 flex flex-col justify-around gap-2 md:gap-1 ">
        <div className="text-black font-figtree tet-[22px] font-[600] leading-[130%] pl-3 hidden md:block">
          What can you expect?
        </div>
        <div className="relative md:pl-2 md:pb-2 lg:pl-3 lg:pb-3 md:flex-none flex-1 flex">
          <img
            src={Gif}
            alt="Animation"
            className="rounded-[28px] md:rounded-[30px] border border-[#D7CCC6] md:h-auto"
          />
          <div
            className="flex w-[38px] h-[38px] p-1 justify-center items-center rounded-full border border-solid border-off-white-dark absolute left-2 bottom-2 md:left-0 md:bottom-0 bg-off-white-base link interact"
            onClick={handleAudio}
          >
            <img
              src={isPlaying ? Speaker : VolumeX}
              alt="volume-x"
              className="w-[18px] h-[18px]"
            />
          </div>
        </div>
      </div>
      <JessicaImageCard />
    </div>
  );
};

export default AnimationCard;
