import React, { forwardRef } from "react";
import PropTypes from 'prop-types';

const ContentTitle = forwardRef(({
  count,
  platform,
  type,
  className,
  isDraft = true,
  id
}, ref) => {
  return (
    <div
      ref={ref}
      className={`font-figtree text-[20px] leading-[105%] text-black ${className}`}
      id={id}
    >
      {`${count || ""} ${platform} ${type || ""} ${isDraft ? (count > 1 ? "drafts" : "draft") : ""}`}
    </div>
  );
});

ContentTitle.propTypes = {
  count: PropTypes.number,
  platform: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  isDraft: PropTypes.bool,
};

export default ContentTitle;

