import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHour, setMin } from "store/preparationSlice";
import { getCurrentDateTime } from "Utils";

const TimeSelector = () => {
  const dispatch = useDispatch();
  const { hour, min, timezone, year, month, date } = useSelector(
    (state) => state.preparation
  );
  const handleChangeTime = (e) => {
    dispatch(setHour(parseInt(e.target.value.split(".")[0])));
    dispatch(setMin(parseInt(e.target.value.split(".")[1])));
  };
  const options = useMemo(() => {
    const currentDatetime = getCurrentDateTime(timezone);
    console.log(currentDatetime);

    const values = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const Hour = h.toString().padStart(2, "0");
        const Minute = m.toString().padStart(2, "0");
        console.log(
          year === currentDatetime.year &&
            month === currentDatetime.month &&
            date === currentDatetime.day &&
            (h < currentDatetime.hour ||
              (h === currentDatetime.hour && m <= currentDatetime.minute))
        );

        if (
          year === currentDatetime.year &&
          month === currentDatetime.month &&
          date === currentDatetime.day &&
          (h < currentDatetime.hour ||
            (h === currentDatetime.hour && m <= currentDatetime.minute))
        )
          continue;
        const label = `${Hour}.${Minute}`;
        values.push({ label: label, value: label });
      }
    }
    return values;
  }, [date, month, timezone, year]);

  useEffect(() => {
    const Hour = hour.toString().padStart(2, "0");
    const Minute = min.toString().padStart(2, "0");
    if (
      options.findIndex((option) => option.value === `${Hour}.${Minute}`) < 0
    ) {
      handleChangeTime({ target: { value: options[0].value } });
    }
  });

  const value = useMemo(() => {
    const formattedHour = String(hour).padStart(2, "0");
    const formattedMinute = String(min).padStart(2, "0");
    return `${formattedHour}.${formattedMinute}`;
  }, [hour, min]);
  return (
    <FormControl fullWidth>
      <InputLabel id="time-select-label">Time</InputLabel>
      <Select
        labelId="time-select-label"
        id="time-select"
        value={value}
        label="Time"
        onChange={handleChangeTime}
        sx={{ borderRadius: "30px" }}
        fullWidth
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value} sx={{ width: "100%" }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeSelector;
