import Cookies from "js-cookie";
import { useCallback } from "react";

const useCookie = () => {
  const getCookieEmail = useCallback(() => {
    return Cookies.get("jessica-register");
  }, []);
  const setCookieEmail = useCallback((email) => {
    Cookies.set("jessica-register", email);
  }, []);
  const removeCookieEmail = useCallback(() => {
    Cookies.remove("jessica-register");
  }, []);
  const getCookiePayed = useCallback(() => {
    return !!Cookies.get("jessica-payed");
  }, []);
  const setCookiePayed = useCallback((email) => {
    Cookies.set("jessica-payed", email);
  }, []);
  const removeCookiePayed = useCallback(() => {
    Cookies.remove("jessica-payed");
  }, []);
  return {
    setCookieEmail,
    getCookieEmail,
    removeCookieEmail,
    setCookiePayed,
    getCookiePayed,
    removeCookiePayed,
  };
};

export default useCookie;
