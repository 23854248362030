import React, { useEffect, useState } from "react";
import MyPosts from "./MyPosts";
import OthersPosts from "./OthersPosts";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTH_STEP,
  PERSONALIZATION_SOURCE,
  PERSONALIZATION_STEP,
  showSuccessMessage,
} from "Utils";
import {
  /*setMyPosts,*/ setMyPosts,
  setPersonalizationSource,
  setPersonalizationStep,
  setSelectedSource,
} from "store/personalizeSlice";
import useUserInfo from "hooks/useUserInfo";
import { setAuth } from "store/mainSlice";
import { BASE_SERVER_URL } from "api";
import axios from "axios";
import AddLinkedinPopup from "components/Popups/AddLinkedinPopup/AddLinkedinPopup";
// import axios from "axios";
// import useUserInfo from "hooks/useUserInfo";
// import { SERVER_URL } from "api";

const SourceSelection = () => {
  const dispatch = useDispatch();
  const { isAuth, isSignedUp } = useUserInfo();
  const [wait, setWait] = useState(false);
  const { source } = useSelector((state) => state.personalize);
  const { profile } = useUserInfo();

  const handleNextForMyPosts = async () => {
    if (wait) return;
    setWait(true);
    try {
      if (!profile.linkedin) {
        setWait(false);
        setOpen(true);
        return;
      }
      const response = await axios.get(
        `${BASE_SERVER_URL}/api/v2/feeds/content/profile-posts?profileUrl=https://www.linkedin.com/in/${profile.linkedin}/&forceCollecting=true`
      );
      dispatch(setMyPosts(response.data));
      dispatch(setPersonalizationStep(PERSONALIZATION_STEP.selectingPosts));
      if (response.data.length === 0) {
        dispatch(setPersonalizationSource(PERSONALIZATION_SOURCE.OthersPosts));
      } else {
        dispatch(setPersonalizationSource(PERSONALIZATION_SOURCE.myPosts));
      }
    } catch (error) {
      console.log(error);
    }
    setWait(false);
  };
  const handleNextForOthers = () => {
    dispatch(setPersonalizationStep(PERSONALIZATION_STEP.selectingPosts));
  };
  const handleNext = () => {
    if (!isAuth) {
      dispatch(setAuth(isSignedUp ? AUTH_STEP.sign_in : AUTH_STEP.sign_up));
      dispatch(setSelectedSource(source));
      return;
    }
    if (source === PERSONALIZATION_SOURCE.myPosts) handleNextForMyPosts();
    else handleNextForOthers();
  };

  useEffect(() => {
    dispatch(setMyPosts(null));
  }, [dispatch]);

  const successAddLinkedin = () => {
    showSuccessMessage("Succeeded to add your linkedin profile url");
  };

  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col items-center flex-1 gap-8 lg:gap-14 2xl:gap-[71px] w-full flex-shrink-0 py-8 md:py-10 lg:py-[80px] 2xl:pt-[93px] px-5 lg:px-[58px] 2xl:pb-[118px]">
      <div className="text-off-white-base font-figtree text-[24px] md:text-[30px] lg:text-[36px] leading-[122%] text-center">
        Where should we extract your ideal tone of voice from?
      </div>
      <div className="flex flex-wrap w-full justify-center gap-4 lg:gap-8">
        <MyPosts selected={source === PERSONALIZATION_SOURCE.myPosts} />
        <OthersPosts selected={source === PERSONALIZATION_SOURCE.OthersPosts} />
      </div>
      <button
        className="interact w-[215px] max-w-full rounded-full bg-primary-main px-5 py-2 font-figtree text-[15px] font-[700] tracking-[0.46px]"
        style={{
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
        }}
        disabled={wait}
        onClick={handleNext}
      >
        {wait ? "Wait..." : "Next"}
      </button>
      {open && (
        <AddLinkedinPopup
          setOpen={setOpen}
          parentWait={wait}
          // isWeekly={isWeekly}
          callback={successAddLinkedin}
        />
      )}
    </div>
  );
};

export default SourceSelection;
