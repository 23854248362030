import React, { useEffect, useRef, useState } from "react";
import playIcon from "assets/images/dashboard/PlayArrow.svg";
import pauseIcon from "assets/images/dashboard/pause.svg";
import VolumeInput from "./VolumeInput";
import AudioProgressBar from "./AudioProgressBar";
import downloadIcon from "assets/images/dashboard/download.svg";

const AudioPlayer = ({
  className = "",
  src,
  IsPlay = false,
  setIsPlay = () => {},
  onChangeDuration = () => {},
  length = 0,
}) => {
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [volume, setVolume] = useState(1);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
      setIsPlay(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
      setIsPlay(true);
    }
  };

  useEffect(() => {
    if (length) setDuration((prev) => prev || length);
  }, [length]);

  useEffect(() => {
    setIsPlaying(IsPlay);
    if (audioRef.current) {
      if (audioRef.current.paused && IsPlay) {
        audioRef.current.play();
      } else if (!audioRef.current.paused && !IsPlay) {
        audioRef.current.pause();
      }
    }
  }, [IsPlay]);

  const handleVolumeChange = (volumeValue) => {
    if (!audioRef.current) return;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  };
  const handleBufferProgress = (e) => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (
          audio.buffered.start(audio.buffered.length - 1 - i) <
          audio.currentTime
        ) {
          const bufferedLength = audio.buffered.end(
            audio.buffered.length - 1 - i
          );
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  };
  function formatDurationDisplay(duration) {
    if (!Number.isFinite(duration)) return "--:--";
    const min = Math.floor(duration / 60);
    const sec = Math.floor(duration - min * 60);
    const formatted = [min, sec].map((n) => (n < 10 ? "0" + n : n)).join(":"); // format - mm:ss
    return formatted;
  }
  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currentProgress);
  return (
    <div
      className={`w-full flex sm:gap-2.5 items-center p-5 px-2 sm:px-5 ${className}`}
    >
      <audio
        ref={audioRef}
        preload="metadata"
        className="hidden"
        onDurationChange={(e) => {
          if (Number.isFinite(e.currentTarget?.duration)) {
            onChangeDuration(e.currentTarget.duration);
            setDuration((prev) => prev || e.currentTarget?.duration || 0);
          }
        }}
        onCanPlay={(e) => {
          e.currentTarget.volume = volume;
          setIsReady(true);
        }}
        onPlaying={() => {
          setIsPlaying(true);
          setIsPlay(true);
        }}
        onPause={() => {
          setIsPlaying(false);
          setIsPlay(false);
        }}
        onTimeUpdate={(e) => {
          setCurrentProgress(e.currentTarget.currentTime);
          handleBufferProgress(e);
        }}
        onProgress={handleBufferProgress}
      >
        <source type="audio/mpeg" src={src} />
      </audio>
      <div className="font-figtree text-[14px] leading-[185%] tracking-[0.46px] max-400:flex-1">{`${elapsedDisplay}/${durationDisplay}`}</div>
      <button
        disabled={!isReady}
        onClick={togglePlayPause}
        className="flex items-center justify-center size-[42px] max-400:flex-1"
      >
        <img
          src={isPlaying ? pauseIcon : playIcon}
          alt={isPlaying ? "pause" : "play"}
          className={`${isPlaying ? "size-3.5" : "size-6"} flex-shrink-0`}
        />
      </button>
      <AudioProgressBar
        className="flex-1 max-400:hidden"
        duration={duration}
        currentProgress={currentProgress}
        buffered={buffered}
        setCurrentProgress={(value) => {
          if (!audioRef.current) return;
          audioRef.current.currentTime = value;
          setCurrentProgress(value);
        }}
      />
      <VolumeInput volume={volume} setVolume={handleVolumeChange} />
      <a
        href={src}
        download
        className="max-400:flex-1 flex items-center justify-center size-[42px]"
      >
        <button>
          <img
            src={downloadIcon}
            alt={"download"}
            className={`size-6 flex-shrink-0`}
          />
        </button>
      </a>
    </div>
  );
};

export default AudioPlayer;
