import { createSlice } from "@reduxjs/toolkit";

export const CIRCLE_TOPICS_STATUS = {
  INITIATION: "initiation",
  LOADING: "loading",
  RENDERED: "rendered",
};

const initialState = {
  data: null,
  fullName: "",
  headline: "",
  picture: null,
  mainTopic: "",
};

const circleTopicSlice = createSlice({
  name: "circleTopic",
  initialState,
  reducers: {
    setData: (state, action) => {
      if (typeof action.payload === "object") {
        state.data = action.payload;
      }
    },
    setProfile: (state, action) => {
      if ("fullName" in action.payload) {
        state.fullName = action.payload.fullName;
      }
      if ("headline" in action.payload) {
        state.headline = action.payload.headline;
      }
      if ("picture" in action.payload) {
        state.picture = action.payload.picture;
      }
    },
    setMainTopic: (state, action) => {
      state.mainTopic = action.payload
    },
    initCircleTopics: () => ({
      ...initialState,
    }),
  },
});

export const { initCircleTopics, setData, setProfile, setMainTopic } =
  circleTopicSlice.actions;

export default circleTopicSlice.reducer;
