import { createSlice } from "@reduxjs/toolkit";
import { get5MinsDate } from "Utils";

const initState = () => ({
  year: get5MinsDate().getFullYear(),
  month: get5MinsDate().getMonth(),
  date: get5MinsDate().getDate(),
  hour: get5MinsDate().getHours(),
  min: get5MinsDate().getMinutes(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  keyword: "",
  question: "",
  interviewLink: null,
  // title: "",
  eventId: null,
  recurring: false
});

// const initialState = {
//   year: get5MinsDate().getFullYear(),
//   month: get5MinsDate().getMonth(),
//   date: get5MinsDate().getDate(),
//   hour: get5MinsDate().getHours(),
//   min: get5MinsDate().getMinutes(),
//   timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
//   keyword: "",
//   question: "",
//   interviewLink: null,
//   // title: "",
//   eventId: null,
//   recurring: true,
// };

const registerSlice = createSlice({
  name: "register",
  initialState: initState(),
  reducers: {
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setHour: (state, action) => {
      state.hour = action.payload;
    },
    setMin: (state, action) => {
      state.min = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setMainTopic: (state, action) => {
      state.topic = action.action;
    },
    initRegister: () => initState(),
    initSchedule: (state) => ({...initState()}),
    setInterviewLink: (state, action) => {
      state.interviewLink = action.payload;
    },
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setQuestion: (state, action) => {
      state.question = action.payload;
    },
    setRecurring: (state, action) => {
      state.recurring = action.payload;
    }
  },
});

export const {
  initRegister,
  initSchedule,
  setDate,
  setMonth,
  setYear,
  setHour,
  setMin,
  setTimezone,
  setMainTopic,
  setInterviewLink,
  setEventId,
  setKeyword,
  setQuestion,
  setRecurring
} = registerSlice.actions;

export default registerSlice.reducer;
