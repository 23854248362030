import React from "react";
import UserCards from "components/UserCards/UserCards";
import Diamond from "assets/images/post/Diamond.svg";
import { useNavigate, useParams } from "react-router-dom";

const AskPersonalization = () => {
  const navigate = useNavigate();
  const { interviewId: iId } = useParams();
  const goToPersonalization = () => {
    navigate(`/personalize?interviewId=${iId}`);
  };
  const skip = () => {
    navigate(`/posts/${iId}?skip`);
  };
  return (
    <div className="absolute min-h-full w-full bg-black-bg flex flex-col justify-center items-center text-white p-4">
      <div className="tet-center text-[24px] leading-[140%] font-figtree pt-10 lg:pt-14 2xl:pt-20 pb-6 lg:pb-10 text-center">
        Before we starting crafting your content
      </div>
      <div className="flex-1 flex justify-center items-center w-full">
        <div className="w-[500px] lg:w-[600px] 2xl:w-[747px] max-w-full p-6 md:p-10 2xl:p-[60px] rounded-[30px] bg-[#212E4E]  flex flex-col gap-4 md:gap-10 2xl:gap-20 justify-center items-center">
          <div className="flex flex-col gap-4 md:gap-6 2xl:gap-11 w-full">
            <div className="font-figtree flex flex-col gap-2 md:gap-3.5">
              <span className="text-[20px] md:text-[24px] lg:text-[30px] 2xl:text-[36px] leading-[122%]">
                What to improve your posts? Take a minute to personalize your
                content 🚀
              </span>
              <span className="text-[14px] lg:text-[18px] leading-[138%]">
                Show us how you would like your posts to look and sound. I’ll
                personalize your content to match!
              </span>
            </div>
            <div>
              <div className="flex-col items-center w-full flex">
                <img
                  src={Diamond}
                  alt="diamond"
                  className="-mb-[40px] w-12 h-12 lg:w-16 lg:h-16 z-10"
                />
                <UserCards className="w-full" />
              </div>
            </div>
          </div>
          <div className="flex justify-center flex-wrap-reverse w-full gap-2">
            <button
              className="max-w-full w-[200px] py-2.5 rounded-[40px] border border-white bg-transparent font-inter text-[16px] font-[600] leading-[150%] interact"
              style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
              onClick={skip}
            >
              Skip
            </button>
            <button
              className="max-w-full w-[200px] py-2.5 rounded-[40px] border border-primary-main bg-primary-main font-inter text-[16px] font-[600] leading-[150%] interact"
              style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
              onClick={goToPersonalization}
            >
              Personalize Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskPersonalization;
