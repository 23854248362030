import { SERVER_URL } from "api";
import axios from "axios";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGuestMode,
  setInterviewId,
  setInterviewScore,
  setInterviewStatus,
  setTopic,
} from "store/interviewSlice";
import { setIsCheckingInterview, setProfile } from "store/mainSlice";

const useInterview = () => {
  const { interviewId, status, topic, score, evaluation, podcastUrl, guestMode } = useSelector(
    (state) => state.interview
  );
  const dispatch = useDispatch();

  const fetchInterviewScore = useCallback(
    async (id) => {
      if (id) {
        try {
          const { data } = await axios.get(
            `${SERVER_URL}/interview-sessions/${id}/score`
          );
          dispatch(setInterviewScore(data));
        } catch (error) {
          console.log(`Failed to fetch interview score : ${error}`);
        }
      }
    },
    [dispatch]
  );

  const fetchInterviewData = useCallback(
    async (iId) => {
      if (!iId) return;
      dispatch(setIsCheckingInterview(true));
      try {
        let response = await axios.get(
          `${SERVER_URL}/interview-sessions/${iId}`
        );
        dispatch(
          setTopic(response.data.interviewSessionSummary.questionOrTopic)
        );
        const user = response.data.author;
        let newProfile = {
          firstName: user.firstName,
          officialFirstName: user.officialFirstName,
          officialLastName: user.officialLastName,
          headline: user.headline,
          email: user.email,
          linkedin: user.linkedinProfileIdentifier,
        };
        if (user.picture) {
          newProfile.picture = user.picture;
        }
        dispatch(setProfile(newProfile));
        const statusStrings = {
          Created: 0,
          Opened: 1,
          Interviewing: 2,
          Done: 3,
          Failed: 4,
        };
        const statusResponse = await axios.get(
          `${SERVER_URL}/interview-sessions/${iId}/status`
        );
        dispatch(setInterviewStatus(statusStrings[statusResponse.data.status]));
        dispatch(setInterviewId(iId));
        dispatch(setGuestMode(response.data.guestMode));
        dispatch(setIsCheckingInterview(false));
        return {
          interviewId: iId,
          interviewStatus: statusStrings[statusResponse.data.status],
          topic: response.data.interviewSessionSummary.questionOrTopic,
        };
      } catch (error) {
        dispatch(setIsCheckingInterview(false));
        throw error;
      }
    },
    [dispatch]
  );
  return {
    interviewId,
    interviewStatus: status,
    topic,
    score,
    evaluation,
    guestMode,
    fetchInterviewData,
    fetchInterviewScore,
    podcastUrl
  };
};

export default useInterview;
