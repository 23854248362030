import React, { /* useCallback, */ useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUserInfo from "hooks/useUserInfo";
import { initPreparation } from "store/preparationSlice";
import { useNavigate } from "react-router-dom";
import Jessica from "assets/images/preparation/jessica-light-blue.png";
import {
  getNextDay,
  // getCurrentDateTime,
  // getWeekNumber,
  // meetingMinutes,
  RECURRING_VALUES,
} from "Utils";
import useInterview from "hooks/useInterview";

const ScheduledPopup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useUserInfo();
  const { firstName } = profile;
  const {
    scheduledId,
    topics,
    year,
    month,
    date,
    hour,
    min,
    timezone,
    recurring,
  } = useSelector((state) => state.preparation);
  const { guestMode } = useInterview();
  // const popupRef = useRef(null);

  // const closePopup = (e, force = false) => {
  //   if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
  //     dispatch(setScheduledId(null));
  //   }
  // };

  const reschedule = () => {
    dispatch(initPreparation());
  };

  const start = async () => {
    dispatch(initPreparation());
    navigate(`/meeting/${scheduledId}`);
  };

  // const getDateTimeString = useCallback(() => {
  //   let startDate = new Date(year, month, date, hour, min);
  //   let endDate = new Date(year, month, date, hour, min + meetingMinutes);

  //   if (hour < 0) {
  //     const today = getCurrentDateTime(timezone);
  //     startDate = new Date(
  //       today.year,
  //       today.month,
  //       today.day,
  //       today.hour,
  //       today.minute
  //     );
  //     endDate = new Date(
  //       today.year,
  //       today.month,
  //       today.day,
  //       today.hour,
  //       today.minute + meetingMinutes
  //     );
  //   }

  //   const formatDate = (date) => {
  //     const options = { weekday: "long", month: "long", day: "numeric" };
  //     const formattedDate = date.toLocaleDateString("en-US", options);

  //     const suffix = getNumberSuffix(date.getDate());

  //     return `${formattedDate}${suffix}, ${year}`;
  //   };

  //   const getNumberSuffix = (day) => {
  //     if (day >= 11 && day <= 13) {
  //       return "th";
  //     }

  //     const lastDigit = day % 10;

  //     switch (lastDigit) {
  //       case 1:
  //         return "st";
  //       case 2:
  //         return "nd";
  //       case 3:
  //         return "rd";
  //       default:
  //         return "th";
  //     }
  //   };

  //   const formattedStartDate = `${startDate.getHours()}:${String(
  //     startDate.getMinutes()
  //   ).padStart(2, "0")}`;
  //   const formattedEndDate = `${endDate.getHours()}:${String(
  //     endDate.getMinutes()
  //   ).padStart(2, "0")}`;

  //   const result = `${formattedStartDate} - ${formattedEndDate} ${formatDate(
  //     startDate
  //   )}`;
  //   return result;
  // }, [date, hour, min, month, timezone, year]);

  // const timeString = getDateTimeString();

  const dateTitle = useMemo(() => {
    function getWeekDayString(date) {
      const weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay();
      return weekDays[dayIndex];
    }
    const formatDate = (date) => {
      const options = { month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      const suffix = getNumberSuffix(date.getDate());
      return `${formattedDate}${suffix}, ${year}`;
    };
    const getNumberSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }

      const lastDigit = day % 10;

      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const timeString = (() => {
      const newDate = new Date(year, month, date, hour, min, 0, 0);
      newDate.setMinutes(newDate.getMinutes() + 30);
      const newHour = newDate.getHours();
      const newMin = newDate.getMinutes();
      return `${hour > 9 ? "" : "0"}${hour}:${min > 9 ? "" : "0"}${min}-${
        newHour > 9 ? "" : "0"
      }${newHour}:${newMin > 9 ? "" : "0"}${newMin}`;
    })();
    let dateString = `${timeString} ${getWeekDayString(
      new Date(year, month, date, 0, 0, 0, 0)
    )}, `;
    // let weekNumber = 0;
    switch (recurring) {
      case RECURRING_VALUES.MONTHLY:
        // weekNumber = getWeekNumber(year, month, date);
        // return `${
        //   weekNumber > 4 ? "Last" : weekNumber + getNumberSuffix(weekNumber)
        // } ${getWeekDayString(
        //   new Date(year, month, date, 0, 0, 0, 0)
        // )} at ${timeString}`;
        dateString += "Monthly";
        break;

      case RECURRING_VALUES.WEEKLY:
        dateString += "Weekly";
        break;

      case RECURRING_VALUES.NOT_REPEAT:
        dateString += formatDate(new Date(year, month, date, 0, 0, 0, 0));
        break;

      default:
        break;
    }
    return dateString;
  }, [date, hour, min, month, recurring, year]);

  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex flex-col items-center z-[100] p-2.5 top-0 left-0 overflow-auto"
      // onClick={closePopup}
    >
      <div className="flex-1 flex items-center max-w-full">
        <div
          className="scroll-dark relative rounded-[20px] sm:rounded-[30px] border-[0.59px] border-solid bg-[#f9f1ed] w-[972px] max-w-full  gap-4 flex flex-col items-center"
          id="audience-popup"
          style={{
            boxShadow:
              "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
          }}
          // ref={popupRef}
        >
          <div className="max-w-full p-4 pt-8 md:p-8 md:pt-16 pb-0">
            <div className="text-black-bg text-center text-[27px] sm:text-[36px] leading-[103%] font-eighties-it w-full">
              Your Call is scheduled
            </div>
            <div className="flex flex-col items-start gap-2 max-w-full">
              <div className="flex flex-col sm:flex-row items-center justify-center gap-5 mb-2">
                <img
                  src={Jessica}
                  alt="jessica"
                  className="w-24 h-auto flex-shrink-0"
                />
                <div className="gap-2 flex flex-col flex-1 items-start">
                  <div className="capitalize font-eighties text-[20px] sm:text-[27px] font-[500] leading-[140%]">{`${guestMode?.guest?.firstName || firstName} <> ${guestMode?.assistant?.name || "Jessica"}`}</div>
                  <div className="font-eighties-it text-[20px] sm:text-[24px] leading-[120%]">
                    Personalized content creation interview
                  </div>
                </div>
              </div>
              <div className="font-figtree text-[16px] font-[700] py-2">
                {dateTitle}
              </div>
              <div className="font-figtree text-[14px] flex items-center flex-wrap gap-x-4 gap-y-1">
                <div>Voice interview, no need for a web camera</div>
                <div className="text-text-secondary">
                  <strong>Time Zone</strong> <span>{timezone}</span>
                </div>
              </div>
              <div className="font-figtree text-black-bg text-[16px] font-[500] gap-2 md:gap-4 flex flex-col max-w-full w-full">
                <div className="pt-3">
                  Chosen Topic{recurring === RECURRING_VALUES.NOT_REPEAT || "s"}
                  :
                </div>
                {(recurring === RECURRING_VALUES.NOT_REPEAT
                  ? [topics[0]]
                  : topics
                ).map((t, index) => {
                  const dateTitle = (() => {
                    // Create a Date object
                    const {
                      year: newYear,
                      month: newMonth,
                      day: newDate,
                    } = getNextDay(year, month, date, index, recurring);
                    const d = new Date(
                      newYear,
                      newMonth,
                      newDate,
                      hour,
                      min,
                      0,
                      0
                    );

                    // Format the day with suffix (e.g., 8th)
                    const daySuffix = (d) => {
                      if (d >= 11 && d <= 13) return `${d}th`;
                      switch (d % 10) {
                        case 1:
                          return `${d}st`;
                        case 2:
                          return `${d}nd`;
                        case 3:
                          return `${d}rd`;
                        default:
                          return `${d}th`;
                      }
                    };

                    // Format the full date string
                    const formattedDate = `${d.toLocaleDateString("en-GB", {
                      weekday: "long",
                    })} ${daySuffix(d.getDate())} ${d.toLocaleDateString(
                      "en-GB",
                      {
                        month: "long",
                      }
                    )}, ${d.getHours()}.${String(d.getMinutes()).padStart(
                      2,
                      "0"
                    )}`;

                    return formattedDate;
                  })();
                  return (
                    <div className="w-full space-y-3">
                      {recurring === RECURRING_VALUES.NOT_REPEAT || (
                        <div className="text-text-secondary font-figtree text-[14px] font-[500] leading-[257%]">
                          {dateTitle}
                        </div>
                      )}
                      <div
                        key={index}
                        className="px-4 py-3 relative flex items-center justify-between rounded-full bg-off-white-base w-full"
                      >
                        <div className="absolute top-0 left-3 flex flex-start items-center -translate-y-3 gap-1 w-full">
                          {t.isAI && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                              AI Suggestion
                            </div>
                          )}
                          {t.isTrending && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                              🔥Trending
                            </div>
                          )}
                          {t.isNew && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                              🌟New
                            </div>
                          )}
                          {t.isCustom && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-purple-lavender  rounded-full">
                              Custom
                            </div>
                          )}
                        </div>
                        <div className="text-ellipsis overflow-x-clip text-nowrap w-full">
                          {t.topic}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center gap-4 flex-wrap py-4 sticky bottom-0 border-t border-off-white-dark bg-off-white-light p-4 md:p-8 pt-4">
            <button
              type="submit"
              className="link interact max-w-full w-[207px] py-2 sm:py-3 text-primary-main bg-transparent border border-primary-main font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]"
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={reschedule}
            >
              Schedule another Call
            </button>
            <button
              type="submit"
              className={`max-w-full w-[207px] py-2 sm:py-3 bg-primary-main text-white border border-primary-main interact font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]`}
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={start}
            >
              Start Call Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledPopup;
