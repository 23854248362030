import React, { useRef, useState } from "react";
import "./UpgradePopup.css";
import { useDispatch } from "react-redux";
import { setBillingInfo, setShowUpgrade } from "store/mainSlice";
import CloseIcon from "assets/images/close.svg";
import avatar0 from "assets/images/users/Avatar Image.png";
import avatar1 from "assets/images/users/Avatar Image (1).png";
import avatar2 from "assets/images/users/Avatar Image (2).png";
import avatar3 from "assets/images/users/Avatar Image (3).png";
import avatar4 from "assets/images/users/Avatar Image (4).png";
import avatar5 from "assets/images/users/Avatar Image (5).png";
import avatar6 from "assets/images/users/Avatar Image (6).png";
import avatar7 from "assets/images/users/Avatar Image (7).png";
import avatar8 from "assets/images/users/Avatar Image (8).png";
import avatar9 from "assets/images/users/Avatar Image (9).png";
import image1 from "assets/images/upgrade/image1.png";
import image2 from "assets/images/upgrade/image2.png";
import image3 from "assets/images/upgrade/image3.png";
import { showErrorMessage } from "Utils";
import useUserInfo from "hooks/useUserInfo";
import useRedirect from "hooks/useRedirect";
import axios from "axios";
import { SERVER_URL } from "api";
import useCookie from "hooks/useCookie";

const UpgradePopup = () => {
  const dispatch = useDispatch();
  const { navigateCreatingRedirect } = useRedirect();
  const popupRef = useRef(null);
  const { isSubscribed, billingInfo, isCanceled } = useUserInfo();
  const { removeCookiePayed } = useCookie();
  const [wait, setWait] = useState(false);
  const goToBilling = () => {
    closePopup({}, true);
    navigateCreatingRedirect("/subscribe");
  };
  const userAvatars = [
    avatar0,
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
  ];
  const banners = [
    {
      image: image1,
      title: "Uncover trending topics",
      description:
        "We find topics with validated interest so your content is always a hit with your audience.",
    },
    {
      image: image2,
      title: "Hands-off content creation",
      description:
        "Voice interviews with Jessica are the new, effortless way, to create content.",
    },
    {
      image: image3,
      title: "5 minutes = 1 week of content",
      description:
        "Get 1 week worth of posts & blogs from just 5 minutes with Jessica.",
    },
  ];
  const closePopup = (e, force = false) => {
    if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
      dispatch(setShowUpgrade(false));
    }
  };
  const cancelSubscription = async () => {
    if (!billingInfo || isCanceled) {
      return;
    }
    setWait(true);
    try {
      await axios.post(`${SERVER_URL}/billing/cancel`);
      const newBillingInfo = { ...billingInfo };
      newBillingInfo.isCanceled = true;
      dispatch(setBillingInfo(newBillingInfo));
      dispatch(setShowUpgrade(false));
      removeCookiePayed();
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message ||
          "Failed cancel subscription, please try again"
      );
    }
    setWait(false);
  };
  return (
    <div className="upgrade-popup-container" onClick={closePopup}>
      <div className="upgrade-popup" ref={popupRef}>
        <div className="upgrade-close">
          <img
            src={CloseIcon}
            alt="close"
            onClick={(e) => closePopup(e, true)}
            className="link"
          />
        </div>
        <div className="upgrade-left">
          <div className="upgrade-title">
            Keep Creating with <span>Jessica</span>
          </div>
          <div className="upgrade-banners">
            {banners.map(({ image, title, description }, index) => (
              <div key={index} className="upgrade-banner">
                <img src={image} alt="" />
                <div className="upgrade-banner-body">
                  <div className="upgrade-banner-title">{title}</div>
                  <div className="upgrade-banner-description">
                    {description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="upgrade-right">
          <div className="upgrade-title">
            {isSubscribed ? (
              <>
                Don’t get lost in the crowd.
                <br />
                <span>Stand out with Jessica</span>
              </>
            ) : (
              <>
                Upgrade <span>to continue</span>
              </>
            )}
          </div>
          <div className="upgrade-description">
            {isSubscribed ? (
              <>
                Are you sure you want to cancel? People who use Jessica weekly
                grow their exposure 3.5x faster!
              </>
            ) : (
              <>
                Continue standing out with your authentic content, thought
                leadership, and insights. Upgrade now and{" "}
                <strong>unlock unlimited interviews with Jessica!</strong>
              </>
            )}
          </div>
          {isSubscribed ? (
            <div className="w-full flex flex-col sm:flex-row gap-2 ">
              <button
                type="button"
                className="w-full sm:w-auto sm:flex-[3] border border-solid border-cta-blue bg-none text-cta-blue py-3 xl:py-6 rounded-[40px] text-center font-figtree 2xl:text-[22px] font-semibold leading-[18px] tracking-[2.2px] uppercase"
                onClick={cancelSubscription}
              >
                {wait ? "WAIT..." : "YES, CANCEL"}
              </button>
              <button
                type="button"
                className="w-full sm:w-auto sm:flex-[4] bg-cta-blue xl:py-6 rounded-[40px] text-white font-figtree py-3 2xl:text-[22px] font-semibold leading-[18px] tracking-[2.2px] uppercase"
                onClick={(e) => closePopup(e, true)}
              >
                NO, DON'T CANCEL
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="upgrade-submit"
              onClick={goToBilling}
            >
              Upgrade Now
            </button>
          )}
          <div className="upgrade-avatars">
            {userAvatars.map((avatar, index) => (
              <img key={index} src={avatar} alt="avatar" />
            ))}
          </div>
          <div className="upgrade-used">Used by over 1000K+ people</div>
          <div className="upgrade-bubbles">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePopup;
