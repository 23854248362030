import React from "react";

const AudioProgressBar = ({
  className = "",
  duration,
  currentProgress,
  buffered,
  setCurrentProgress = () => { },
  showThumb = false,
  isBlue = false,
}) => {
  const progressBarWidth = isNaN(currentProgress / duration)
    ? 0
    : (currentProgress / duration) * 100;
  const bufferedWidth = isNaN(buffered / duration)
    ? 0
    : (buffered / duration) * 100;

  const handleClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newProgress = (offsetX / rect.width) * duration;
    console.log(newProgress);
    setCurrentProgress(newProgress);
  };

  return (
    <div className={`relative ${className}`} onClick={handleClick}>
      <div className={`link no-select w-full ${isBlue ? "bg-[#707070]" : "bg-black"} rounded-[4px] h-2 opacity-30`}></div>
      <div
        className={`link no-select absolute left-0 h-2 rounded-[4px] bg-gray-500 opacity-50 top-1/2 -translate-y-1/2`}
        style={{ width: `${bufferedWidth}%` }}
      ></div>
      <div
        className={`link no-select absolute left-0 top-1/2 -translate-y-1/2 rounded-full ${isBlue ? "h-2.5 bg-[#3E50DE]" : "h-2 bg-black"}`}
        style={{ width: `${progressBarWidth}%` }}
      ></div>
      {showThumb && <div
        className="link no-select absolute size-[18px] top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#0E24D6]"
        style={{ left: `${progressBarWidth}%` }}
      ></div>}
    </div>
  );
};

export default AudioProgressBar;
