import React, { forwardRef, useMemo } from "react";
import DragHandle from "assets/images/preparation/DragHandle.svg";
import { RECURRING_VALUES, capitalize, getNextDay } from "Utils";
import useUserInfo from "hooks/useUserInfo";
import TrashIcon from "assets/images/preparation/trash-01.svg";
import "./styles.css";
import { useSelector } from "react-redux";

const TopicItem = forwardRef(
  (
    {
      topic,
      tag,
      id,
      isTrending,
      isCustom,
      isNew,
      isAI,
      dragHandleProps,
      snapshot,
      deleteItem,
      index,
      ...props
    },
    ref
  ) => {
    const { profile } = useUserInfo();
    const { recurring, year, month, date, hour, min } = useSelector(
      (state) => state.preparation
    );
    const disabled = useMemo(() => {
      return recurring === RECURRING_VALUES.NOT_REPEAT && index > 0;
    }, [recurring, index]);
    const dateTitle = useMemo(() => {
      // Create a Date object
      const {year: newYear, month: newMonth, day: newDate} = getNextDay(year, month, date, index, recurring);
      const d = new Date(newYear, newMonth, newDate, hour, min, 0, 0);

      // Format the day with suffix (e.g., 8th)
      const daySuffix = (d) => {
        if (d >= 11 && d <= 13) return `${d}th`;
        switch (d % 10) {
          case 1:
            return `${d}st`;
          case 2:
            return `${d}nd`;
          case 3:
            return `${d}rd`;
          default:
            return `${d}th`;
        }
      };

      // Format the full date string
      const formattedDate = `${d.toLocaleDateString("en-GB", {
        weekday: "long",
      })} ${daySuffix(d.getDate())} ${d.toLocaleDateString("en-GB", {
        month: "long",
      })}, ${d.getHours()}.${String(d.getMinutes()).padStart(2, "0")}`;

      return formattedDate;
    }, [date, hour, min, month, recurring, year, index]);
    return (
      <div
        className={`max-w-full py-2 space-y-6 ${
          disabled ? "opacity-30" : "opacity-100"
        }`}
        ref={ref}
        {...props}
      >
        <div className="text-text-secondary font-figtree text-[14px] font-[500] leading-[257%]">
          {dateTitle}
        </div>
        <div
          className={
            "px-4 py-3 relative flex items-center justify-between rounded-full bg-off-white-base max-w-full" +
            (snapshot.isDragging ? "hovering" : "")
          }
        >
          <div className="absolute top-0 left-3 flex flex-start items-center -translate-y-3 gap-1">
            {isAI && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                      AI Suggestion
                    </div>
                  )}
            {tag === "Topics" && (
              <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                ✨Topic
              </div>
            )}
            {tag?.trim() === "Company Questions" && (
              <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                {`🏢Question about ${capitalize(profile.company)}`}
              </div>
            )}
            {tag?.trim() === "Expertise Questions" && (
              <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                {`Question about ${capitalize(profile.fullName)}`}
              </div>
            )}
            {isTrending && (
              <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                🔥Trending
              </div>
            )}
            {isNew && (
              <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                🌟New
              </div>
            )}
            {isCustom && (
              <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-purple-lavender  rounded-full">
                Custom
              </div>
            )}
          </div>
          <span {...dragHandleProps} className="flex-shrink-0">
            <img
              src={DragHandle}
              className="size-6 flex-shrink-0"
              alt="drag-handle"
            />
          </span>
          <div className="text-ellipsis overflow-x-clip text-nowrap w-[calc(100%-64px)]">
            {topic}
          </div>
          <img
            src={TrashIcon}
            alt="delete"
            className="w-6 h-6 flex-shrink-0 interact link"
            onClick={deleteItem}
          />
        </div>
      </div>
    );
  }
);

export default TopicItem;
