import React, { useEffect, useRef, useState } from "react";
import DownIcon from "assets/images/image-manager/chevron-down.svg";

const imageStyles = [
  {
    title: "Inspirational",
    description:
      "Creates uplifting memes with a humorous twist, maintaining a positive vibe.",
  },
  {
    title: "Sarcastic",
    description:
      "Generates memes with playful, ironic commentary on the post's content.",
  },
  {
    title: "Industry-Specific Humor",
    description:
      "Produces jokes or references tailored to specific industry experiences or stereotypes.",
  },
  {
    title: "Pop Culture References",
    description:
      "Incorporates elements from popular culture related to the post's theme for humor.",
  },
  {
    title: "Office Humor",
    description:
      "Focuses on relatable work-life situations, poking fun at typical office culture aspects.",
  },
  {
    title: "Motivational Parody",
    description:
      "Creates exaggerated, humorous takes on motivational quotes or speeches.",
  },
  {
    title: "Tech Quirks",
    description:
      "Generates humor around technology, software bugs, or digital life challenges.",
  },
  {
    title: "Historical Twist",
    description:
      "Uses historical figures or events with a modern twist related to the post's content.",
  },
  {
    title: "Animal Reactions",
    description:
      "Utilizes cute or funny animal reactions to express thoughts or feelings about the post.",
  },
  {
    title: "Meme Classics",
    description:
      "Adapts well-known meme formats to fit the theme of the LinkedIn post.",
  },
];

const ImageStyleDropdown = ({ style, setStyle }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const topicMenuRef = useRef(null);
  const closeMenu = (e) => {
    if (topicMenuRef.current && !topicMenuRef.current.contains(e.target)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", closeMenu);
    const pop = document.getElementsByClassName("pop");
    if (pop.length > 0) pop[0].addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
      if (pop.length > 0) pop[0].removeEventListener("click", closeMenu);
    };
  }, []);

  useEffect(() => {
    if (style) return;
    setStyle(imageStyles[0]);
  }, [setStyle, style]);

  return (
    <div className="w-full relative">
      <div
        className="w-full flex items-center justify-between px-3 py-1.5 border border-other-outlined-border rounded-[30px] font-figtree text-text-primary text-[16px] leading-[150%] tracking-[0.15px] link"
        onClick={() => setOpenMenu((prev) => !prev)}
        ref={topicMenuRef}
      >
        <span>{style?.title}</span>
        <img src={DownIcon} alt="down" className="size-6 flex-shrink-0" />
      </div>
      <div className="absolute top-0 left-3 -translate-y-1/2 bg-off-white-light text-text-secondary font-figtree text-[12px] leading-[100%] tracking-[0.15px]">
        Style
      </div>
      <div className="relative w-full max-h-0">
        {openMenu && (
          <div className="absolute z-10 w-[calc(100%-24px)] left-1/2 -translate-x-1/2 rounded-b-[30px] overflow-hidden">
            <div className="bg-white w-full">
              {imageStyles.map((style, index) => (
                <div
                  key={index}
                  className="link px-4 py-2.5 w-full font-figtree text-[14px] leading-[143%] tracking-[0.15px] text-text-primary flex flex-col items-start justify-center"
                  onClick={() => {
                    setStyle(style);
                  }}
                >
                  <span>{style.title}</span>
                  <span className="text-text-secondary">
                    {style.description}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageStyleDropdown;
