import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
// import { ReactComponent as DropdownIcon } from "assets/images/dropdownIcon.svg";
import "./style.css";
import { useDispatch } from "react-redux";
import UserLogo from "assets/images/user-black.png";
import { setAuth, setIsSlim } from "store/mainSlice";
import { AUTH_STEP } from "Utils";
import ProfileMenu from "./ProfileMenu";
import useUserInfo from "hooks/useUserInfo";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "assets/images/others/Menu.svg";
import useCustomTheme from "hooks/useCustomTheme";
// import { Link } from "react-router-dom";
// import ListIcon from "assets/images/List.svg";
// import { CloseRounded } from "@mui/icons-material";

const Navbar = ({ showLogo = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const { isAuth, isSignedUp, profile } = useUserInfo();
  // const [showSideMenu, setShowSideMenu] = useState(false)
  // const closeMenu = () => {
  //   setShowSideMenu(false)
  //   setTimeout(() => setOpen(false), 500)
  // }
  // const openMenu = () => {
  //   setOpen(true)
  //   setTimeout(() => setShowSideMenu(true), 10)
  // }
  useEffect(() => {
    const closeMenu = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target))
        setOpen(false);
    };
    window.addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  });

  const { isSlim } = useCustomTheme();

  const toggleSidebar = (event) => {
    event.stopPropagation();
    dispatch(setIsSlim(!isSlim));
  };


  return (
    <nav>
      <div className="links">
        {isAuth ? (
          <div
            className="p-3 cursor-pointer block md:hidden"
            onClick={toggleSidebar}
          >
            <img src={MenuIcon} alt="menu" />
          </div>
        ) : (
          showLogo ? <Logo className="logo" onClick={() => navigate("/")}/> : <div></div>
        )}

        {/* <div className="navigation">
              <div className="nav-item">Home</div>
              <div className="nav-item">
                Products <DropdownIcon />
              </div>
              <div className="nav-item">
                Resources <DropdownIcon />
              </div>
              <div className="nav-item">Pricing</div>
            </div> */}
      </div>
      <div className="links">
        <div className="navigation">
          {/* <div className="nav-item">Log in</div> */}
          {!isAuth ? (
            isSignedUp ||
            !profile.email ||
            currentRoute === "/" ||
            currentRoute === "/schedule" ? (
              <span
                className="nav-item link"
                onClick={() => dispatch(setAuth(AUTH_STEP.sign_in))}
              >
                Sign in
              </span>
            ) : (
              <span
                className="nav-item link"
                onClick={() => dispatch(setAuth(AUTH_STEP.sign_up))}
              >
                Sign up
              </span>
            )
          ) : (
            <span
              onClick={() => setOpen(true)}
              className="nav-item md:relative"
              ref={menuRef}
            >
              <img src={UserLogo} alt="user-logo" />
              {open && <ProfileMenu setOpen={setOpen} />}
            </span>
          )}
        </div>
      </div>
      {/* <div className="container mobile">
          { <div>
            <img src={ListIcon} alt="menu" onClick={openMenu}/>
            <div className={`aside-container ${!open ? "hide" : ""}`}>
              <div className={"side-menu" + (showSideMenu ? ' active' : '')}>
              <div className="side-item close" onClick={closeMenu}><CloseRounded fontSize="large" className="close-icon"/> </div>
                <div className="side-item" onClick={closeMenu}>Home</div>
                <div className="side-item" onClick={closeMenu}>
                  Products
                  <DropdownIcon />
                </div>
                <div className="side-item" onClick={closeMenu}>
                  Resources
                  <DropdownIcon />
                </div>
                <div className="side-item" onClick={closeMenu}>Pricing</div>
              </div>
            </div>
          </div> }
          <a href="https://www.q-ueue.ai/?utm_source=Jessica&utm_medium=registration-page">
            <Logo className="logo" />
          </a>
          { <div className="nav-item">Log in</div> }
        </div> */}
    </nav>
  );
};

export default Navbar;
