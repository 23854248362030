import React, { useEffect, useRef, useState } from "react";
import MyPostCard from "./MyPostCard";
import PostPopup from "./PostPopup";
import { PERSONALIZATION_SOURCE, showErrorMessage } from "Utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from "api";
import useUserInfo from "hooks/useUserInfo";
import { setProfile } from "store/mainSlice";
import { setPersonalizationSource } from "store/personalizeSlice";
import { setFetchedPosts } from "store/postSlice";

const PostsSelection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [wait, setWait] = useState(false);
  const { myPosts } = useSelector((state) => state.personalize);
  const { profile } = useUserInfo();
  const handleSubmit = async () => {
    if (wait) return;
    if (myPosts?.length > 2 && selectedIndices.length < 3) {
      showErrorMessage("Please select 3 posts for optimal personalization.");
      return;
    }
    setWait(true);
    try {
      const posts = myPosts
        .filter(
          (_, index) =>
            selectedIndices.findIndex(
              (selectedIndex) => selectedIndex === index
            ) > -1
        )
        .map((myPost) => myPost.text);
      await axios.post(`${SERVER_URL}/personalization/`, {
        email: profile.email,
        posts,
      });
      dispatch(setProfile({ personalized: true }));
      const urlParams = new URLSearchParams(window.location.search);
      const interviewId = urlParams.get("interviewId");
      await axios.post(`${SERVER_URL}/personalization/generate`, {
        interviewId,
      });
      dispatch(setFetchedPosts(false));
      navigate(
        interviewId
          ? `/complete-personalize?interviewId=${interviewId}`
          : "/complete-personalize"
      );
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to personalize your contents. Please try again");
    }
    setWait(false);
  };
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [showIndex, setShowIndex] = useState(-1);
  const isShowingSelected =
    selectedIndices.findIndex((selectedIndex) => selectedIndex === showIndex) >
    -1;
  const isSelectable = selectedIndices.length < 3;
  const select = (index) => {
    if (wait || myPosts.length < 3) return;
    const selected =
      selectedIndices.findIndex((selectedIndex) => selectedIndex === index) >
      -1;
    setSelectedIndices((indices) =>
      !selected
        ? isSelectable
          ? [...indices, index]
          : [...indices]
        : indices.filter((aIndex) => aIndex !== index)
    );
  };
  useEffect(() => {
    if (!myPosts) return;
    if (myPosts.length === 1) {
      setSelectedIndices([0]);
    }
    if (myPosts.length === 2) {
      setSelectedIndices([0, 1]);
    }
  }, [myPosts]);
  const isShowNext =
    myPosts &&
    (myPosts.length > 2 ||
      myPosts.reduce((sum, post) => post.text.length + sum, 0) > 100);
  const isShowSwitch = myPosts && myPosts.length < 3;
  const buttonBoxRef = useRef(null);
  const [paddingBottom, setPaddingBottom] = useState(0);
  useEffect(() => {
    const run = () => {
      if (buttonBoxRef.current) {
        setPaddingBottom(buttonBoxRef.current.getBoundingClientRect().height);
      }
    };

    run()
  
    window.addEventListener("DOMContentLoaded", run);
    window.addEventListener("resize", run);
  
    return () => {
      window.removeEventListener("DOMContentLoaded", run);
      window.removeEventListener("resize", run);
    };
  }, [buttonBoxRef]); // Add buttonBoxRef as a dependency

  return (
    <div className="flex-1 w-full flex flex-col justify-center items-center px-4 py-4 md:py-8 gap-5 md:gap-10">
      <div className="text-off-white-base font-figtree text-[30px] leading-[146%] text-center">
        Choose 3 posts that will define your tone of voice
      </div>
      {isShowNext && isShowSwitch && (
        <div className="text-off-white-base text-center font-figtree text-[20px] sm:leading-[120%] md:leading-[220%]  w-[1060px] max-w-full">
          Oops, you don't have enough posts. We need at least 3 posts to analyze
          your tone of voice accurately. Proceed with your posts or consider
          extracting a tone of voice from a LinkedIn user whose style inspires
          you!
        </div>
      )}
      {!isShowNext && isShowSwitch && (
        <div className="text-off-white-base text-center font-figtree text-[20px] sm:leading-[120%] md:leading-[220%]  w-[1060px] max-w-full">
          Your post is a bit short (under 100 words), it will be tough to
          pinpoint your desired tone of voice. Instead, choose some LinkedIn
          posts from writers whose style you admire – we’ll analyze the tone
          you'd like to convey!
        </div>
      )}
      <div
        className="w-full max-w-[1200px] flex flex-wrap justify-center items-center gap-[26px] flex-1"
        style={{ paddingBottom: `${paddingBottom}px` }}
      >
        {myPosts &&
          myPosts.map((post, index) => {
            const selected =
              selectedIndices.findIndex(
                (selectedIndex) => selectedIndex === index
              ) > -1;
            return (
              <MyPostCard
                key={index}
                show={() => setShowIndex(index)}
                selected={selected}
                select={() => select(index)}
                isSelectable={isSelectable}
                post={post}
              />
            );
          })}
      </div>
      <div
        className="w-full flex flex-wrap-reverse items-center justify-center gap-3.5 fixed bg-[#101828] p-5 pb-10 bottom-0 left-0 z-10"
        ref={buttonBoxRef}
      >
        {isShowSwitch && (
          <button
            className="link interact w-[240px] max-w-full rounded-[40px] bg-transparent py-2 px-5 text-white font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] border border-white"
            // style={{
            //   boxShadow:
            //     "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            // }}
            onClick={() =>
              dispatch(
                setPersonalizationSource(PERSONALIZATION_SOURCE.OthersPosts)
              )
            }
            disabled={wait}
          >
            Extract from other’s posts
          </button>
        )}
        {isShowNext && (
          <button
            className={`link interact w-[240px] max-w-full rounded-[40px] ${
              wait || (myPosts.length > 2 && selectedIndices.length < 3)
                ? "bg-[#969696] border-[#969696]"
                : "bg-primary-main border-primary-main"
            } py-2 px-5 text-white font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] border`}
            style={{
              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
            onClick={handleSubmit}
            disabled={
              wait || (myPosts.length > 2 && selectedIndices.length < 3)
            }
          >
            {wait ? "Wait..." : "Next"}
          </button>
        )}
      </div>
      {showIndex > -1 && (
        <PostPopup
          selected={isShowingSelected}
          select={() => select(showIndex)}
          setOpenPopup={(value) => {
            if (!value) setShowIndex(-1);
          }}
          selectable={isSelectable}
          post={myPosts[showIndex]}
        />
      )}
    </div>
  );
};

export default PostsSelection;
