import React, {
  /* useEffect, */ useCallback,
  useEffect,
  useRef,
} from "react";
import quoteIcon from "assets/images/dashboard/“.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUserInfo from "hooks/useUserInfo";
import useInterview from "hooks/useInterview";
import { setAuth, setShowUpgrade } from "store/mainSlice";
import { setIsPreview } from "store/postSlice";
import { AUTH_STEP } from "Utils";
import PodcastCard from "./PodcastCard";

const PodcastBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quotesRef = useRef(null);
  const dotsRef = useRef(null);
  const { isSubscribed, isAuth, isSignedUp } = useUserInfo();
  const countRef = useRef(0);
  const timerRef = useRef(0);
  const { interviewId } = useInterview();
  const { posts, articles, threads, tweets, answers, reels, quotes } =
    useSelector((state) => state.post);

  const timerFunc = useCallback(() => {
    if (quotesRef.current?.children[countRef.current])
      quotesRef.current.children[countRef.current].style.opacity = 0;
    if (dotsRef.current?.children[countRef.current])
      dotsRef.current.children[countRef.current].style.backgroundColor =
        "#99afff";
    countRef.current = (countRef.current + 1) % quotes.length;
    if (quotesRef.current?.children[countRef.current])
      quotesRef.current.children[countRef.current].style.opacity = 1;
    if (dotsRef.current?.children[countRef.current])
      dotsRef.current.children[countRef.current].style.backgroundColor =
        "#0e24d6";
  }, [quotes.length]);

  const showQuote = useCallback(
    (index) => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = 0;
      }
      if (index !== countRef.current) {
        if (quotesRef.current?.children[countRef.current])
          quotesRef.current.children[countRef.current].style.opacity = 0;
        if (quotesRef.current?.children[index])
          quotesRef.current.children[index].style.opacity = 1;
        if (dotsRef.current?.children[countRef.current])
          dotsRef.current.children[countRef.current].style.backgroundColor =
            "#99afff";
        if (dotsRef.current?.children[index])
          dotsRef.current.children[index].style.backgroundColor = "#0e24d6";
      }
      countRef.current = index;
      timerRef.current = setInterval(timerFunc, 5 * 1000);
    },
    [timerFunc]
  );

  useEffect(() => {
    const timer = setInterval(timerFunc, 5 * 1000);
    timerRef.current = timer;
    return () => {
      clearInterval(timer);
    };
  }, [timerFunc]);

  const touchXRef = useRef(-1);

  useEffect(() => {
    const touchStartHandle = (e) => {
      if (quotesRef.current && quotesRef.current.contains(e.target))
        touchXRef.current = e.changedTouches[0].clientX;
      else touchXRef.current = -1;
    };
    const touchEndHandle = (e) => {
      if (touchXRef.current > -1) {
        if (e.changedTouches[0].clientX - touchXRef.current < -10)
          showQuote(
            countRef.current === 0 ? quotes.length - 1 : countRef.current - 1
          );
        if (e.changedTouches[0].clientX - touchXRef.current > 10)
          showQuote((countRef.current + 1) % quotes.length);
      }
      touchXRef.current = 0;
    };
    window.addEventListener("touchstart", touchStartHandle);
    window.addEventListener("touchend", touchEndHandle);
    return () => {
      window.removeEventListener("touchstart", touchStartHandle);
      window.removeEventListener("touchend", touchEndHandle);
    };
  }, [quotes.length, showQuote]);

  const fullyAvailable = [
    ...posts,
    ...articles,
    ...threads,
    ...tweets,
    ...answers,
    ...reels,
    ...quotes
  ].every((post) => !post.isOpenedFirst);

  const EditQuote = () => {
    if (isAuth) {
      if (isSubscribed || fullyAvailable || quotes[countRef.current].isOpenedFirst) {
        navigate(`/posts/${interviewId}/${quotes[countRef.current]._id}`);
        dispatch(setIsPreview(false));
      } else {
        dispatch(setShowUpgrade(true));
        return;
      }
    } else if (isSignedUp) {
      dispatch(setAuth(AUTH_STEP.sign_in));
      return;
    } else {
      dispatch(setAuth(AUTH_STEP.sign_up));
      return;
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full">
        {quotes?.length > 0 && (
          <div
            className="border border-off-white-dark rounded-[20px] p-3"
            style={{
              background: "linear-gradient(to right, #FE896830, #0000)",
            }}
          >
            <div className="w-full flex-1 flex items-start gap-2 py-2 min-h-[120px]">
              <div
                className="font-figtree text-[14px] lg:text-[18px] text-black min-h-[120px] flex-1 relative"
                ref={quotesRef}
              >
                {quotes.map((quote, index) => (
                  <div
                    key={index}
                    style={{ opacity: index === 0 ? 1 : 0 }}
                    className="transition-opacity duration-[1000] ease-linear absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex items-start gap-2"
                  >
                    <img
                      src={quoteIcon}
                      alt="quote"
                      className="w-[21px] h-[15px] inline"
                    />{" "}
                    {`${quote.title}`}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex items-center">
              <button
                className="interact border border-primary-main text-primary-main py-1 px-2.5 font-figtree text-[13px] font-[700] leading-[22px] tracking-[0.46px] rounded-[40px]"
                onClick={EditQuote}
              >
                Publish as a Short Post
              </button>
              <div className="flex-1 relative" ref={dotsRef}>
                {quotes.map((_, index, quotes) => (
                  <div
                    key={index}
                    className={`absolute rounded-full right-0 top-0 size-2 link transition-colors duration-[1000] ease-linear`}
                    style={{
                      transform: `translate(-${(quotes.length - index) * 12
                        }px, -50%)`,
                      backgroundColor: index === 0 ? "#0e24d6" : "#99afff",
                    }}
                    onClick={() => showQuote(index)}
                  ></div>
                ))}
              </div>
            </div>
          </div>
        )}
        <PodcastCard />
      </div>
    </>
  );
};

export default PodcastBlock;
