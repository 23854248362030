import React from "react";

const Terms = () => {

  return (
    <div className="absolute w-full min-h-full p-4 pb-8 flex items-center">
      <div className="w-full min-h-full flex items-center justify-center gap-8">
        Terms and conditions
      </div>
    </div>
  );
};

export default Terms;
