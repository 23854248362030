import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "api";
import { CIRCLE_TOPICS_SOURCES, cutLinkedin, showErrorMessage } from "Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  CIRCLE_TOPICS_STATUS,
  initCircleTopics,
  setData,
  setMainTopic,
  setProfile,
} from "store/circleTopicSlice";
import CircleInput from "./components/CircleInput";
import CircleResults from "./components/CircleResults";
import "./CircleTopics.css";
import CircleLoading from "./components/CircleLoading";
import CircleStartPopup from "./components/CircleStartPopup";

const checkCompanyLinkedin = (linkedin) => {
  return linkedin.includes("company/");
};

const CircleTopics = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(CIRCLE_TOPICS_STATUS.INITIATION);
  const { mainTopic } = useSelector((state) => state.circle);

  useEffect(() => {
    dispatch(initCircleTopics());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (value, source) => {
      setStatus(CIRCLE_TOPICS_STATUS.LOADING);
      const isCompanyLinkedin = checkCompanyLinkedin(value);
      try {
        let response = null;
        if (source === CIRCLE_TOPICS_SOURCES.linkedin)
          response = await axios.get(
            `${SERVER_URL}/services/topics-new?linkedin=https://www.linkedin.com/${
              isCompanyLinkedin ? "company" : "in"
            }/${cutLinkedin(value)}`
          );
        if (source === CIRCLE_TOPICS_SOURCES.twitter)
          response = await axios.get(
            `${SERVER_URL}/services/topics-new?twitter=https://x.com/${cutLinkedin(
              value
            )}`
          );
        if (source === CIRCLE_TOPICS_SOURCES.website) {
          response = await axios.get(
            `${SERVER_URL}/services/topics-new?website=${value}`
          );
        }
        dispatch(setProfile(response.data));
        const topics = response.data.topics;
        const firstKey = Object.keys(topics)[0];
        const childKeys = Object.keys(topics[firstKey]);
        if (childKeys.includes("AccountType")) {
          let Child = "Company" in topics[firstKey] || "Personal" in topics[firstKey] ?  topics[firstKey] : topics[firstKey]["AccountType"]
          const thirdKey =
            "Company" in Child
              ? "Company"
              : "Personal";
          dispatch(
            setData({
              name: firstKey,
              children: Object.values(
                Child[thirdKey]
              ).map((obj) => ({
                name: obj.Topic,
                children: Object.values(obj.Questions).map((q) => ({
                  name: q,
                  value: 1,
                })),
              })),
            })
          );
        } else {
          dispatch(
            setData({
              name: firstKey,
              children: childKeys.map((key) => ({
                name: key,
                children: isFinite(
                  parseInt(Object.keys(topics[firstKey][key])[0])
                )
                  ? Object.values(topics[firstKey][key]).map((obj) => ({
                      name: obj.Topic,
                      children: Object.values(obj.Questions).map((q) => ({
                        name: q,
                        value: 1,
                      })),
                    }))
                  : Object.keys(topics[firstKey][key]).map((k) => ({
                      name: k,
                      children: Object.values(topics[firstKey][key][k]).map(
                        (obj) => ({
                          name: obj.Topic,
                          children: Object.values(obj.Questions).map((q) => ({
                            name: q,
                            value: 1,
                          })),
                        })
                      ),
                    })),
              })),
            })
          );
        }
        setStatus(CIRCLE_TOPICS_STATUS.RENDERED);
      } catch (error) {
        console.log(error);
        showErrorMessage("Failed to get topics. Please try again");
        setStatus(CIRCLE_TOPICS_STATUS.INITIATION);
      }
    },
    [dispatch]
  );

  return (
    <div className="w-full flex-1 flex flex-col justify-center items-center bg-transparent p-4 gap-12 relative">
      <div className="absolute h-1/2 w-[130%] top-0 -translate-y-1/3 -translate-x-1/2 left-1/2 rounded-[2075px] opacity-50 bg-[#E7FB96] blur-[262px] -z-10"></div>
      <div className="flex items-center flex-col justify-center gap-4 text-black font-figtree">
        <span className="text-[24px] sm:text-[32px] lg:text-[48px] leading-[116.7%] text-center">
          Discover Tailored Content Ideas Instantly in a Few Seconds
        </span>
        <span className="text-[16px] lg:text-[20px] tracking-[0.15px] leading-[160%] text-center">
          Enter your URL to unlock personalized content ideas that amplify your
          message across platforms!
        </span>
      </div>
      {
        <CircleInput
          onSubmit={handleSubmit}
          hidden={status !== CIRCLE_TOPICS_STATUS.INITIATION}
        />
      }
      {status === CIRCLE_TOPICS_STATUS.RENDERED && <CircleResults />}
      {status === CIRCLE_TOPICS_STATUS.LOADING && <CircleLoading />}
      {!!mainTopic && (
        <CircleStartPopup
          setOpenPopup={(value) => {
            if (!value) dispatch(setMainTopic(""));
          }}
        />
      )}
    </div>
  );
};

export default CircleTopics;
