import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import "./style.css";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Select(props) {
  const { options, label, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setValue = (value) => {
    onChange({ target: { value } });
    handleClose();
  };

  const [focused, setFocused] = React.useState(false);
  const componentRef = React.useRef(null);

  const removeFocus = (e) => {
    if (!componentRef.current?.contains(e.target)) {
      setFocused(false);
    } else setFocused(true);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", removeFocus);
    return () => {
      document.removeEventListener("mousedown", removeFocus);
    };
  }, []);

  return (
    <div
      ref={componentRef}
      onClick={(e) => {
        setFocused(true);
      }}
    >
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="none"
        onClick={handleClick}
        endIcon={anchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        className={"select-component-label" + (focused ? " focus" : "")}
        style={{ textTransform: "none" }}
      >
        {label}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => setValue(option.value)} disableRipple>
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
