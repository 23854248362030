import React, { useRef, useState } from "react";
import PauseCircle from "assets/images/podcast/PauseCircle.svg";
import PlayCircle from "assets/images/podcast/PlayCircle.svg";
import FrameIcon from "assets/images/dashboard/Frame.svg";
import FrameDarkIcon from "assets/images/dashboard/FrameDark.svg";
import { useSelector } from "react-redux";
import Button, { ButtonVariant } from "components/Button";

const FullAudioPlyer = () => {
    const { podcast } = useSelector(state => state.post);
    const audioRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume] = useState(1);
    const [currentProgress, setCurrentProgress] = useState(0);
    // const [buffered, setBuffered] = useState(0);
    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current?.pause();
            setIsPlaying(false);
        } else {
            audioRef.current?.play();
            setIsPlaying(true);
        }
    };

    const handleBufferProgress = (e) => {
        const audio = e.currentTarget;
        const dur = audio.duration;
        if (dur > 0) {
            for (let i = 0; i < audio.buffered.length; i++) {
                if (
                    audio.buffered.start(audio.buffered.length - 1 - i) <
                    audio.currentTime
                ) {
                    // const bufferedLength = audio.buffered.end(
                    //     audio.buffered.length - 1 - i
                    // );
                    // setBuffered(bufferedLength);
                    break;
                }
            }
        }
    };
    function formatDurationDisplay(duration) {
        if (!Number.isFinite(duration)) return "0.00 sec";
        return duration >= 60
            ? `${(duration / 60).toFixed(2)} min`
            : `${duration.toFixed(2)} sec`;
    }
    const durationDisplay = formatDurationDisplay(duration);
    return <div className="flex flex-start items-center gap-2.5 text-blue-grey font-figtree text-[12px] leading-[120%]">
        <audio
            ref={audioRef}
            preload="metadata"
            className="hidden"
            onDurationChange={(e) => {
                if (Number.isFinite(e.currentTarget?.duration)) {
                    setDuration((prev) => prev || e.currentTarget?.duration || 0);
                }
            }}
            onCanPlay={(e) => {
                e.currentTarget.volume = volume;
                setIsReady(true);
            }}
            onPlaying={() => {
                setIsPlaying(true);
            }}
            onPause={() => {
                setIsPlaying(false);
            }}
            onTimeUpdate={(e) => {
                setCurrentProgress(e.currentTarget.currentTime);
                handleBufferProgress(e);
            }}
            onProgress={handleBufferProgress}
        >
            <source type="audio/mpeg" src={podcast?.highlight_audio_url} />
        </audio>
        <Button variant={ButtonVariant.GHOST} icon={isPlaying ? PauseCircle : PlayCircle} alt={isPlaying ? "pause" : "play"} iconClassName={`size-6 flex-shrink-0 ${isPlaying ? "p-0" : "p-0.5"}`} disabled={!isReady}
            onClick={togglePlayPause} />
        <div className="flex items-center gap-2 relative">
            <img
                src={FrameIcon}
                alt="frame"
                className="h-5 w-6 inline-block"
            />
            <img
                src={FrameIcon}
                alt="frame"
                className="h-5 w-6 inline-block"
            />
            <img
                src={FrameIcon}
                alt="frame"
                className="h-5 w-6 inline-block"
            />
            <img
                src={FrameIcon}
                alt="frame"
                className="h-5 w-6 inline-block"
            />
            <div className="absolute left-0 flex items-center gap-2 top-0 overflow-hidden" style={{ width: `${duration ? currentProgress / duration * 100 : 0}%` }}>
                <img
                    src={FrameDarkIcon}
                    alt="frame"
                    className="h-5 w-6 min-w-6 inline-block flex-shrink-0"
                />
                <img
                    src={FrameDarkIcon}
                    alt="frame"
                    className="h-5 w-6 inline-block flex-shrink-0"
                />
                <img
                    src={FrameDarkIcon}
                    alt="frame"
                    className="h-5 w-6 inline-block flex-shrink-0"
                />
                <img
                    src={FrameDarkIcon}
                    alt="frame"
                    className="h-5 w-6 inline-block flex-shrink-0"
                />
            </div>
        </div>
        <span>{durationDisplay}</span>
    </div>
}

export default FullAudioPlyer;
