import React, { useEffect, useRef, useState } from "react";
// import HelpCircle from "assets/images/help-circle.svg";
import { showErrorMessage } from "Utils";
import axios from "axios";
import { BASE_SERVER_URL, SERVER_URL } from "api";
import { useDispatch } from "react-redux";
import useUserInfo from "hooks/useUserInfo";
import { setAudience } from "store/mainSlice";
import { setIsSettingUpAudience } from "store/preparationSlice";
import AudienceComplete from "./components/AudienceComplete";
import CancelIcon from "assets/images/preparation/Cancel.svg";
import AddLinkedinPopup from "components/Popups/AddLinkedinPopup/AddLinkedinPopup";

const SetAudiencePopup = ({ parentWait }) => {
  const dispatch = useDispatch();
  const { profile } = useUserInfo();
  const popupRef = useRef(null);
  const [wait, setWait] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [draftIndustries, setDraftIndustries] = useState([]);
  const [draftTitles, setDraftTitles] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!profile.audience) {
      setIndustries([]);
      setJobTitles([]);
    } else {
      setIndustries(profile.audience.industries);
      setJobTitles(profile.audience.jobTitles);
    }
  }, [profile.audience]);

  const closePopup = (e, force = false) => {
    if (wait || parentWait) return;
    if (force || (popupRef.current && !popupRef.current.contains(e.target))) {
      dispatch(setIsSettingUpAudience(false));
    }
  };

  const submit = async (event, newLinkedin = profile.linkedin) => {
    event.preventDefault();
    if (wait || parentWait) return;
    if (!newLinkedin) {
      setOpen(true);
      return;
    }
    setWait(true);
    try {
      await axios.post(`${SERVER_URL}/audience`, {
        email: profile.email,
        industries,
        jobTitles,
        linkedinUrl: newLinkedin,
      });
      dispatch(setAudience({ industries, jobTitles }));
      closePopup({}, true);
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to set up your audience, please try again");
    }
    setWait(false);
  };

  const handleIndustryKeywordChange = async (keyword) => {
    if (keyword.trim() === "") {
      setDraftIndustries([]);
      return;
    }
    try {
      const response = await axios.get(
        `${BASE_SERVER_URL}/api/v2/users/onboarding/industries?q=${keyword}`
      );
      setDraftIndustries(
        response.data.filter((industry) => !industries.includes(industry))
      );
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to fetch data");
    }
  };

  const handleSelectIndustry = (newIndustry) => {
    if (newIndustry.trim() === "") return;
    setIndustries((prev) => [...prev, newIndustry]);
  };

  const handleTitleKeywordChange = async (keyword) => {
    if (keyword.trim() === "") {
      setDraftTitles([]);
      return;
    }
    try {
      const response = await axios.get(
        `${BASE_SERVER_URL}/api/v2/users/onboarding/job-title?q=${keyword}`
      );
      setDraftTitles(
        response.data.filter((title) => !jobTitles.includes(title))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectTitle = (newTitle) => {
    if (newTitle.trim() === "") return;
    setJobTitles((prev) => [...prev, newTitle]);
  };

  const disabled =
    wait || parentWait || industries.length === 0 || jobTitles.length === 0;

  const setAudienceCallback = async (_, newLinkedin) => {
    submit({ preventDefault: () => {} }, newLinkedin);
  };

  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex flex-col items-center z-[100] p-2.5 top-0 left-0 overflow-auto"
      onClick={closePopup}
    >
      <div className="flex-1 flex items-center max-w-full" id="audience-popup">
        <div
          className="rounded-[20px] sm:rounded-[30px] border-[0.59px] border-solid bg-[#f9f1ed] w-[972px] max-w-full p-4 pt-8 md:p-8 md:pt-16 gap-4 md:gap-8 flex flex-col items-center"
          id="audience-popup"
          style={{
            boxShadow:
              "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
          }}
          ref={popupRef}
        >
          <span className="text-black-bg text-center text-[22px] sm:text-[24px] leading-[170%]">
            🎯 Set up your Audience
          </span>
          <span className="text-center text-text-primary font-figtree text-[14px] leading-[140%] max-w-full w-[645px]">
            Set up your Audience to receive topic suggestions tailored to
            audience interests, so every post you create is a hit.
          </span>
          <div className="w-[469px] max-w-full flex flex-col gap-4 md:gap-9">
            <div className="w-full flex flex-col gap-3 items-start">
              <AudienceComplete
                options={draftIndustries}
                label="Industries"
                onKeywordChange={handleIndustryKeywordChange}
                onSelect={handleSelectIndustry}
                placeholder="Search Industries"
              />
              <div className="flex items-center flex-wrap gap-2">
                {industries.map((industry, index) => (
                  <div
                    key={index}
                    className="p-1 flex items-center gap-0 flex-shrink-0 bg-blue-200 rounded-[16px]"
                  >
                    <div className="p-1.5 text-text-primary font-roboto text-[13px] leading-[18px] tracking-[0.16px]">
                      {industry}
                    </div>
                    <img
                      src={CancelIcon}
                      alt="remove"
                      className="w-6 h-6 link interact"
                      onClick={() => {
                        setIndustries((prev) =>
                          prev.filter((i) => i !== industry)
                        );
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col gap-3 items-start">
              <AudienceComplete
                options={draftTitles}
                label="Customer professions"
                onKeywordChange={handleTitleKeywordChange}
                onSelect={handleSelectTitle}
                placeholder="Search Title"
              />
              <div className="flex items-center flex-wrap gap-2">
                {jobTitles.map((title, index) => (
                  <div
                    key={index}
                    className="p-1 flex items-center gap-0 flex-shrink-0 bg-blue-200 rounded-[16px] "
                  >
                    <div className="p-1.5 text-text-primary font-roboto text-[13px] leading-[18px] tracking-[0.16px]">
                      {title}
                    </div>
                    <img
                      src={CancelIcon}
                      alt="remove"
                      className="w-6 h-6 link interact"
                      onClick={() => {
                        setJobTitles((prev) => prev.filter((i) => i !== title));
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center gap-4 flex-nowrap">
            <button
              type="submit"
              className="link interact max-w-full w-[157px] py-2 sm:py-3 text-primary-main bg-transparent border border-primary-main font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]"
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              onClick={() => closePopup({}, true)}
            >
              {"Cancel"}
            </button>
            <button
              type="submit"
              className={`max-w-full w-[157px] py-2 sm:py-3 ${
                disabled
                  ? "bg-action-disabled-background text-action-disabled border"
                  : "bg-primary-main text-white border border-primary-main interact"
              } font-inter text-[16px] font-[600] leading-[150%] rounded-[40px]`}
              style={{
                boxShadow: disabled
                  ? "none"
                  : "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
              disabled={disabled}
              onClick={submit}
            >
              {wait || parentWait ? "Wait..." : "Continue"}
            </button>
          </div>
        </div>
      </div>

      {open && (
        <AddLinkedinPopup
          setOpen={setOpen}
          parentWait={wait}
          isWeekly={false}
          callback={setAudienceCallback}
        />
      )}
    </div>
  );
};

export default SetAudiencePopup;
