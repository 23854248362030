import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDuration,
  setPlayingAudio,
  setProgress,
  setVolume,
} from "store/meetingSlice";
import getBlobDuration from "get-blob-duration";
import { SPEED_VALUES } from "Utils";

const AudioPlayer = ({ audioContext }) => {
  const audioRef = useRef(null);
  // const analyserRef = useRef(null);
  const durationRef = useRef(Infinity);
  const progressRef = useRef(0);
  // const trackRef = useRef(null);
  const {
    isPlayingAudio: isPlaying,
    audio,
    speedStep,
  } = useSelector((state) => state.meeting);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleFinished = () => {
      dispatch(setPlayingAudio(false));
      dispatch(setVolume(0));
      dispatch(setProgress(0));
      progressRef.current = 0;
    };

    const getDuration = async (src) => {
      try {
        const blobDuration = await getBlobDuration(src);
        dispatch(setDuration(blobDuration));
        durationRef.current = blobDuration;
        return blobDuration || 0;
      } catch (error) {
        console.error("An error occurred while getting the duration:", src, error);
        return 0; // Returning 0 in case of an error
      }
    };    

    const handleTimeUpdate = async () => {
      if (!audioRef.current) return;
      const currentProgress =
        (audioRef.current.currentTime /
          (await getDuration(audioRef.current.src))) *
        100;
      dispatch(setProgress(currentProgress));
      progressRef.current = audioRef.current?.currentTime || 0;
      // if (!analyserRef.current) {
      //   dispatch(setVolume(0));
      //   return;
      // }
      // const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      // analyserRef.current.getByteFrequencyData(dataArray);

      // let sum = 0;
      // for (let i = 0; i < dataArray.length; i++) {
      //   sum += dataArray[i];
      // }
      // let average = sum / dataArray.length;
      // dispatch(setVolume(average));
    };

    const processAudio = async () => {
      try {
        if (!audio) {
          if (audioRef.current) {
            audioRef.current.stop();
            audioRef.current = null;
          }
          // if (trackRef.current) trackRef.current.disconnect();
          // if (analyserRef.current) analyserRef.current.disconnect();
          handleFinished();
        } else {
          if (audio !== audioRef.current?.src) {
            if (audioRef.current) {
              audioRef.current.pause();
              audioRef.current = null;
              progressRef.current = 0;
            }
            let audioElement = new Audio(audio);
            audioElement.playbackRate = audio.includes("blob")
              ? 1
              : SPEED_VALUES[speedStep];
            await getDuration(audio);
            audioElement.crossOrigin = "anonymous";
            audioElement.currentTime = 0;
            audioRef.current = audioElement;

            // if (audioContext) {
            //   if (trackRef.current) trackRef.current.disconnect();
            //   if (analyserRef.current) analyserRef.current.disconnect();
            //   const track = audioContext.createMediaElementSource(audioElement);
            //   const analyser = audioContext.createAnalyser();
            //   analyser.fftSize = 256; // Change this value for more or less data
            //   track.connect(analyser);
            //   analyser.connect(audioContext.destination);
            //   analyserRef.current = analyser;
            //   trackRef.current = track;
            // }
          }

          const isPlaybackRateChanged =
            audioRef.current &&
            !audioRef.current.src.includes("blob") &&
            Math.abs(
              audioRef.current?.playbackRate -
                parseFloat(SPEED_VALUES[speedStep])
            ) > 1e-6; //  check if audio src exists and audio src is a blob recorded user's voice and playbackRate is changed
          if (isPlaybackRateChanged) {
            if (audioRef.current) {
              audioRef.current.pause();
              audioRef.current = null;
            }
            let audioElement = new Audio(audio);
            audioElement.playbackRate = SPEED_VALUES[speedStep];
            getDuration(audio);
            audioElement.currentTime = progressRef.current;
            audioElement.crossOrigin = "anonymous";
            audioRef.current = audioElement;

            // if (audioContext) {
            //   if (trackRef.current) trackRef.current.disconnect();
            //   if (analyserRef.current) analyserRef.current.disconnect();
            //   const track = audioContext.createMediaElementSource(audioElement);
            //   const analyser = audioContext.createAnalyser();
            //   analyser.fftSize = 256; // Change this value for more or less data
            //   track.connect(analyser);
            //   analyser.connect(audioContext.destination);
            //   analyserRef.current = analyser;
            //   trackRef.current = track;
            // }
          }
          // if (audioRef.current && audioContext && !analyserRef.current) {
          //   if (trackRef.current) trackRef.current.disconnect();
          //   if (analyserRef.current) analyserRef.current.disconnect();
          //   const track = audioContext.createMediaElementSource(audioRef.current);
          //   const analyser = audioContext.createAnalyser();
          //   analyser.fftSize = 256; // Change this value for more or less data
          //   track.connect(analyser);
          //   analyser.connect(audioContext.destination);
          //   analyserRef.current = analyser;
          //   trackRef.current = track;
          // }
          if (isPlaying) audioRef.current.play();
          else audioRef.current.pause();
        }

        if (audioRef.current) {
          audioRef.current.addEventListener("ended", handleFinished);
          audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
        }
      } catch (error) {
        console.log(error);
      }
    };

    processAudio();

    return () => {
      // Cleanup the event listener when component unmounts
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", handleFinished);
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [dispatch, audio, isPlaying, speedStep]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  return <></>;
};

export default AudioPlayer;
