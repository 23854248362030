import { Player } from "@lottiefiles/react-lottie-player";
import React, { useEffect, useRef, useState } from "react";
import animationJson from "../animations/loading.json";

const CircleLoading = ({ hidden }) => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setValue((prev) => (prev >= 99 ? prev : prev + 1));
    }, (300 / 30) * 30);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const lottieRef = useRef(null);

  useEffect(() => {
    const mainTag = document.getElementsByTagName("main")[0]
    if(!mainTag) return;
    mainTag.scrollTo({
      top: Math.min(window.innerHeight, window.innerWidth) * 0.04 + 234,
      behavior: "smooth",
    });
  }, [])

  useEffect(() => {
    if (lottieRef.current) {
      const animationInstance = lottieRef.current;
      // Play from frame 0 to frame 180 (assuming 30fps for 6 seconds)
      animationInstance.playSegments?.([0, 110], true);
    }
  }, []);
  return (
    <div
      className={`${
        hidden ? "hidden" : "flex"
      } w-[min(140vh,140vw)] h-[min(140vh,140vw)] flex-col items-center gap-14 circle-loader -m-[calc(0.16*min(100vh,100vw))] relative overflow-hidden`}
    >
      <div
        className={`absolute w-[min(95.3vh,95.3vw)] h-[min(95.3vh,95.3vw)] rounded-full ${
          value >= 99 ? "bg-[#92acf5]" : "bg-[#D5D5D5]"
        } top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center`}
      ></div>
      <Player
        autoplay
        speed={(0.118 * 30) / 30}
        src={animationJson}
        className="w-[min(140vh,140vw)]"
        ref={lottieRef}
      />
      <div className="absolute w-[min(30vh,30vw)] h-[min(30vh,30vw)] rounded-full bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
        <span className="text-[5vw]">{value}%</span>
      </div>
    </div>
  );
};

export default CircleLoading;
