import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment-timezone";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTimezone } from "store/preparationSlice";

const TimeZoneSelector = () => {
  const dispatch = useDispatch();
  const handleChangeTimeZone = (e) => {
    dispatch(setTimezone(e.target.value));
  };
  const { timezone } = useSelector((state) => state.preparation);

  const timezones = useMemo(() => {
    return moment.tz.names().map((timezone) => ({
      value: timezone,
      label: timezone,
    }));
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="timezone-label">TimeZone</InputLabel>
      <Select
        labelId="timezone-label"
        id="timezone-select"
        value={timezone}
        label="TimeZone"
        onChange={handleChangeTimeZone}
        sx={{ borderRadius: "30px" }}
        fullWidth
      >
        {timezones.map((option, index) => (
          <MenuItem key={index} value={option.value} sx={{ width: "100%" }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeZoneSelector;
