import React, { useMemo } from "react";
import "./MonthSelector.css";
import Select from "components/Select";
import { useDispatch, useSelector } from "react-redux";
import { setMonth, setYear } from "store/preparationSlice";

const MonthSelector = (props) => {
  const dispatch = useDispatch()
  const { month, year } = useSelector((state) => state.preparation);
  const monthOptions = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  const currentYear = new Date().getFullYear();
  const filteredMonthOptions =
    year === currentYear
      ? monthOptions.filter((option) => option.value >= new Date().getMonth())
      : monthOptions;
  const range = 10;
  const yearOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i <= range; i++) {
      const year = currentYear + i;
      options.push({ value: year, label: year });
    }
    return options;
  }, [currentYear]);
  return (
    <div className="month-selector">
      <Select
        options={filteredMonthOptions}
        value={month}
        label={monthOptions.find(option => option.value === month)?.label}
        onChange={(e) => dispatch(setMonth(e.target.value))}
      />
      <Select
        options={yearOptions}
        value={year}
        label={yearOptions.find(option => option.value === year)?.label}
        onChange={(e) => dispatch(setYear(e.target.value))}
      />
    </div>
  );
};

export default MonthSelector;
