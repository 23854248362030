import { useEffect, useState } from "react";
import { SERVER_URL } from "api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setBillingInfo } from "store/mainSlice";

const useFastSpring = () => {
  const dispatch = useDispatch();
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const openPaymentPopup = (
    fastSpringProduct,
    { email, firstName, lastName }
  ) => {
    console.log("open billing popup");
    setIsClosed(false);
    setIsSubscribing(true);
    try {
      window.fastspring.builder.clean();
      window.fastspring.builder.reset();
      window.fastspring.builder.push({
        reset: true,
        products: [
          {
            path: fastSpringProduct,
            quantity: 1,
          },
        ],
        paymentContact: {
          email,
          firstName,
          lastName,
        },
        language: "en",
      });
      window.fastspring.builder.checkout();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.onPaymentPopupClosed = (orderReference) => {
      setIsClosed(true);
      setIsSubscribing(false);
      if (orderReference) {
        window.fastspring.builder.reset();
        window.fastspring.builder.clean();
      }
    };
    window.onPaymentError = (data) => {
      console.log("payment error", data);
    };
    window.onPaymentCompleted = async (data) => {
      console.log(data);
      if (data) {
        try {
          const res = await axios.post(
            `${SERVER_URL}/billing/status`,
            {
              orderId: data.id,
              accountId: data.account,
              live: data.live, // is test mode
            },
            {
              withCredentials: true,
            }
          );
          if (res.data.success) {
            console.log("Payment success");
            try {
              const response = await axios.get(`${SERVER_URL}/billing/info`);
              dispatch(setBillingInfo(response.data));
              setIsCompleted(true);
            } catch (error) {
              console.log("Failed fetching payment details : ", error);
              dispatch(setBillingInfo(null));
              setIsFailed(true);
            }
          } else {
            console.log("Payment Failed");
            setIsFailed(true);
          }
        } catch (e) {
          console.log(`Error while payment : ${e}`);
          setIsFailed(true);
        }
      } else {
        setIsFailed(true);
      }
      setIsSubscribing(false);
    };
    return () => {
      delete window.onPaymentPopupClosed;
      delete window.onPaymentError;
      delete window.onPaymentCompleted;
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (isSubscribed) {
  //     setIsSubscribing(false);
  //   }
  // }, [isSubscribed]);

  return {
    isSubscribing,
    openPaymentPopup,
    isCompleted,
    isFailed,
    isClosed,
  };
};

export default useFastSpring;
