import React from "react";
import "./Personalized.css";
import image1 from "assets/images/upgrade/image2.png";
import image2 from "assets/images/upgrade/image3.png";
import image3 from "assets/images/upgrade/image4.png";
import { useNavigate } from "react-router-dom";
import jessica from "assets/images/personalize/jessica-light-blue.png";

const Personalized = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const interviewId = urlParams.get("interviewId");
  const goToPosts = () => {
    if (interviewId) {
      navigate(`/posts/${interviewId}?skip`);
    } else navigate("/schedule");
  };
  const banners = [
    {
      image: image1,
      title: "More impactful posts",
      description:
        "Watch as your posts resonate with your audience better than ever before",
    },
    {
      image: image2,
      title: "Less editing time",
      description:
        "Save time editing. Your posts will sound exactly like you wrote them yourself.",
    },
    {
      image: image3,
      title: "Stand out",
      description:
        "Establish a recognizable professional personal brand with a consistent tone of voice.",
    },
  ];
  return (
    <div className="personalized-container">
      <div className="personalized">
        <div className="upgrade-left">
          <div className="upgrade-title font-figtree">Personalization completed 🎉</div>
          <div className="upgrade-banners">
            {banners.map(({ image, title, description }, index) => (
              <div key={index} className="upgrade-banner">
                <img src={image} alt="banner" />
                <div className="upgrade-banner-body">
                  <div className="upgrade-banner-title">{title}</div>
                  <div className="upgrade-banner-description">
                    {description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="upgrade-right">
          <div className="upgrade-title italic font-eighties text-center">You did awesome!</div>
          <div className="upgrade-description">
            Hang tight for 1 minute while I create all the content for you
            (don’t close this tab!)
          </div>
          <img src={jessica} alt="jessica" className="w-[125px] h-auto"/>
          <div className="font-figtree text-center text-[20xp] leading-[120%]">
            Review & publish your posts to{" "}
            <strong>
              grow your exposure and become a leading voice in your niche
            </strong>{" "}
            🚀
          </div>
          <div className="font-figtree text-center text-[20xp] leading-[120%]">
            <i>Jessica identifies trending topics within your field,</i> and
            helps you create hands-off content to{" "}
            <i>position you as a go-to expert.</i>
          </div>
          <button
            type="button"
            className="upgrade-submit"
            onClick={goToPosts}
          >
            {interviewId ? "See your posts" : "Schedule your interview"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Personalized;
