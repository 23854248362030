import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import data0 from "./data0.json";
import * as d3 from "d3";
import backIcon from "assets/images/others/flip-backward.svg";
// import moreIcon from "assets/images/others/plus-circle.svg";
import starIcon from "assets/images/others/Star 1.svg";
import RotateIcon from "assets/images/others/Icon.svg";
import Tooltip from "components/Tooltip";
import Jessica from "assets/images/book.png";
import { showErrorMessage } from "Utils";
import { BASE_SERVER_URL } from "api";
import { Check, Share } from "@mui/icons-material";
import { setMainTopic } from "store/circleTopicSlice";

const CircleResults = ({ hidden }) => {
  const dispatch = useDispatch();
  const [topic, setTopic] = useState("");
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [selected, setSelected] = useState("");
  const [selectedPosition, setSelectedPosition] = useState({ x: 0, y: 0 });
  const { fullName, headline, picture, data } = useSelector(
    (state) => state.circle
  );
  const ref = useRef();
  const topicRef = useRef(null);
  const timerRef = useRef(0);
  const popupTimerRef = useRef(0);
  const rotationRef = useRef(0);

  const applyTopic = (newTopic) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setTopic(newTopic);
    }, 200);
    const timer = timerRef.current;
    return () => {
      clearTimeout(timer);
    };
  };

  const applyShowPopup = useCallback((value) => {
    if (popupTimerRef.current) clearTimeout(popupTimerRef.current);
    popupTimerRef.current = setTimeout(() => {
      if (value || !inPopupRef.current) setShowPopup(value);
    }, 200);
    const timer = popupTimerRef.current;
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const reRotateTexts = useCallback(() => {
    if (!ref.current) return;
    const svg = d3.select(ref.current);
    svg
      .selectAll("g")
      .selectAll("text")
      .attr("transform", function (d) {
        const radius = 928 / 6;
        const angle =
          (((d.current.x0 + d.current.x1) / 2) * 180) / Math.PI +
          rotationRef.current;
        const x = (((d.current.x0 + d.current.x1) / 2) * 180) / Math.PI;
        const y = ((d.current.y0 + d.current.y1) / 2) * radius;
        return `rotate(${
          x - 90
        }) translate(${y},0) rotate(${angle % 360 < 180 ? 0 : 180})`;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function getAbsolutePosition(element) {
      const rect = element.getBoundingClientRect();
      const scrollLeft = window.scrollY || document.documentElement.scrollLeft;
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      return {
        x: rect.left + scrollLeft + rect.width / 2,
        y: rect.top + scrollTop + rect.height / 2,
      };
    }
    // const data = {
    //   name: "Topics",
    //   children: data0,
    // };
    if (ref.current) {
      while (ref.current.firstChild) {
        ref.current.removeChild(ref.current.firstChild);
      }
    }

    if (!data) {
      return;
    }

    const width = 928;
    const height = width;
    const radius = width / 6;

    // Create the color scale.
    const color = d3.scaleOrdinal(
      d3.quantize(d3.interpolateRainbow, data.children.length + 1)
    );

    // Compute the layout.
    const hierarchy = d3
      .hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value);
    const root = d3.partition().size([2 * Math.PI, hierarchy.height + 1])(
      hierarchy
    );
    root.each((d) => (d.current = d));

    // Create the arc generator.
    const arc = d3
      .arc()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d) => d.y0 * radius)
      .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

    // Create the SVG container.
    const svg = d3
      .select(ref.current)
      .attr("viewBox", [-width / 2, -height / 2, width, width])
      .style("font", "10px sans-serif");

    // Append the arcs.
    const path = svg
      .append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", (d) => {
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr("fill-opacity", (d) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attr("pointer-events", (d) => (arcVisible(d.current) ? "auto" : "none"))
      .attr("d", (d) => arc(d.current))
      .on("mouseenter", (e, d) => {
        if (!d.children) {
          if (!e.currentTarget) return;

          // Get absolute position of the arc element
          const position = getAbsolutePosition(e.currentTarget);
          if (window.innerWidth < 768) {
            return;
          }
          setSelected(d.data.name);
          setSelectedPosition({
            x: position.x,
            y: position.y,
          });
          applyShowPopup(true);
        } else {
          if (window.innerWidth >= 768) {
            applyTopic(d.data.name);
            return;
          }
        }
      })
      .on("mouseleave", (_, d) => {
        if (!d.children) {
          applyShowPopup(false);
        } else {
          applyTopic("");
        }
      }).on("click", (_, d) => {
        if (!d.children) {
          if (window.innerWidth < 768) {
            dispatch(setMainTopic(d.data.name));
            return;
          }
        }
      });

    // Make them clickable if they have children.
    path.filter((d) => d.children).on("click", clicked);
    path.style("cursor", "pointer");

    function wrapText(text, width, limit = 999) {
      text.each(function () {
        let text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineHeight = 1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy") || 0),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        let lineCount = 1;
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            if (lineCount >= limit) {
              line.push("...");
              tspan.text(line.join(" "));
              return;
            }
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", lineHeight + dy + "em")
              .text(word);
            lineCount += 1;
          }
        }
        text
          .select("tspan")
          .attr("dy", -(lineCount / 2) * lineHeight + dy + 0.5 + "em");
      });
    }

    function wrapHeadline(text, width, limit = 999) {
      text.each(function () {
        let text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineHeight = 0.7, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy") || 0),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        let lineCount = 1;
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            if (lineCount >= limit) {
              line.push("...");
              tspan.text(line.join(" "));
              return;
            }
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", lineHeight + dy + "em")
              .text(word);
            lineCount += 1;
          }
        }
        text
          .select("tspan")
          .attr("dy", -(lineCount / 2) * lineHeight + dy + "em");
      });
    }

    const label = svg
      .append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.1em")
      .attr("fill-opacity", (d) => +labelVisible(d.current))
      .attr("transform", (d) => labelTransform(d.current))
      .text((d) => d.data.name)
      .on("mouseenter", (_, d) => {
        if (!d.children) {
          applyShowPopup(true);
        } else {
          if (window.innerWidth > 768) {
            applyTopic(d.data.name);
            return;
          }
        }
      })
      .style("font-size", "12px")
      .on("mouseleave", (_, d) => {
        if (!d.children) {
          applyShowPopup(false);
        } else {
          applyTopic("");
        }
      })
      .each(function (d) {
        wrapText(d3.select(this), 100);
      })
      .style("cursor", "pointer"); // You can adjust the width as needed

    const parent = svg
      .append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all");

    svg
      .append("image")
      .attr("xlink:href", picture) // Replace with your image URL
      .attr("width", (2 * radius) / 3)
      .attr("height", (2 * radius) / 3)
      .attr("x", -radius / 3)
      .attr("y", (-radius * 2) / 3)
      .attr("class", "circle-center")
      .attr("clip-path", "url(#circleClip)");

    svg
      .append("defs")
      .attr("class", "circle-center")
      .append("clipPath")
      .attr("id", "circleClip")
      .append("circle")
      .attr("cx", 0)
      .attr("cy", -radius / 3)
      .attr("r", radius / 3);

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", radius / 6)
      .attr("font-family", "sans-serif")
      .attr("font-size", "14px")
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .style("dominant-baseline", "middle")
      .style("font-family", "Figtree")
      .style("font-weight", "600")
      .text(fullName)
      .attr("class", "circle-center");

    svg
      .append("text")
      .attr("x", 0)
      .attr("y", (radius * 2.5) / 6)
      .attr("font-family", "sans-serif")
      .attr("font-size", "14px")
      .attr("fill", "black")
      .style("text-anchor", "middle") // Center horizontally
      .style("dominant-baseline", "middle")
      .style("font-family", "Figtree")
      .style("font-weight", "400")
      .text(headline)
      .each(function (d) {
        wrapHeadline(d3.select(this), radius * 2 * 0.7, 2);
      })
      .attr("class", "circle-center");

    svg
      .append("image")
      .attr("xlink:href", backIcon) // Replace with your image URL
      .attr("width", 24)
      .attr("height", 24)
      .attr("id", "back-icon")
      .attr("x", -12)
      // .attr("x", -radius * 0.15 - 12)
      .attr("y", radius * 0.7)
      .datum(root)
      .on("click", clicked)
      .style("opacity", 0.4)
      .style("cursor", "not-allowed")
      .attr("class", "circle-center");

    // svg
    //   .append("image")
    //   .attr("xlink:href", moreIcon) // Replace with your image URL
    //   .attr("width", 24)
    //   .attr("height", 24)
    //   .style("cursor", "pointer")
    //   .attr("x", radius * 0.15 - 12)
    //   .attr("y", radius * 0.7)
    //   .attr("class", "circle-center");
    // Handle zoom on click.
    function clicked(_, p) {
      parent.datum(p.parent || root);
      root.each(
        (d) =>
          (d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          })
      );

      const t = svg.transition().duration(750);

      path
        .transition(t)
        .tween("data", (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (t) => (d.current = i(t));
        })
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", (d) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr("pointer-events", (d) => (arcVisible(d.target) ? "auto" : "none"))
        .attrTween("d", (d) => () => arc(d.current));

      label
        .filter(function (d) {
          return +this.getAttribute("fill-opacity") || labelVisible(d.target);
        })
        .transition(t)
        .attr("fill-opacity", (d) => +labelVisible(d.target))
        .attrTween("transform", (d) => () => labelTransform(d.current));
      d3.select("#back-icon")
        .transition()
        .duration(500)
        .style("opacity", p.depth > 0 ? 1 : 0.4)
        .style("cursor", p.depth > 0 ? "pointer" : "not-allowed");
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  }, [applyShowPopup, data, dispatch, fullName, headline, picture]);

  useEffect(() => {
    const setMousePosition = (event) => {
      setMouseX(event.clientX);
      setMouseY(event.clientY);
    };
    window.addEventListener("mousemove", setMousePosition);
    return () => {
      window.removeEventListener("mousemove", setMousePosition);
    };
  }, []);

  const svgContainerRef = useRef(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        (svgContainerRef.current.offsetHeight -
          parseInt(getComputedStyle(document.documentElement).fontSize) * 80) /
        2;
    }
  }, []);

  const rotateCircle = (direction) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop += direction * 100;
    }
  };

  const [rotation, setRotation] = useState(0);
  const autoRotationDelayRef = useRef(0);

  const setAutoRotation = (direction = 0) => {
    if (autoRotationDelayRef.current) {
      clearTimeout(autoRotationDelayRef.current);
      autoRotationDelayRef.current = 0;
    }
    if (direction === 0) {
      setRotation(0);
    } else {
      autoRotationDelayRef.current = setTimeout(() => {
        setRotation(direction);
      }, 300);
    }
  };

  useEffect(() => {
    if (rotation === 0) return;
    const timer = setInterval(() => {
      rotateCircle(rotation / 3);
    }, 10);
    return () => {
      clearInterval(timer);
    };
  }, [rotation]);

  const handleStart = () => {
    dispatch(setMainTopic(selected));
  };

  const inPopupRef = useRef(false);

  useEffect(() => {
    const moveToCenter = (ref) => {
      if (!ref.current) return;

      const mainTag = document.getElementsByTagName("main")[0];
      if (!mainTag) return;
      const refElement = ref.current;
      const elementRect = refElement.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + mainTag.scrollTop;
      const middleYOfElement =
        absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

      mainTag.scrollTo({
        top: middleYOfElement,
        behavior: "smooth",
      });
    };
    moveToCenter(ref);
  }, []);

  const [sharingStep, setSharingStep] = useState(0);

  useEffect(() => {
    let timer = 0;
    if (sharingStep === 1) {
      timer = setTimeout(() => {
        setSharingStep(0);
        timer = 0;
      }, 3000);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [sharingStep]);

  const startSharing = async () => {
    if (sharingStep > 0) return;
    setSharingStep(1);
    try {
      await navigator.clipboard.writeText(
        `${BASE_SERVER_URL}/api/v2/assistant/services/share-v2${window.location.search}`
      );
      setSharingStep(1);
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to share the topics. Please try again.");
    }
  };

  return (
    <div
      className={`${
        hidden ? "hidden" : "flex"
      } w-full flex-col md:flex-row items-center justify-center gap-5`}
    >
      <div className="flex-1 hidden md:block"></div>
      <div
        className="relative h-[min(98vw,98vh)] md:h-[min(60vw,98vh)] overflow-auto circle-scroll-container"
        ref={scrollContainerRef}
        onScroll={(e) => {
          const step =
            e.target.scrollTop /
            (svgContainerRef.current.offsetHeight -
              parseInt(getComputedStyle(document.documentElement).fontSize) *
                80);
          document.body.style.setProperty("--scroll", step);
          rotationRef.current = step * 10 * 360;
          reRotateTexts();
        }}
      >
        <div className="h-[99990px]" ref={svgContainerRef}>
          <svg
            ref={ref}
            className="w-[min(98vw,98vh)] md:w-[min(60vw,98vh)] sticky top-0 bottom-0 circle"
            preserveAspectRatio=""
          ></svg>
        </div>
      </div>
      <div className="flex flex-row-reverse md:flex-col gap-3 items-start flex-1">
        <Tooltip
          content={"Rotate Left"}
          side="right"
          className="py-2 px-3 rounded-[8px] bg-white text-gray-700 font-inter text-[12px] font-[600] leading-[150%]"
        >
          <button
            className="w-12 h-12 rounded-[16px] border border-off-white-dark bg-gradient1 flex items-center justify-center"
            onClick={() => rotateCircle(-1)}
            onMouseDown={() => setAutoRotation(-1)}
            onMouseUp={() => setAutoRotation(0)}
          >
            <img
              src={RotateIcon}
              alt="rotate-right"
              className=" rotate-90 md:rotate-0"
            />
          </button>
        </Tooltip>

        <Tooltip
          content={"Share"}
          side="right"
          className="py-2 px-3 rounded-[8px] bg-white text-gray-700 font-inter text-[12px] font-[600] leading-[150%]"
        >
          <button
            className="w-12 h-12 rounded-[16px] border border-off-white-dark bg-gradient1 flex items-center justify-center"
            onClick={startSharing}
            disabled={sharingStep === 1}
          >
            {sharingStep === 0 && <Share className="text-primary-main" />}
            {sharingStep === 1 && <Check className="text-primary-main" />}
          </button>
        </Tooltip>
        <Tooltip
          content={"Rotate Right"}
          side="right"
          className="py-2 px-3 rounded-[8px] bg-white text-gray-700 font-inter text-[12px] font-[600] leading-[150%]"
        >
          <button
            className="w-12 h-12 rounded-[16px] border border-off-white-dark bg-gradient1 flex items-center justify-center"
            onClick={() => rotateCircle(1)}
            onMouseDown={() => setAutoRotation(1)}
            onMouseUp={() => setAutoRotation(0)}
          >
            <img src={RotateIcon} alt="rotate-right" className="rotate-icon" />
          </button>
        </Tooltip>
      </div>
      {!!topic && !showPopup && (
        <>
          <img
            src={starIcon}
            alt="star"
            className="h-[49px] w-[89px] fixed -translate-x-1/2"
            style={{
              bottom: `${window.innerHeight - mouseY}px`,
              left: `${mouseX}px`,
            }}
          />
          <div
            className={`text-center fixed bg-white rounded-[30px] py-3 px-5 text-black-bg font-inter text-[14px] leading-[171%] capitalize max-w-full ${
              mouseX < window.innerWidth / 2
                ? "translate-x-1/2"
                : "-translate-x-1/2"
            }`}
            style={{
              bottom: `${window.innerHeight - mouseY + 14}px`,
              left: mouseX < window.innerWidth / 2 ? "auto" : `${mouseX}px`,
              right:
                mouseX < window.innerWidth / 2
                  ? `${window.innerWidth - mouseX}px`
                  : "auto",
            }}
            ref={topicRef}
          >
            {topic}
          </div>
        </>
      )}
      {showPopup && (
        <>
          <img
            src={starIcon}
            alt="star"
            className="h-[49px] w-[89px] fixed -translate-x-1/2"
            style={{
              bottom: `${window.innerHeight - selectedPosition.y}px`,
              left: `${selectedPosition.x}px`,
            }}
            onMouseEnter={() => {
              inPopupRef.current = true;
              applyShowPopup(true);
            }}
            onMouseLeave={() => {
              inPopupRef.current = false;
              applyShowPopup(false);
            }}
          />
          <div
            className={`text-center fixed bg-white rounded-[30px] py-3 px-5 text-black-bg font-inter text-[14px] leading-[171%] capitalize max-w-full ${
              selectedPosition.x < window.innerWidth / 2
                ? "translate-x-1/2"
                : "-translate-x-1/2"
            }`}
            style={{
              bottom: `${window.innerHeight - selectedPosition.y + 14}px`,
              left:
                selectedPosition.x < window.innerWidth / 2
                  ? "auto"
                  : `${selectedPosition.x}px`,
              right:
                selectedPosition.x < window.innerWidth / 2
                  ? `${window.innerWidth - selectedPosition.x}px`
                  : "auto",
            }}
            ref={topicRef}
            onMouseEnter={() => {
              inPopupRef.current = true;
              applyShowPopup(true);
            }}
            onMouseLeave={() => {
              inPopupRef.current = false;
              applyShowPopup(false);
            }}
          >
            <div className="flex items-center gap-4 min-w-[450px]">
              <img
                src={Jessica}
                alt="jessica"
                className="w-[132px] h-auto flex-shrink-0"
              />
              <div className="flex flex-col items-start justify-center text-black-bg font-figtree capitalize gap-2">
                <div className="text-left text-[16px] font-[700] leading-[150%]">{`Start discussion about "${selected}"`}</div>
                <div className="text-left text-[13px] font-[400] leading-[138%]">
                  Get 2 weeks of LinkedIn-ready content with a 5 minute
                  interview!
                </div>
                <button
                  className={`px-4 py-1.5 rounded-[40px]  font-figtree text-[14px] font-[700] leading-[171%] tracking-[0.4px] mt-3 bg-primary-main text-white interact`}
                  onClick={handleStart}
                  style={{
                    boxShadow:
                      "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  Start Call Now
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CircleResults;
