import Avatar from "components/Avatar";
import useInterview from "hooks/useInterview";
import useUserInfo from "hooks/useUserInfo";
import React from "react";

const ContentHeader = () => {
  const {
    profile: { picture, fullName, headline },
  } = useUserInfo();
  const { guestMode } = useInterview();
  return (
    <div className="w-full flex items-center gap-1">
      <Avatar className="w-[50px] flex-shrink-0" src={guestMode?.guest?.picture || picture} alt="user" />
      <div className="flex-1">
        <div className="font-figtree text-[14px] capitalize leading-[20px] font-[600]">
          {guestMode?.guest?.firstName ? `${guestMode?.guest?.firstName} ${guestMode?.guest?.lastName}` : fullName}
        </div>
        <div className="font-inter text-gray-500 leading-[18px] font-[500] text-[12p] text-nowrap text-ellipsis max-w-full">
          <span className="hidden sm:inline">
            {headline?.length > 50 ? headline?.slice(0, 50) + "..." : headline}
          </span>
          <span className="inline sm:hidden">
            {headline?.length > 25 ? headline?.slice(0, 25) + "..." : headline}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContentHeader;
