import React, { useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import TopicItem from "./TopicItem";
import { setTopics } from "store/preparationSlice";
import { useDispatch } from "react-redux";

const TopicSorter = () => {
  const dispatch = useDispatch();
  const { topics } = useSelector((state) => state.preparation);
  const setItems = (newItems) => {
    dispatch(setTopics(newItems));
  }
  const items = useMemo(() => {
    return (topics || []).map((t, index) => ({ ...t, id: index }));
  }, [topics]);
  const dragEnded = (param) => {
    const { source, destination } = param;
    let _arr = [...items];
    //extracting the source item from the list
    const _item = _arr.splice(source.index, 1)[0];
    //inserting it at the destination index.
    _arr.splice(destination.index, 0, _item);
    console.log();
    setItems(_arr.map(t => {
      const newT = {...t};
      if(newT.id) delete newT.id;
      return newT;
    }));
  };
  return (
    <div className="max-w-full w-full">
      {/* rendering comments */}
      <DragDropContext onDragEnd={dragEnded}>
        <Droppable droppableId="comments-wrapper">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items?.map((item, index) => {
                return (
                  <Draggable
                    draggableId={`comment-${item.id}`}
                    index={index}
                    key={item.id}
                  >
                    {(_provided, _snapshot) => (
                      <TopicItem
                        ref={_provided.innerRef}
                        dragHandleProps={_provided.dragHandleProps}
                        {..._provided.draggableProps}
                        snapshot={_snapshot}
                        {...item}
                        index={index}
                        deleteItem={() => setItems(items.filter((_, i) => i !== index)
                        )} />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default TopicSorter;
