import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import ArrowBack from "assets/images/post-edit/ArrowBack.svg";
import smileIcon from "assets/images/post/Smiley.svg";
import imgIcon from "assets/images/post/ImageSquare.svg";
import PostAside from "components/PostAside/PostAside";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from "api";
// import Navbar from "components/Navbar";
// import DashboardHeader from "components/DashboardHeader";
import {
  setContents,
  // setDefaultPrompt,
  setFetchedPost,
  setFetchedPosts,
  setImageManagerTab,
  setImageVersion,
  setPost,
  updatePost,
} from "store/postSlice";
import {
  AUTH_STEP,
  IMAGE_MANAGER_TABS,
  INTERVIEW_STATUS,
  showErrorMessage,
  sleep,
} from "Utils";
import EditForm from "./components/EditForm";
import EditButtons from "./components/EditButtons";
import Versions from "./components/Versions";
// import Counter from "components/Counter/Counter";
import useUserInfo from "hooks/useUserInfo";
import { setAuth, setShowUpgrade } from "store/mainSlice";
import EmojiPicker from "./components/EmojiPicker";
import Trash from "assets/images/post-edit/trash-blue.svg";
import useError from "hooks/useError";
import { setInterviewId } from "store/interviewSlice";
import useInterview from "hooks/useInterview";
import ContentHeader from "./components/ContentHeader";
import AIIcon from "assets/images/post-edit/AI.svg";
import PictureIcon from "assets/images/post-edit/image-gray.svg";
import ReactMarkdown from "react-markdown";
import reaction1 from "assets/images/personalize/react1.svg";
import reaction2 from "assets/images/personalize/react2.png";
import reaction3 from "assets/images/personalize/react3.svg";
import reaction4 from "assets/images/personalize/react4.svg";
import reaction5 from "assets/images/personalize/react5.svg";
import reaction6 from "assets/images/personalize/react6.png";
import reactIcon1 from "assets/images/personalize/likeIcon.svg";
import reactIcon2 from "assets/images/personalize/commentIcon.svg";
import reactIcon3 from "assets/images/personalize/repeatIcon.svg";
import reactIcon4 from "assets/images/personalize/sendIcon.svg";
import ImageManager from "./components/ImageManager/ImageManager";
import Animation from "components/Popups/LoadingPopUp/Animation.json"
import { Player } from "@lottiefiles/react-lottie-player";

const reactions = [
  reaction1,
  reaction2,
  reaction3,
  reaction4,
  reaction5,
  reaction6,
];

const ContentEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { interviewId, interviewStatus, fetchInterviewData } = useInterview();
  const { interviewId: iId, postId: pId } = useParams();
  const {
    post,
    version,
    fetchedPosts,
    fetchedPost,
    posts: originalPosts,
    articles: originalArticles,
    answers: originalAnswers,
    tweets: originalTweets,
    threads: originalThreads,
    reels: originalReels,
    quotes: originalQuotes,
    imageManagerTab,
  } = useSelector((state) => state.post);
  const [text, setText] = useState("");
  const { isAuth, isChecking, isSignedUp, isSubscribed, interviews } =
    useUserInfo();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { isErrorStatus } = useError();
  const [fetched, setFetched] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const isQuote = post?.platform === "Quote";

  useEffect(() => {
    if (interviewId !== iId) {
      dispatch(setInterviewId(iId));
    }
  }, [interviewId, dispatch, iId]);

  useEffect(() => {
    const fetchData = async () => {
      if (isChecking || fetched) return;
      if (fetchedPost) {
        setFetched(true);
        return;
      }
      try {
        let iIdTemp = interviewId,
          statusTemp = interviewStatus;
        if (iId !== interviewId) {
          const interviewData = await fetchInterviewData(iId);
          iIdTemp = interviewData.interviewId;
          statusTemp = interviewData.interviewStatus;
          dispatch(setInterviewId(iIdTemp));
          if (statusTemp < INTERVIEW_STATUS.DONE) {
            navigate(`/meeting/${iIdTemp}`);
            return;
          }
        }
        let posts = originalPosts,
          articles = originalArticles,
          answers = originalAnswers,
          tweets = originalTweets,
          threads = originalThreads,
          reels = originalReels,
          quotes = originalQuotes;
        if (!fetchedPosts) {
          let response = await axios.get(`${SERVER_URL}/posts/${iIdTemp}`);
          dispatch(setContents(response.data.contents));
          dispatch(setFetchedPosts(true));
        }

        let count = 0;
        let response = null;
        while (true) {
          response = await axios.get(`${SERVER_URL}/posts/${iIdTemp}/${pId}`);
          if (
            !response.data.isGenerating &&
            response.data.imageSuggestions.length > 0 &&
            response.data.templateImage.length > 0
          )
            break;
          count++;
          if (count > 120) break;
          await sleep(1000);
        }
        if (count > 100) {
          showErrorMessage(
            "Failed to generate content. Please refresh the page to try again!"
          );
          return;
        }
        const allContents = [
          ...(posts || []),
          ...(articles || []),
          ...(threads || []),
          ...(tweets || []),
          ...(answers || []),
          ...(reels || []),
          ...(quotes || []),
        ];
        const fullyAvailable = allContents.every((post) => !post.isOpenedFirst);
        if (!fullyAvailable) {
          const interviewIndex = interviews.findIndex(
            (interview) => interview._id === iIdTemp
          );
          if (interviewIndex > 1) {
            let index = allContents.findIndex((content) => content._id === pId);
            if (index < 0) {
              console.log("strange");
            } else {
              let content = { ...allContents[index] };
              content.isOpenedFirst = true;
              allContents.splice(index, 1, content);
              dispatch(setContents(allContents));
            }
          }
        }
        dispatch(setPost(response.data));
        dispatch(
          setImageVersion(
            response.data.aiImages ? response.data.aiImages.length - 1 : -1
          )
        );
        dispatch(setFetchedPost(true));
        setFetched(true);
      } catch (error) {
        console.log(error);
        if (
          await isErrorStatus(error, 401, () => {
            dispatch(
              setAuth(isSignedUp ? AUTH_STEP.sign_in : AUTH_STEP.sign_up)
            );
            navigate(`/posts/${iId}?skip`);
          })
        )
          return;
        if (
          await isErrorStatus(error, 403, () => {
            dispatch(setShowUpgrade(true));
            navigate(`/posts/${iId}?skip`);
          })
        )
          return;
        if (
          await isErrorStatus(error, 404, () => {
            navigate(`/not-found`);
          })
        )
          return;
        showErrorMessage(
          error.response?.data?.message || "Internal server error"
        );
        // navigate("/not-found");
      }
    };
    if (!isChecking) {
      if (!isAuth) {
        const message = isSignedUp
          ? "Please sign in to access this content"
          : "Please sign up to access this content";
        showErrorMessage(message);
        navigate(`/posts/${interviewId}`);
      } else {
        fetchData();
      }
    }
  }, [
    dispatch,
    iId,
    pId,
    navigate,
    isChecking,
    isAuth,
    isSubscribed,
    interviews,
    isSignedUp,
    isErrorStatus,
    interviewId,
    fetchedPosts,
    fetched,
    originalPosts,
    originalArticles,
    interviewStatus,
    fetchInterviewData,
    originalAnswers,
    originalTweets,
    originalThreads,
    originalReels,
    fetchedPost,
    originalQuotes,
  ]);

  useEffect(() => {
    setText(post?.contents[version - 1] || "");
  }, [post?.contents, version]);

  const textareaRef = useRef(null);

  const savePost = useCallback(
    async (newText = text, newVersion = version) => {
      try {
        if (version < 1) return;
        await axios.put(`${SERVER_URL}/posts/${interviewId}/${pId}`, {
          content: newText,
          version: version,
        });
        dispatch(updatePost(newText));
        return true;
      } catch (error) {
        if (
          await isErrorStatus(error, 401, () => {
            dispatch(
              setAuth(isSignedUp ? AUTH_STEP.sign_in : AUTH_STEP.sign_up)
            );
            navigate(`/posts/${interviewId}`);
          })
        )
          return false;
        console.log("Failed to save the content", error);
        return false;
      }
    },
    [
      dispatch,
      interviewId,
      isErrorStatus,
      isSignedUp,
      navigate,
      pId,
      text,
      version,
    ]
  );

  const goToPosts = async () => {
    savePost();
    navigate(`/posts/${interviewId}?skip`);
  };

  const onEmojiClick = useCallback((emojiObject) => {
    if (emojiObject.emoji) {
      if (cursorStartIndexRef.current > -1) {
        setText(
          (t) =>
            t.slice(0, cursorStartIndexRef.current) +
            emojiObject.emoji +
            t.slice(cursorEndIndexRef.current)
        );
        // textareaRef.current.focus();
        // if(scrollYRef.current >= 0){
        //   postBodyRef.current.scrollTo({top: scrollYRef.current})
        //   scrollYRef.current = -1
        // }
        setTimeout(() => {
          textareaRef.current.setSelectionRange(
            cursorStartIndexRef.current + 2,
            cursorStartIndexRef.current + 2
          );
        }, 0);
      } else {
        setText((t) => t + emojiObject.emoji);
        if (emojiObject.emoji) textareaRef.current.focus();
      }
    }
    setShowEmojiPicker(false);
  }, []);

  const pickerRef = useRef(null);
  const cursorStartIndexRef = useRef(-1);
  const cursorEndIndexRef = useRef(-1);
  const versionRef = useRef(null);
  // const scrollYRef = useRef(0);
  // const postBodyRef = useRef(null);

  useEffect(() => {
    versionRef.current = version;
  }, [version]);

  useEffect(() => {
    const closePicker = (e) => {
      if (!showEmojiPicker) return;
      if (pickerRef.current && !pickerRef.current.contains(e.target)) {
        onEmojiClick({ emoji: "" });
      }
    };
    window.addEventListener("click", closePicker);
    return () => {
      window.removeEventListener("click", closePicker);
    };
  }, [onEmojiClick, showEmojiPicker]);

  // useEffect(() => {
  //   if (!postBodyRef.current) return;
  //   const handleScroll = () => {
  //     scrollYRef.current = postBodyRef.current.scrollTop;
  //   };

  //   const textAreaElement = textareaRef.current;
  //   textAreaElement.addEventListener("blur", handleScroll);

  //   return () => {
  //     textAreaElement.removeEventListener("blur", handleScroll);
  //   };
  // }, [post]);

  const [resized, setResized] = useState({});

  useEffect(() => {
    const triggerResized = () => {
      setResized({});
    };
    window.addEventListener("resize", triggerResized);
    return () => {
      window.removeEventListener("resize", triggerResized);
    };
  }, []);

  useEffect(() => {
    const textArea = textareaRef.current;
    if (textArea) {
      textArea.style.height = `${textareaRef.current.scrollHeight + 2}px`;
      // const divElement = postBodyRef.current;
      // divElement.scrollTop = scrollYRef.current;
    }
    return () => {
      if (textArea) {
        textArea.style.height = 0;
      }
    };
  }, [text, resized]);

  const deleteImage = async () => {
    try {
      await axios.post(`${SERVER_URL}/posts/${interviewId}/${pId}/image`);
      dispatch(setPost({ ...post, images: [] }));
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to delete the image");
    }
  };

  const timerRef = useRef();

  const delaySaving = (value, updatingVersion) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => savePost(value, updatingVersion), 1000);
  };

  // const [generatedDefaultPrompt, setGeneratedDefaultPrompt] = useState(false);

  // useEffect(() => {
  //   const generateIdea = async () => {
  //     if (!post || generatedDefaultPrompt) return;
  //     try {
  //       const response = await axios.post(
  //         `${SERVER_URL}/services/image/prompt`,
  //         {
  //           style: "Inspirational",
  //           tooltip:
  //             "Creates uplifting memes with a humorous twist, maintaining a positive vibe.",
  //           content: post?.contents[post.contents.length - 1],
  //         }
  //       );
  //       dispatch(setDefaultPrompt(response.data.prompt));
  //       setGeneratedDefaultPrompt(true);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   generateIdea();
  // }, [dispatch, generatedDefaultPrompt, post]);

  if (!post || !fetched)
    return (
      <div
        className="min-h-full bg-off-white-light"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="">
          <Player src={Animation} autoplay loop />
        </div>
      </div>
    );
  return (
    <div className="editor">
      {isAuth || <PostAside />}
      <section className="">
        {/* <Counter /> */}
        {/* <Navbar /> */}
        <div
          className="flex items-center gap-2 text-primary-main font-figtree text-[14px] font-[700] tracking-[0.4px] py-3 px-7 link interact"
          onClick={goToPosts}
        >
          <img
            src={ArrowBack}
            alt="back"
            className="fill-primary-main size-5 flex-shrink-0"
          />
          <span>Back to Content Page</span>
        </div>
        {/* <DashboardHeader showPersonalization={false} /> */}
        {/* {isQuote || <Versions versionsLength={3} currentVersion={1} isVertical={true} />} */}
        <div className="w-full md:px-7 pb-7 flex-1">
          <div className="section-main h-full flex flex-col w-[652px] max-w-full bg-[#FFF9F6] rounded-[30px]">
            <div className="text-center w-full font-inter text-[14px] font-[600] leading-[142%] h-[43px] flex items-center justify-center">
              Edit
            </div>
            <div className="w-full flex-1 flex flex-col bg-white md:px-6 pt-6 pb-3 rounded-t-[30px] gap-4 border-b border-b-off-white-dark relative">
              <Versions isVertical />
              <ContentHeader />
              <textarea
                className="p-2.5 border border-dashed border-light-blue rounded-[20px] flex-grow resize-none"
                ref={textareaRef}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                  delaySaving(e.target.value, version);
                }}
                onBlur={(e) => {
                  cursorStartIndexRef.current = e.target.selectionStart;
                  cursorEndIndexRef.current = e.target.selectionEnd;
                  // scrollYRef.current = postBodyRef.current.scrollTop;
                }}
                style={{ display: isReview ? "none" : "block" }}
              />
              {isReview && (
                <ReactMarkdown children={text} className="p-2.5 flex-grow" />
              )}
              {post?.images?.length > 0 && (
                <div className="w-full relative">
                  <img
                    src={post?.images ? post?.images[0] : undefined}
                    //src={post.images.pop()}
                    alt="post"
                    className="w-full h-auto rounded-lg"
                  />
                  <div className="rounded-full p-1.5 bg-white stroke-[1px] stroke-blue-400 absolute right-4 top-5 cursor-pointer">
                    <img
                      src={Trash}
                      alt="remove"
                      className="w-6 h-6"
                      onClick={deleteImage}
                    />
                  </div>
                </div>
              )}
              {isReview || (
                <div className="flex justify-start gap-2 items-start sm:items-center h-auto w-full flex-col sm:flex-row">
                  <div className="flex justify-start gap-2 items-center h-auto flex-row">
                    <div
                      className="relative short:static sm:relative flex-shrink-0"
                      ref={pickerRef}
                    >
                      <img
                        src={smileIcon}
                        alt="smile"
                        className="w-8 h-8 cursor-pointer flex-shrink-0"
                        onClick={() => setShowEmojiPicker((val) => !val)}
                      />
                      {showEmojiPicker && (
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      )}
                    </div>
                    <img
                      src={imgIcon}
                      alt="img"
                      className="w-8 h-8 cursor-pointer"
                      onClick={() =>
                        dispatch(
                          setImageManagerTab(IMAGE_MANAGER_TABS.UPLOADER)
                        )
                      }
                    />
                  </div>
                  <div className="flex justify-start gap-2 items-center h-auto flex-row">
                    <div
                      className="flex px-2.5 py-1 flex-row items-center gap-2 rounded-[40px] border border-solid border-light-linkedin-grey cursor-pointer active:opacity-60 text-light-linkedin-grey"
                      onClick={() =>
                        dispatch(
                          setImageManagerTab(IMAGE_MANAGER_TABS.GENERATOR)
                        )
                      }
                    >
                      <img src={AIIcon} alt="ai" />
                      <span className="font-figtree text-[13px] font-[700] leading-[160%] tracking-[0.46px]">
                        Generate Image
                      </span>
                    </div>
                    <div
                      className="flex px-2.5 py-1 flex-row items-center gap-2 rounded-[40px] border border-solid border-light-linkedin-grey cursor-pointer active:opacity-60 text-light-linkedin-grey"
                      onClick={() =>
                        dispatch(
                          setImageManagerTab(IMAGE_MANAGER_TABS.TEMPLATE)
                        )
                      }
                    >
                      <img src={PictureIcon} alt="icon" />
                      <span className="font-figtree text-[13px] font-[700] leading-[160%] tracking-[0.46px]">
                        Add Image Template
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {isQuote || isReview || <EditForm />}
              {isReview && (
                <>
                  <div className="flex items-center">
                    {reactions.map((reaction, index) => (
                      <img
                        src={reaction}
                        alt="react"
                        key={index}
                        className="-mr-2 w-[25px] h-[25px]"
                      />
                    ))}
                  </div>
                  <div className="flex justify-around items-center w-full text-[#666] font-arial font-[700] tracking-[0.46px]">
                    <div className="flex items-center gap-1 px-3 py-2">
                      <img src={reactIcon1} alt="like" className="w-6 h-6" />
                      <span className="hidden sm:inline">Like</span>
                    </div>
                    <div className="flex items-center gap-1 px-3 py-2">
                      <img src={reactIcon2} alt="comment" className="w-6 h-6" />
                      <span className="hidden sm:inline">Comment</span>
                    </div>
                    <div className="flex items-center gap-1 px-3 py-2">
                      <img src={reactIcon3} alt="repeat" className="w-6 h-6" />
                      <span className="hidden sm:inline">Repeat</span>
                    </div>
                    <div className="flex items-center gap-1 px-3 py-2">
                      <img src={reactIcon4} alt="send" className="w-6 h-6" />
                      <span className="hidden sm:inline">Send</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="w-full flex justify-between rounded-b-[30px] bg-white px-6 py-3">
              <EditButtons
                savePost={savePost}
                isReview={isReview}
                setIsReview={setIsReview}
                text={text}
              />
            </div>
          </div>
        </div>
        {imageManagerTab === IMAGE_MANAGER_TABS.NO_TAB || <ImageManager />}
      </section>
    </div>
  );
};

export default ContentEditor;
