import React from "react";
import { useNavigate } from "react-router-dom";
import useUserInfo from "hooks/useUserInfo";
import UserCards from "components/UserCards/UserCards";

const Welcome = () => {
  const navigate = useNavigate();
  const { profile } = useUserInfo();

  return (
    <div className="p-6 xl:p-11 flex flex-col gap-4 md:gap-6 lg:gap-10 xl:gap-12 items-center justify-center w-full rounded-[30px] bg-off-white-light">
      <h3 className="text-black font-eighties text-[30px] lg:text-[36px] xl:text-[42px] font-semibold capitalize">
        Hi, {profile.firstName}
      </h3>
      <UserCards />
      <div className="text-black font-eighties text-[24px] lg:text-[28px] xl:text-[32px] font-semibold leading-[120%] text-center">
        Schedule our next call to create a week’s worth of posts
      </div>
      <button
        className="w-full mx-5 bg-main-blue text-center py-4 text-white font-figtree text-[22px] font-semibold leading-6 capitalize rounded-[58px]"
        style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
        onClick={() => navigate("/schedule")}
      >
        {"Choose Time & Topic"}
      </button>
    </div>
  );
};

export default Welcome;
