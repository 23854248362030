import React from "react";
import linkedin from "assets/images/register/linkedin.svg";
import dots from "assets/images/register/dots-horizontal.svg";
import defaultAvatar from "assets/images/default-user.png";
// import arrow from "assets/images/register/arrow-narrow-up-right.svg";
import message from "assets/images/register/message-square-02.svg";
import fire from "assets/images/register/fire.svg";
// import eye from "assets/images/register/eye.svg";
import thumb from "assets/images/register/thumbs-up.svg";
import Check from "assets/images/personalize/check.svg";
import useUserInfo from "hooks/useUserInfo";

const MyPostCard = ({ selected, select, show, isSelectable, post }) => {
  const { profile } = useUserInfo();
  const { officialFirstName, officialLastName, headline } = profile;
  const getDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { month: "short", day: "numeric" });
  };
  const processLargeNumber = (num) => {
    const suffixes = ["", "K", "M", "B", "T"];
    const baseUnit = 1000;

    if (num < baseUnit) return num;

    const magnitude = Math.floor(Math.log10(num) / 3);
    const scaledNum = num / Math.pow(baseUnit, magnitude);

    return `${scaledNum.toFixed(1)}${suffixes[magnitude] || "Inf"}`;
  };
  return (
    <div
      className={`relative p-5 pt-2.5 flex flex-col gap-2.5 rounded-[20px] items-center bg-white max-w-full w-[380px] border-[5px] overflow-hidden ${
        selected ? "border-blue500" : "border-black-bg"
      }`}
      style={{
        boxShadow:
          "0px 17.056px 20.467px -3.411px rgba(16, 24, 40, 0.08), 0px 6.822px 6.822px -3.411px rgba(16, 24, 40, 0.03)",
      }}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex gap-2 items-center">
          <div className="text-blue-grey font-inter text-[10px] leading-[15px]">
            Posted on:
          </div>
          <img src={linkedin} alt="linkedin" className="h-5 w-5" />
          <div className="text-blue-grey font-inter text-[10px] leading-[15px]">
            {getDateString(post.createdAt)}
          </div>
        </div>
        <div className="p-1.5 text-[#F2F4F7]">
          <img src={dots} alt="dots" className="w-2.5 h-2.5" />
        </div>
      </div>
      <div className={`flex flex-col gap-2 2xl:gap-4 items-start w-full`}>
        <div className="flex flex-row gap-1.5 w-full">
          <div
            className="avatar w-7 h-7 rounded-full"
            style={{ backgroundImage: `url(${profile.picture || defaultAvatar})` }}
          ></div>
          <div className="flex flex-col justify-center items-start flex-1 w-[calc(100%-34px)]">
            <div className="text-black font-inter text-[10px] font-semibold leading-4 capitalize">
              {`${officialFirstName} ${officialLastName}`}
            </div>
            <div className="w-full truncate self-stretch overflow-hidden text-blue-grey font-inter text-[10px] leading-[15px]">
              {headline}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-start w-full self-stretch text-black-bg font-inter text-[10px] leading-[15px] h-[30px] text-ellipsis overflow-hidden">
          {post.text}
        </div>
        <div className="flex gap-4 items-center">
          <div className="gap-1 flex items-center">
            <img src={message} alt="message" className="w-3.5 h-3.5" />
            <span className="text-center text-warning-500 text-[10.23px] font-normal font-inter leading-none">
              {processLargeNumber(post.comments)}
            </span>
            <img src={fire} alt="fire" className="h-2.5 w-auto" />
          </div>
          {/* <div className="gap-1 flex items-center">
            <img src={eye} alt="eye" />
            <span className="text-center text-blue-grey text-[10.23px] font-normal font-inter leading-none">
              1.31M
            </span>
          </div> */}
          <div className="gap-1 flex items-center">
            <img src={thumb} alt="thumb-up" />
            <span className="text-center text-blue-grey text-[10.23px] font-normal font-inter leading-none">
              {processLargeNumber(post.likes)}
            </span>
          </div>
        </div>
      </div>
      {selected && (
        <div
          className="absolute h-full w-full bg-[#A2C7FF] opacity-40 top-0 link"
          onClick={select}
        ></div>
      )}
      <div className="flex items-center justify-start gap-2 w-full z-10">
        {selected ? (
          <button
            className="rounded-full text-primary-main border border-primary-main py-1 px-4 font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px] interact flex items-center gap-2"
            style={{
              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
            onClick={select}
          >
            <img src={Check} alt="check" className="w-[18px] h-[18px]" />
            <span>Selected</span>
          </button>
        ) : (
          <button
            className={`rounded-full ${
              isSelectable ? "bg-primary-main interact" : "bg-gray-500"
            } py-1 px-4 font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px] `}
            style={{
              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            }}
            disabled={!isSelectable}
            onClick={select}
          >
            Select
          </button>
        )}
        <span
          className="font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px] interact text-primary-main link"
          onClick={show}
        >
          Show Post
        </span>
      </div>
    </div>
  );
};

export default MyPostCard;
