import Avatar from "components/Avatar";
import useUserInfo from "hooks/useUserInfo";
import React, {
  // useCallback,
  useEffect,
  // useMemo,
  useRef,
  useState,
} from "react";
import Jessica from "assets/images/dashboard/jessica-yellow.png";
import PlayArrow from "assets/images/dashboard/PlayArrow.svg";
import Pause from "assets/images/dashboard/pause.svg";
import ArrowUp from "assets/images/dashboard/chevron-up.svg";
import ArrowDown from "assets/images/dashboard/chevron-down.svg";
import { countWords } from "Utils";
import AudioPlayer from "../../../../components/AudioPlayer/AudioPlayer";
import { useDispatch, useSelector } from "react-redux";
import { setMessages } from "store/postSlice";

const MessageRow = ({ message, index, playIndex, setPlayIndex }) => {
  const { profile } = useUserInfo();
  const { messages } = useSelector((state) => state.post);
  const [open, setOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  // const isPlaying = useMemo(() => index === playIndex, [index, playIndex]);
  // const setIsPlaying = useCallback(
  //   (value) => {
  //     setPlayIndex?.(value ? index : -1);
  //   },
  //   [index, setPlayIndex]
  // );
  useEffect(() => {
    if(isPlaying) setPlayIndex(index)
  }, [index, isPlaying, setPlayIndex])
  useEffect(() => {
    if(index !== playIndex) setIsPlaying(false)
  }, [index, playIndex])
  const contentClass =
    "font-figtree text-[14px] leading-[143%] tracking-[0.15px]";
  // useEffect(() => {
  //   if (!open) {
  //     setIsPlaying(false);
  //   }
  // }, [open, setIsPlaying]);
  const [overflow, setOverflow] = useState("hidden");
  const timerRef = useRef(0);
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (open) {
      timerRef.current = setTimeout(() => {
        setOverflow("visible");
      }, 500);
    } else setOverflow("hidden");
  }, [open]);
  const durationString =
    message.duration >= 60
      ? `${(message.duration / 60).toFixed(1)}mins`
      : `${message.duration.toFixed(1)}s`;
  const dispatch = useDispatch();
  const saveDuration = (value) => {
    const newMessage = { ...message, duration: value };
    const newMessages = [...messages];
    newMessages.splice(index, 1, newMessage);
    dispatch(setMessages(newMessages));
  };
  return (
    <div className="w-full">
      <div className="w-full flex">
        <div className="h-[52px] flex items-center justify-center w-[60px] sm:w-[72px]">
          <Avatar
            src={message.role === 0 ? profile.picture : Jessica}
            alt="avatar"
            className="size-6 sm:size-8"
          />
        </div>
        <div
          className={`h-[52px] items-center justify-start p-4 flex-1 ${contentClass} overflow-hidden hidden sm:flex`}
        >
          <span className="whitespace-nowrap text-ellipsis overflow-hidden max-w-full">
            {message.content}
          </span>
        </div>
        <div className="h-[52px] flex items-center justify-start whitespace-nowrap text-ellipsis overflow-hidden p-4 pl-2 sm:pl-4 gap-2 w-[100px] xl:w-[160px]  flex-1 sm:flex-none">
          {isPlaying ? (
            <img
              src={Pause}
              alt="pause"
              className="size-3.5 link"
              onClick={() => {
                setIsPlaying(false);
              }}
            />
          ) : (
            <img
              src={PlayArrow}
              alt="play"
              className="size-6 link"
              onClick={() => {
                setIsPlaying(true);
              }}
            />
          )}
          <div className={contentClass}>{isPlaying ? "Pause" : "Play"}</div>
        </div>
        <div
          className={`h-[52px] flex items-center justify-start whitespace-nowrap text-ellipsis overflow-hidden p-4 pl-2 sm:pl-4 w-[100px] xl:w-[160px] ${contentClass}  flex-1 sm:flex-none`}
        >
          {durationString}
        </div>
        <div
          className={`h-[52px] flex items-center justify-start whitespace-nowrap text-ellipsis overflow-hidden p-4 pl-2 sm:pl-4 w-[70px] ${contentClass}`}
        >
          {countWords(message.content)}
        </div>
        <div className="h-[52px] flex items-center justify-center w-[50px]">
          <img
            src={open ? ArrowUp : ArrowDown}
            alt="more"
            className="size-6 interact link"
            onClick={() => setOpen((prev) => !prev)}
          />
        </div>
      </div>
      <div
        className="px-5 height-transition pb-4"
        style={{ maxHeight: open ? "497px" : 0, overflow }}
      >
        <AudioPlayer
          src={message.record}
          IsPlay={isPlaying}
          setIsPlay={setIsPlaying}
          length={message.duration}
          onChangeDuration={(value) => {
            if (message.duration === 0) saveDuration(value);
          }}
        />
        <div className="border border-dashed rounded-[30px] border-off-white-dark max-h-[395px] overflow-y-hidden">
          <div className="overflow-auto h-full w-full py-5 px-2.5 flex flex-col gap-4 justify-start scroll-dark">
            <strong>Transcription</strong>
            <span className="whitespace-pre text-pretty">
              {message.content}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageRow;
