import { createSlice } from "@reduxjs/toolkit";
import { INTERVIEW_STATUS } from "Utils";

const initialState = {
  selectingTopic: false,
  interviewId: null,
  year: new Date().getFullYear(),
  month: new Date().getMonth(),
  date: new Date().getDate(),
  hour: -1,
  min: -1,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  topic: "",
  recurring: false,
  interviewStatus: INTERVIEW_STATUS.CREATED,
};

const manageSlice = createSlice({
  name: "manage",
  initialState,
  reducers: {
    init: () => ({ ...initialState }),
    setInterviewId: (state, action) => {
      state.interviewId = action.payload;
    },
    setInterview: (state, action) => {
      const interview = action.payload;
      if (!interview) {
        return initialState;
      }
      state.min = interview.schedule.minute;
      state.hour = interview.schedule.hour;
      state.date = interview.schedule.day;
      state.month = interview.schedule.month;
      state.year = interview.schedule.year;
      state.topic = interview.topic;
      state.recurring = interview.recurring;
      state.interviewStatus = interview.status;
    },
    setSelectingTopic: (state, action) => {
      state.selectingTopic = action.payload;
    },
    setMainTopic: (state, action) => {
      state.topic = action.payload;
    },
  },
});

export const {
  init,
  setInterview,
  setInterviewId,
  setMainTopic,
  setSelectingTopic,
} = manageSlice.actions;

export default manageSlice.reducer;
