import React, { useRef } from "react";
import Jessica from "assets/images/book.png";
import CloseIcon from "assets/images/post-edit/x-close.svg";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import congAnimation from "assets/animations/congrats-checkout.json";
import useUserInfo from "hooks/useUserInfo";
import LinkedinIcon from "assets/images/post-done/linkedin-light.svg";

const SuccessPopup = ({ setOpenPopup }) => {
  const navigate = useNavigate();
  const { profile } = useUserInfo();
  const popupRef = useRef(null);
  const closePopup = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target))
      setOpenPopup(false);
  };
  const goToLinkedinActivities = () => {
    window.open(
      `https://www.linkedin.com/in/${profile.linkedin}/recent-activity/all/`,
      "_blank"
    );
  };
  return (
    <div
      className="fixed w-full h-full bg-[#131313aa] flex items-center justify-center z-[100] min-h-max p-4"
      onClick={closePopup}
    >
      <div
        className="relative rounded-[30px] overflow-hidden bg-off-white-light w-[687px] flex flex-col items-center gap-8 p-4 sm:p-6"
        style={{
          boxShadow:
            "0px 6.157434463500977px 88.47000122070312px 0px #959da524",
        }}
        ref={popupRef}
      >
        <div className="absolute right-5 top-5 cursor-pointer z-[11]">
          <img
            src={CloseIcon}
            alt="close"
            onClick={() => setOpenPopup(false)}
          />
        </div>
        <div className="w-full flex items-center justify-center absolute flex-1 h-full top-0 left-0 -z-0">
          <div className="relative w-[100000px] h-full top-0 left-0 flex items-center justify-center checkout-congrats">
            <Player loop autoplay src={congAnimation} className="conf"></Player>
            <Player loop autoplay src={congAnimation} className="conf"></Player>
            <Player loop autoplay src={congAnimation} className="conf"></Player>
            <Player loop autoplay src={congAnimation} className="conf"></Player>
            <Player loop autoplay src={congAnimation} className="conf"></Player>
          </div>
        </div>
        <div className="flex flex-col gap-4 z-10 w-full items-center">
          <div className="w-[519px] max-w-full text-black-bg text-center font-eighties-it text-[32px] sm:text-[48px] font-[400] leading-[103%] mt-5 sm:mt-0">
            Your post has been published
          </div>
          <div className="w-full text-black-bg text-center font-figtree text-[20px] leading-[120%]">
            Congratulations! You’re one step closer to becoming the go-to expert
            in your niche.
          </div>
        </div>
        <img
          src={Jessica}
          alt="jessica"
          className="w-[100px] sm:w-[140px] h-auto  z-10"
        ></img>
        <div className="w-full flex items-center justify-center flex-wrap gap-3 z-10">
          <button
            className="w-[258px] max-w-full rounded-[40px] text-primary-main font-inter text-[16px] font-semibold leading-[24px] text-center py-2 px-4 border border-solid border-primary-main bg-transparent"
            onClick={() => navigate("/schedule")}
          >
            Schedule Another Interview
          </button>
          <button
            className="flex items-center justify-center gap-2 w-[258px] max-w-full rounded-[40px] text-white font-inter text-[16px] font-semibold leading-[24px] text-center py-2 px-4 bg-primary-main"
            onClick={goToLinkedinActivities}
          >
            <img src={LinkedinIcon} alt="linkedin" />
            Go to My Posts
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;
