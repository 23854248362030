import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRecurring } from "store/preparationSlice";
import { getWeekNumber, RECURRING_VALUES } from "Utils";

const RecurringSelector = () => {
  const dispatch = useDispatch();
  const { recurring, year, month, date, topics } = useSelector(
    (state) => state.preparation
  );
  useEffect(() => {
    dispatch(setRecurring(RECURRING_VALUES.NOT_REPEAT));
  }, [dispatch]);
  const handleChangeTime = (e) => {
    dispatch(setRecurring(e.target.value));
  };
  const options = useMemo(() => {
    const weekday = new Date(year, month, date, 0, 0, 0, 0).getDay();
    const weekStrings = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const getNumberSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      const lastDigit = day % 10;
      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const weekNumber = getWeekNumber(year, month, date);
    const weekNumberString =
      getWeekNumber(year, month, date) > 4
        ? "last"
        : weekNumber + getNumberSuffix(weekNumber);
    return [
      { label: "Do not repeat", value: RECURRING_VALUES.NOT_REPEAT },
      { label: "Weekly", value: RECURRING_VALUES.WEEKLY },
      {
        label: `Monthly on the ${weekNumberString} ${weekStrings[weekday]}`,
        value: RECURRING_VALUES.MONTHLY,
      },
    ];
  }, [year, month, date]);
  return (
    <Select
      labelId="recurring-label"
      id="recurring-select"
      value={recurring}
      onChange={handleChangeTime}
      sx={{ borderRadius: "30px" }}
      fullWidth
    >
      {options.map((option, index) => (
        <MenuItem
          key={index}
          value={option.value}
          sx={{ width: "100%" }}
          disabled={topics.length < 2 && index > 0}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default RecurringSelector;
