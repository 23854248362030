import React, { useEffect, useRef } from "react";
import Picker from "emoji-picker-react";

const EmojiPicker = ({ onEmojiClick }) => {
  const pickerRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const closePicker = (e) => {
      if (pickerRef.current && !pickerRef.current.contains(e.target))
        onEmojiClick({ emoji: "" });
    };
    const container = containerRef.current
    container?.addEventListener("click", closePicker);
    return () => {
        container?.removeEventListener("click", closePicker);
    };
  }, [onEmojiClick]);

  return (
    <div className="fixed short:absolute sm:absolute left-0 short:left-0 sm:left-full bottom-0 w-full p-4 short:p-4 sm:p-0 top-0 sm:top-auto short:top-0 z-10" ref={containerRef}>
      <div className="h-full w-full" ref={pickerRef}>
        <Picker
          onEmojiClick={onEmojiClick}
          style={{ minHeight: "100%", minWidth: "100%" }}
        />
      </div>
    </div>
  );
};

export default EmojiPicker;
