import React from "react";
import RadioChecked from "assets/images/personalize/RadioButtonChecked.svg";
import RadioUnchecked from "assets/images/personalize/RadioButtonUnchecked.svg";
import defaultAvatar from "assets/images/default-user.png";
import Linkedin from "assets/images/personalize/linkedin.svg";
import { useDispatch } from "react-redux";
import { setPersonalizationSource } from "store/personalizeSlice";
import { PERSONALIZATION_SOURCE } from "Utils";
import useUserInfo from "hooks/useUserInfo";

const MyPosts = ({ selected = false }) => {
  const dispatch = useDispatch();
  const { profile } = useUserInfo();
  const { officialFirstName, officialLastName, headline, picture } = profile;
  const onClick = () => {
    dispatch(setPersonalizationSource(PERSONALIZATION_SOURCE.myPosts));
  };
  return (
    <div
      className={`link w-[410px] h-[240px] max-w-full border-[10px] bg-off-white-light rounded-[40px] text-black flex flex-col justify-between items-center px-4 sm:px-9 py-4 sm:py-5 overflow-hidden ${
        selected ? "border-blue500" : "border-black-bg"
      }`}
      onClick={onClick}
    >
      <div className="w-full flex justify-between items-center">
        <div className="flex gap-1 sm:gap-3 items-center">
          <img
            src={selected ? RadioChecked : RadioUnchecked}
            alt={selected ? "checked" : "unchecked"}
            className="w-6 h-6"
          />
          <span className="text-[24px] sm:text-[30px] font-[700] tracking-[-0.3px] leading-[133%]">
            My posts
          </span>
        </div>
        <img src={Linkedin} alt="linkedin" className="w-10 h-10" />
      </div>
      <div className="w-full flex items-center gap-2 sm:gap-4">
        <div className="avatar rounded-full border-[2.3px] w-[50px] h-[50px] border-blue500 shrink-0" style={{backgroundImage: `url(${picture || defaultAvatar})`}} />
        <div className="flex flex-col gap-2 font-figtree text-[14px] sm:text-[16px] leading-[125%]">
          <span className="font-[600] capitalize overflow-clip text-clip max-w-full max-h-[18px] sm:max-h-5">{`${officialFirstName} ${officialLastName}`}</span>
          <span className="max-h-[35px] sm:max-h-10 overflow-hidden">
            {headline}
          </span>
        </div>
      </div>
      <div className="font-inter text-[14px] font-[500] leading-[128%]">
        Choose this to base your tone of voice on your top-performing posts
      </div>
    </div>
  );
};

export default MyPosts;
