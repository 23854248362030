import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import congAnimation from "assets/animations/congrats-checkout.json";
import "./style.css"

const CheckoutCongrats = () => {
  return (
    <div className="w-full flex items-center justify-center fixed flex-1 h-full top-0 left-0">
      <div className="relative w-[100000px] h-full top-0 left-0 flex items-center justify-center checkout-congrats">
        <Player loop autoplay src={congAnimation} className="conf"></Player>
        <Player loop autoplay src={congAnimation} className="conf"></Player>
        <Player loop autoplay src={congAnimation} className="conf"></Player>
        <Player loop autoplay src={congAnimation} className="conf"></Player>
        <Player loop autoplay src={congAnimation} className="conf"></Player>
      </div>
    </div>
  );
};

export default CheckoutCongrats;
