import React from "react";
import Room from "./Room";
import { useSelector } from "react-redux";
import { INTERVIEW_STATUS } from "Utils";
import Transcription from "./Transcription";
import InterviewTips from "./InterviewTips";
import useUserInfo from "hooks/useUserInfo";
import useInterview from "hooks/useInterview";

const MainBox = () => {
  const { topic, status } = useSelector((state) => state.interview);
  const { firstName, headline } = useUserInfo().profile;
  const { guestMode } = useInterview();
  const selectedHeadline = guestMode?.guest?.headline || headline;
  const cutHeadline =
    selectedHeadline.length > 30
      ? selectedHeadline.slice(0, 30) + "..."
      : selectedHeadline;
  return (
    <div
      className={`main${status === INTERVIEW_STATUS.DONE ? " finished" : ""}`}
    >
      <div className="names capitalize">
        {guestMode?.assistant?.name || "Jessica Que"} &lt;&gt;{" "}
        {guestMode?.guest?.firstName || firstName} | {cutHeadline}
      </div>
      <div className="topic">
        Topic: {topic.length > 100 ? topic.slice(0, 100) + "..." : topic}
      </div>
      <div className="conversation">
        <InterviewTips />
        <Room />
        <Transcription />
      </div>
    </div>
  );
};

export default MainBox;
