import React, { useEffect, useRef, useState } from "react";
import volumeIcon from "assets/images/dashboard/Volume.svg";
import { createTheme, Slider, ThemeProvider } from "@mui/material";

const VolumeInput = ({volume, setVolume}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const sliderRef = useRef(null);

  const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            color: "#707070",
            height: 2,
          },
          thumb: {
            height: 8,
            width: 8,
            backgroundColor: "#707070",
            border: "none",
            "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
              filter:
                "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.20))",
            },
            "&::before": {
              display: "none",
            },
          },
          valueLabel: {
            display: "none",
          },
          track: {
            height: 2,
            borderRadius: 2,
          },
          rail: {
            height: "100%",
            borderRadius: 2,
            width: 2,
            opacity: 0.5
          },
        },
      },
    },
  });

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  const handleSliderChange = (_, newValue) => {
    setVolume(newValue / 100); // Converting back to percentage
  };

  return (
    <div className="relative size-[42px] flex items-center justify-center  max-400:flex-1" ref={ref}>
      <button onClick={() => setOpen((prev) => !prev)}>
        <img src={volumeIcon} alt="volume" />
      </button>
      {open && (
        <div className="absolute bottom-full left-1/2 -translate-x-1/2 translate-y-3">
          <ThemeProvider theme={theme}>
            <Slider
              sx={{
                '& input[type="range"]': {
                  writingMode: "vertical-lr",
                  direction: "rtl",
                },
                height: "60px",
              }}
              orientation="vertical"
              size="small"
              value={volume * 100}
              onChange={handleSliderChange}
              max={100}
              onKeyDown={preventHorizontalKeyboardNavigation}
              valueLabelDisplay="off"
              ref={sliderRef}
            />
          </ThemeProvider>
        </div>
      )}
    </div>
  );
};

export default VolumeInput;
