import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuth, setProfile, setRegisterEmail } from "store/mainSlice";
import { AUTH_STEP, INTERCOM_EVENTS, showErrorMessage } from "Utils";
import { BASE_SERVER_URL, SERVER_URL } from "api";
import axios from "axios";
import { useIntercom } from "react-use-intercom";
import { useNavigate } from "react-router-dom";
import useCookie from "hooks/useCookie";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const intercom = useIntercom();
  const navigate = useNavigate();
  const { setCookieEmail } = useCookie();
  const [error, setError] = useState({});
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [wait, setWait] = useState(false);
  const [agree, setAgree] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agree) {
      showErrorMessage(
        "You must agree to the terms and conditions in order to register."
      );
      return;
    }
    if (firstName.trim() === "") {
      showErrorMessage("First name is required");
      setError({ firstName: "First name is required" });
      return;
    }
    // if (title === "") {
    //   showErrorMessage("Title is required");
    //   setError({ title: "Title is required" });
    //   return;
    // }
    if (email.trim() === "") {
      showErrorMessage("Email is required");
      setError({ email: "Email is required" });
      return;
    }
    if (!isValidEmail(email.trim())) {
      showErrorMessage("Invalid email format");
      setError({ email: "Invalid email format" });
      return;
    }
    if (linkedin.trim() === "") {
      showErrorMessage("LinkedIn URL is required");
      setError({ linkedin: "LinkedIn is required" });
      return;
    }

    let cutLinkedin = linkedin
      .split("?")[0]
      ?.trim()
      .replace(/^\/+|\/+$/g, "")
      .replace(/\s/g, "")
      .split("/")
      .pop();

    if (!cutLinkedin) {
      showErrorMessage("LinkedIn URL is invalid");
      setError({ linkedin: "LinkedIn is invalid" });
      return;
    }

    setWait(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/services/linkedin/${cutLinkedin}`
      );

      const {
        officialFirstName,
        officialLastName,
        company,
        picture,
        headline,
      } = response.data;

      dispatch(
        setProfile({
          firstName: firstName.trim(),
          officialFirstName,
          officialLastName,
          company,
          picture,
          email: email.trim(),
          linkedin: cutLinkedin,
          headline,
          // title
        })
      );
      dispatch(setRegisterEmail(email));
      intercom.trackEvent(INTERCOM_EVENTS.submitted_details, {
        firstName,
        email,
        linkedin: cutLinkedin,
      });
      //   const result = await fetchLinkedinProfile(linkedin);
      //   if (!result) return;
      try {
        const signedUpResponse = await axios.post(
          `${BASE_SERVER_URL}/api/v2/users/assistant/check`,
          { email }
        );
        if (signedUpResponse.data.ok) {
          dispatch(setAuth(AUTH_STEP.sign_in));
          setWait(false);
          return;
        }
      } catch (error) {
        console.log(error);
      }
      setCookieEmail(email);
      navigate("/schedule");
      // rest of the validation and submission logic here
    } catch (error) {
      console.error(error);
      if (error.response?.status === 404) {
        showErrorMessage("LinkedIn URL is invalid");
        setError({ linkedin: "LinkedIn is invalid" });
        const response = await axios.post(
          `${SERVER_URL}/services/getUserHash`,
          { email }
        );
        const userHash = response.data;
        intercom.update({
          email,
          name: firstName,
          userHash,
          customAttributes: {
            app: "Jessica",
          },
        });
        setTimeout(() => {
          intercom.trackEvent(INTERCOM_EVENTS.invalidLinkedin, {
            firstName,
            email,
          });
        }, 5 * 1000);
      } else
        showErrorMessage(
          error.response?.data?.message || "Server Error, please try again."
        );
      // handle the error, display a message to the user, etc.
    }

    setWait(false);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <form className="register-card" onSubmit={handleSubmit}>
      <h3 className="title">Let's book your call</h3>
      <div className="input-group">
        <input
          type="text"
          name="first-name"
          id="first-name"
          placeholder="Enter your first name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className={error.firstName ? "error" : ""}
        />
        {/* <input
          type="text"
          name="title"
          id="title"
          placeholder="Job Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={error.title ? "error" : ""}
        /> */}
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={error.email ? "error" : ""}
        />
        <div className="w-full flex flex-col items-start gap-2">
          <input
            type="text"
            name="linkedin"
            id="linkedin"
            placeholder="Linkedin profile URL"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
            className={(error.linkedin ? "error " : "") + "w-full"}
          />
          <span className="text-new-gray font-figtree text-[12px] leading-[150%] sm:ml-5">
            {"Example:  https://www.linkedin.com/in/{your_profile_id}"}
          </span>
        </div>
      </div>
      <div className="actions-container">
        <button type="submit" className="submit link interact" disabled={wait}>
          {wait ? "Wait..." : "Find A Time For Your Call"}
        </button>
        <div className="terms-container gap-2 items-center">
          <input
            type="checkbox"
            name="agree-terms"
            id="agree-terms"
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
            className="flex-shrink-0"
          />
          <span>
            I read and agreed to{" "}
            <a
              className="link term-link"
              href="https://q-ueue.ai/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions.
            </a>{" "}
          </span>
        </div>
      </div>

      <div className="account">
        Already have an account?{" "}
        <span
          className="sign-in link"
          onClick={() => dispatch(setAuth(AUTH_STEP.sign_in))}
        >
          Sign in
        </span>
      </div>
    </form>
  );
};

export default RegisterForm;
