import React, { useEffect /*, useRef, useState */, useRef } from "react";
import "./LoadingPopUp.css";
import Logo from "assets/images/post/jessica-loading.png";
// import { useCallback } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "./Animation.json";
import BG1 from "assets/images/post/loading-bg (1).png";
import BG2 from "assets/images/post/loading-bg (2).png";
import useUserInfo from "hooks/useUserInfo";
import UserCards from "components/UserCards/UserCards";
import Diamond from "assets/images/post/Diamond.svg";
import { useNavigate, useParams } from "react-router-dom";

const LoadingPopUp = ({ setShowPopup, fetched }) => {
  const navigate = useNavigate();
  const { profile } = useUserInfo();
  const { interviewId: iId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const skip = urlParams.has("skip");

  useEffect(() => {
    if (fetched && timerRef.current === 0) {
      setShowPopup(false);
    }
  }, [fetched, setShowPopup]);

  const fetchedRef = useRef(false);
  const timerRef = useRef(0);

  useEffect(() => {
    if (fetched) fetchedRef.current = true;
  }, [fetched]);

  useEffect(() => {
    const next = () => {
      if (fetchedRef.current) {
        setShowPopup(false);
      }
      timerRef.current = 0;
    };
    if (!skip) timerRef.current = setInterval(next, 5000);
    return () => clearInterval(timerRef.current);
  }, [setShowPopup, skip]);

  const goToPersonalization = () => {
    navigate(`/personalize?interviewId=${iId}`);
  };

  return (
    <div className="post-loading">
      <div className="loading-popup">
        <div className="popup-title">You did awesome!</div>
        <div className="popup-head">
          Hold on for 2 minutes while I create all the content for you (Don’t
          close this tab)
        </div>
        <div className="popup-animation">
          <Player src={Animation} autoplay loop />
        </div>
        {profile.personalized && (
          <div className="popup-description">
            Publish your content to establish authority and{" "}
            <strong>attract more clients & opportunities.</strong>
          </div>
        )}
        <div className="popup-card relative">
          <img src={BG2} alt="bg1" className="absolute top-0 left-0 h-1/2" />
          <img
            src={BG1}
            alt="bg1"
            className="absolute bottom-0 right-0 h-1/2"
          />
          {profile.personalized || skip ? (
            <>
              <div className="flex gap-2 items-center justify-center w-full z-10">
                <img
                  src={Logo}
                  alt="queue"
                  className="flex-shrink-0 w-16 md:w-20"
                />
                <div className="font-figtree text-[24px] md:text-[30px] lg:text-[36px] leading-[103%] text-center">
                  Continue Creating with{" "}
                  <span className="font-eighties-it">Jessica</span>
                </div>
              </div>
              <div className="popup-content z-10">
                Natural interviews help create a wealth of authentic content
                that resonates with your audience, showcasing your expertise and
                personal journey.
              </div>
              <a
                className="z-10 link text-white font-figtree text-[15px] font-[700] leading-[26px] tracking-[0.46px] bg-primary-main rounded-[40px] px-[22px] py-2"
                style={{
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                  color: "white",
                }}
                href="/schedule"
                target="_blank"
                rel="noreferrer"
              >
                Schedule Your Next Call
              </a>
              <div className="popup-helper z-10">
                Don’t worry it will open in a new tab
              </div>
            </>
          ) : (
            <>
              <div className="font-figtree text-[20px] lg:text-[24px] 2xl:text-[28px] font-[400] leading-[103%] text-center z-10">
                What to improve your posts? Personalize your content!{" "}
              </div>
              <div className="w-full flex gap-1 z-10 flex-col md:flex-row">
                <div className="flex-col items-center md:w-[55%] xl:w-[45%] flex">
                  <img
                    src={Diamond}
                    alt="diamond"
                    className="-mb-[40px] w-12 h-12 lg:w-16 lg:h-16 z-10"
                  />
                  <UserCards size="sm" />
                </div>
                <div className="flex-1 pl-3 flex flex-col items-center md:items-start">
                  <div className="font-figtree text-[14px] lg:text-[18px] xl:text-[20px] leading-[140%] pb-3 md:py-6">
                    <strong>
                      Take a minute to show us how you would like your posts to
                      look and sound.
                    </strong>{" "}
                    Select your favorite posts from you or other thought leaders
                    on LinkedIn.
                  </div>
                  <button
                    className="max-w-full px-4 py-2 rounded-full border-primary-900 bg-cta-blue text-white font-inter text-[16px] font-[600] leading-[150%] w-[200px]"
                    style={{
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      color: "white",
                    }}
                    onClick={goToPersonalization}
                  >
                    Personalize
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadingPopUp;
