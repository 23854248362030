import React from "react";
import PlusCircleIcon from "assets/images/preparation/plus-circle.svg";
import "./AITopicCard.css";
import { capitalize } from "Utils";
import useUserInfo from "hooks/useUserInfo";

const AITopicCard = ({ text, isTrending, isNew, tag, onSelect = () => {} }) => {
  const { profile } = useUserInfo();
  return (
    <div className="relative flex gap-3 py-3 px-4 bg-off-white-base rounded-[20px] items-center flex-nowrap ai-card-body">
      <div
        className="flex-1 whitespace-nowrap overflow-clip font-figtree text-[14px] leading-[142.857%] w-[calc(100%-32px)] text-ellipsis ai-card-text"
        style={{ transition: "height 1s linear" }}
      >
        {text}
      </div>
      <img
        src={PlusCircleIcon}
        alt="select"
        className="w-6 h-6 flex-shrink-0 cursor-pointer"
        onClick={onSelect}
      />
      <div className="absolute top-0 -translate-y-1/2 left-0 flex items-center gap-2 justify-start w-full">
        {tag === "Topics" && (
          <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
            ✨Topic
          </div>
        )}
        {tag?.trim() === "Company Questions" && (
          <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
            {`🏢Question about ${capitalize(profile.company)}`}
          </div>
        )}
        {tag?.trim() === "Expertise Questions" && (
          <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
            {`Question about ${capitalize(profile.fullName)}`}
          </div>
        )}
        {isTrending && (
          <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
            🔥Trending
          </div>
        )}
        {isNew && (
          <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
            🌟New
          </div>
        )}
      </div>
    </div>
  );
};

export default AITopicCard;
