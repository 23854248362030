import { useSelector } from "react-redux";

const useCustomTheme = () => {
  const { isSlim } = useSelector((state) => state.main.theme);

  return {
    isSlim,
  };
};

export default useCustomTheme;
