import React, { useEffect, useRef, useState } from "react";
import AITopicCard from "./AITopicCard/AITopicCard";
import SearchIcon from "assets/images/preparation/search.svg";
import CheckIcon from "assets/images/preparation/check.svg";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, showErrorMessage } from "Utils";
import TrashIcon from "assets/images/preparation/trash-01.svg";
import useUserInfo from "hooks/useUserInfo";
import {
  PREPARATION_STEP,
  setIsSettingUpAudience,
  setIsSettingUpGuestMode,
  setTopics,
  setPreparationStep,
} from "store/preparationSlice";
import { SERVER_URL } from "api";
import axios from "axios";
import Loader from "components/Loader";
import PreparationStep from "./PreparationStep/PreparationStep";
import Button, { ButtonVariant } from "components/Button";
import Info from "assets/images/preparation/info.svg"

const TopicSelector = ({ wait, createSchedule }) => {
  const dispatch = useDispatch();
  const { profile, isCheckingAudience } = useUserInfo();
  const [keywords, setKeywords] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [questions1, setQuestions1] = useState([]);
  const [questions2, setQuestions2] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [needMenu, setNeedMenu] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [showAll, setShowAll] = useState(false);
  const topicMenuRef = useRef(null);
  const inputRef = useRef(null);
  const timerRef = useRef(0);
  const { topics: mainTopics, guestMode } = useSelector(
    (state) => state.preparation
  );
  const { audience } = useUserInfo().profile;
  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
    setNeedMenu(true);
  };

  const questions = showAll
    ? [...questions1, ...questions2, ...questions3]
    : questions2.length === 0
    ? [...questions1.slice(0, 6)]
    : [
        ...questions1.slice(0, 2),
        ...questions2.slice(0, 2),
        ...questions3.slice(0, 2),
      ];
  const leftQuestions = questions.filter((_, index) => index % 2 === 0);
  const rightQuestions = questions.filter((_, index) => index % 2 === 1);

  const questionsCount =
    questions1.length + questions2.length + questions3.length;

  useEffect(() => {
    const fetchDefaultQuestions = async () => {
      if (isCheckingAudience) return;
      if (guestMode?.guest?.linkedin || profile.linkedin) {
        setQuestions1([]);
        setQuestions2([]);
        setQuestions3([]);
        setShowAll(false);
        const response = await axios.get(
          audience
            ? `${SERVER_URL}/services/topics?email=${encodeURIComponent(
                profile.email
              )}`
            : `${SERVER_URL}/services/topics?email=${encodeURIComponent(
                profile.email
              )}&linkedin=${encodeURIComponent(guestMode?.guest?.linkedin || profile.linkedin)}`
        );
        let questions = [];
        if (response.data.Topics) {
          questions = [
            ...response.data.Topics.map((obj) => ({
              text: obj.Description,
              isNew: false,
              tag: "Topics",
            })),
          ];
          setQuestions1(questions);
        }
        if (response.data["Company Questions"]) {
          questions = [
            ...response.data["Company Questions"].map((obj) => ({
              text: obj.Question,
              isNew: false,
              tag: "Company Questions",
            })),
          ];
          setQuestions2(questions);
        }
        if (response.data["Expertise Questions"]) {
          questions = [
            ...response.data["Expertise Questions"].map((obj) => ({
              text: obj.Question,
              isNew: false,
              tag: "Expertise Questions",
            })),
          ];
          setQuestions3(questions);
        }
      } else {
        const response = await axios.get(
          `${SERVER_URL}/services/topics/${profile.headline}`
        );
        setQuestions1(response.data);
      }
    };
    fetchDefaultQuestions();
  }, [
    profile.headline,
    audience,
    profile.email,
    isCheckingAudience,
    profile.linkedin,
    guestMode,
  ]);

  const resetMenuSize = () => {
    if (
      !inputRef?.current ||
      document.getElementsByTagName("main").length === 0
    )
      return;
    const inputRect = inputRef.current.getBoundingClientRect();
    const mainRect = document
      .getElementsByTagName("main")[0]
      .getBoundingClientRect();
    setMenuHeight(
      mainRect.top + mainRect.height - inputRect.top - inputRect.height - 21
    );
  };
  const closeMenu = (e) => {
    if (topicMenuRef.current && !topicMenuRef.current.contains(e.target)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    resetMenuSize();
    window.addEventListener("resize", resetMenuSize);
    window.addEventListener("scroll", resetMenuSize);
    window.addEventListener("click", closeMenu);
    const pop = document.getElementsByClassName("pop");
    if (pop.length > 0) pop[0].addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("resize", resetMenuSize);
      window.removeEventListener("scroll", resetMenuSize);
      window.removeEventListener("click", closeMenu);
      if (pop.length > 0) pop[0].removeEventListener("click", closeMenu);
    };
  }, [keyword, openMenu]);

  useEffect(() => {
    return () => setOpenMenu(false);
  }, []);

  useEffect(() => {
    if (keywords.length > 0 && needMenu) setOpenMenu(true);
    else setOpenMenu(false);
  }, [needMenu, keywords]);

  useEffect(() => {
    const fetchTopics = async (searchString) => {
      if (searchString.trim() === "") {
        setKeywords([]);
        setTimeout(() => {
          setKeywords([]);
        }, 200);
        return;
      }
      try {
        const response = await axios.get(
          `${SERVER_URL}/services/topics/${searchString}`
        );
        const responseTopics = response.data;
        if (!responseTopics) {
          setKeywords([
            {
              text: searchString,
            },
          ]);
          return;
        }
        const sameTopicIndex = responseTopics.findIndex(
          (t) => t.text.trim() === searchString.trim()
        );
        if (sameTopicIndex < 0)
          setKeywords([
            {
              text: searchString,
            },
            ...responseTopics,
          ]);
        else setKeywords(responseTopics);
      } catch (error) {
        console.log(error);
        showErrorMessage(
          error.response?.data?.message || "Internal server error!"
        );
      }
    };
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = 0;
    }
    timerRef.current = setTimeout(() => {
      fetchTopics(keyword);
    }, 500);
  }, [dispatch, keyword]);

  const openAudiencePopup = () => {
    dispatch(setIsSettingUpAudience(true));
  };

  const openGuestModePopup = () => {
    dispatch(setIsSettingUpGuestMode(true));
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      if (!selectedTopic) {
        setQuestions1([]);
        setQuestions2([]);
        setQuestions3([]);
        return;
      }
      if (!selectedTopic.link) {
        setQuestions1([
          {
            text: selectedTopic.text,
            isNew: false,
            trends: false,
          },
        ]);
        setShowAll(false);
        return;
      }
      try {
        setQuestions1([]);
        setQuestions2([]);
        setQuestions3([]);
        setShowAll(false);
        const response = await axios.get(`${SERVER_URL}/services/questions`, {
          params: {
            topicLink: selectedTopic.link,
          },
        });
        const responseQuestions = response.data;
        if (!Array.isArray(responseQuestions)) {
          setQuestions1([]);
          setQuestions2([]);
          setQuestions3([]);
          setShowAll(false);
          return;
        }
        setQuestions1(responseQuestions);
      } catch (error) {
        console.log(error);
        showErrorMessage(
          error.response?.data?.message || "Internal server error!"
        );
      }
    };
    fetchQuestions();
  }, [selectedTopic]);

  return (
    <div className="w-full flex justify-center items-start max-w-full  gap-5">
      <div
        className={`flex justify-center flex-col flex-1 max-w-[min(100%,900px)] gap-6 ${
          mainTopics.length > 0 ? "mb-[90vw] md:mb-0 topic-select-box" : ""
        }`}
      >
        <div className="text-black-bg font-figtree text-[24px] leading-[87.5%]">
          Let's set up your topic
        </div>
        <PreparationStep />
        <div className="p-4 lg:p-8 rounded-[30px] bg-off-white-super-light flex flex-col gap-7">
          <div className="w-full flex justify-between items-center">
            <div className="text-black-bg font-figtree font-[500] text-[16px] leading-[256.25%] text-start">
              What topic would you like discuss?
            </div>
            <div className="preparation-audience-after-desktop flex items-center gap-4">
              {guestMode && (
                <div
                  className="link interact items-center gap-2 text-cta-blue font-figtree font-[600] leading-[41px] tracking-[0.15px] py-1 px-4 rounded-full border border-off-white-dark  hidden sm:flex "
                  onClick={openGuestModePopup}
                >
                  <img src={CheckIcon} alt="check" />
                  <div>🏢</div>
                  <span className="flex-shrink-0 text-nowrap">Guest Mode</span>
                </div>
              )}
              {audience && (
                <div
                  className="link interact items-center gap-2 text-cta-blue font-figtree font-[600] leading-[41px] tracking-[0.15px] py-1 px-4 rounded-full border border-off-white-dark  hidden sm:flex "
                  onClick={openAudiencePopup}
                >
                  <img src={CheckIcon} alt="check" />
                  <div>🎯</div>
                  <span className="flex-shrink-0 text-nowrap">
                    Your audience
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full gap-9">
            <div className="w-full relative">
              <input
                type="text"
                placeholder="Type Topic"
                value={keyword}
                onChange={handleKeywordChange}
                ref={inputRef}
                className="bg-transparent w-full pr-11 py-4 pl-4 outline-none border border-other-outlined-border rounded-full"
              />
              <div
                className="max-h-0 relative w-full overflow-visible"
                ref={topicMenuRef}
              >
                {openMenu && keywords.length > 0 && (
                  <div
                    style={{
                      maxHeight: `${menuHeight}px`,
                    }}
                    className="absolute z-10 w-full overflow-y-auto overflow-x-hidden rounded-[30px] border border-gray-400"
                  >
                    <div className="bg-white w-full py-2">
                      {keywords.map((topic, index) => (
                        <div
                          key={index}
                          className={`link py-2 px-4 font-figtree text-[16px] leading-[150%] tracking-[0.15px] hover:bg-gray-200 ${
                            /*mainTopics.findIndex(t => t.topic === topic.text) > -1 ? "text-text-secondary" : */ "text-text-primary"
                          }`}
                          onClick={
                            topic.link
                              ? () => {
                                  setKeyword(topic.text);
                                  setSelectedTopic(topic);
                                  setNeedMenu(false);
                                }
                              : () => {
                                  dispatch(
                                    setTopics([
                                      ...mainTopics,
                                      {
                                        topic: topic.text,
                                        isCustom: true,
                                      },
                                    ])
                                  );
                                  setKeyword(topic.text);
                                  setNeedMenu(false);
                                }
                          }
                        >
                          {topic.link ? (
                            topic.text
                          ) : (
                            <>
                              <span className="link text-primary-main">
                                Create new :
                              </span>{" "}
                              {topic.text}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <img
                src={SearchIcon}
                alt="search"
                className="absolute right-3 top-1/2 -translate-y-1/2 w-6 h-6"
              />
              <div className="absolute top-0 left-4 -translate-y-1/2 text-text-secondary bg-off-white-super-light">
                Topic
              </div>
            </div>
            {!!audience || (
              <div className="gap-2 font-figtree tracking-[0.15px] py-4 px-4 rounded-[30px] border border-off-white-dark w-full items-start preparation-audience-before-desktop hidden sm:flex">
                <div>🎯</div>
                <div>
                  <span
                    className="text-cta-blue font-[600] link interact"
                    onClick={openAudiencePopup}
                  >
                    Set up your audience
                  </span>{" "}
                  <span>
                    for better AI topic suggestions tailored to your needs
                  </span>
                </div>
              </div>
            )}
            <div className="w-full gap-7 flex flex-col">
              <div>
                <div className="text-black-bg font-figtree text-[20px] font-[500] leading-[205%]">
                  {/* <Player src={Fire} autoplay loop/> */}
                  🔥 AI Suggested Topics:
                </div>
                <div className="text-black-bg font-figtree leading-[157%]">
                  Measured by: views, reactions, and number of comments{" "}
                </div>
              </div>
              {questions.length > 0 ? (
                <div className="w-full flex flex-col gap-4">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 ai-topics-grid">
                    <div className="w-full flex flex-col gap-8">
                      {leftQuestions.map((question, index) => (
                        <AITopicCard
                          key={index}
                          isNew={question.isNew}
                          isTrending={selectedTopic && index < 3}
                          text={question.text}
                          tag={question.tag}
                          onSelect={() => {
                            dispatch(
                              setTopics([
                                ...mainTopics,
                                {
                                  topic: question.text,
                                  isTrending: selectedTopic && index < 3,
                                  isNew: question.isNew,
                                  tag: question.tag,
                                  isAI: true,
                                },
                              ])
                            );
                          }}
                        />
                      ))}
                    </div>
                    <div className="w-full flex flex-col gap-8">
                      {rightQuestions.map((question, index) => (
                        <AITopicCard
                          key={index}
                          isNew={question.isNew}
                          isTrending={selectedTopic && index < 3}
                          text={question.text}
                          tag={question.tag}
                          onSelect={() => {
                            dispatch(
                              setTopics([
                                ...mainTopics,
                                {
                                  topic: question.text,
                                  isTrending: selectedTopic && index < 3,
                                  isNew: question.isNew,
                                  tag: question.tag,
                                  isAI: true,
                                },
                              ])
                            );
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  {questionsCount > 6 && (
                    <div
                      className="link interact w-full flex justify-end text-primary-main font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px] p-1"
                      onClick={() => setShowAll((prev) => !prev)}
                    >
                      <span>{showAll ? "Show Less " : "Show More"}</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="w-full min-h-64 flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center preparation-audience-mobile sm:hidden">
          {!audience ? (
            <div className="gap-2 font-figtree tracking-[0.15px] py-4 px-4 rounded-[30px] border border-off-white-dark w-full items-start preparation-audience-before-mobile flex mt-5">
              <div>🎯</div>
              <div>
                <span
                  className="text-cta-blue font-[600] link interact"
                  onClick={openAudiencePopup}
                >
                  Set up your audience
                </span>{" "}
                <span>
                  for better AI topic suggestions tailored to your needs
                </span>
              </div>
            </div>
          ) : (
            <div
              className="link interact flex items-center gap-2 text-cta-blue font-figtree font-[600] leading-[41px] tracking-[0.15px] py-1 px-4 rounded-full border border-off-white-dark preparation-audience-after-mobile mt-5"
              onClick={openAudiencePopup}
            >
              <img src={CheckIcon} alt="check" />
              <div>🎯</div>
              <span className="flex-shrink-0 text-nowrap">Your audience</span>
            </div>
          )}
        </div>
        {!guestMode ? (
          <div className="w-full flex justify-center">
            <div className="gap-2 font-figtree tracking-[0.15px] py-4 px-4 rounded-[30px] border border-off-white-dark w-full items-start preparation-audience-before-mobile flex mt-5">
              <div>🏢</div>
              <div>
                <span
                  className="text-cta-blue font-[600] link interact"
                  onClick={openGuestModePopup}
                >
                  Agency mode
                </span>{" "}
                <span>Turn on agency mode and customize your interviews</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-center preparation-audience-mobile sm:hidden">
            <div
              className="link interact flex items-center gap-2 text-cta-blue font-figtree font-[600] leading-[41px] tracking-[0.15px] py-1 px-4 rounded-full border border-off-white-dark preparation-audience-after-mobile mt-5"
              onClick={openGuestModePopup}
            >
              <img src={CheckIcon} alt="check" />
              <div>🏢</div>
              <span className="flex-shrink-0 text-nowrap">Guest Mode</span>
            </div>
          </div>
        )}
      </div>
      {mainTopics.length > 0 && (
        <div className="selected-topic-card w-screen md:w-[330px] lg:w-[440px] pt-12 bottom-0 md:bottom-auto fixed md:sticky h-[60vh] md:h-full">
          <div className="w-full border border-off-white-dark rounded-t-[30px] md:rounded-b-[30px] bg-off-white-light pt-4 lg:pt-8 h-full flex flex-col">
            <div className="w-full px-6 flex-1">
              <div className="font-figtree text-black-bg text-[16px] font-[500] gap-4 flex flex-col h-full">
                <div className="py-3">Chosen Topic(s):</div>
                <div className="w-full relative flex-1">
                  <div className="w-full h-full absolute flex-1 space-y-8 overflow-auto scroll-dark top-0 left-0 py-4">
                    {mainTopics.map((t, index) => (
                      <div
                        className="px-4 py-3 relative flex items-center justify-between rounded-full bg-off-white-base"
                        key={index}
                      >
                        <div className="absolute top-0 left-3 flex flex-start items-center -translate-y-3 gap-1">
                          {/* {isAI && (
                    <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                      AI Suggestion
                    </div>
                  )} */}
                          {t.tag === "Topics" && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-light-blue  rounded-full">
                              ✨Topic
                            </div>
                          )}
                          {t.tag?.trim() === "Company Questions" && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                              {`🏢Question about ${capitalize(
                                profile.company
                              )}`}
                            </div>
                          )}
                          {t.tag?.trim() === "Expertise Questions" && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                              {`Question about ${capitalize(profile.fullName)}`}
                            </div>
                          )}
                          {t.isTrending && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#FFEB92]  rounded-full">
                              🔥Trending
                            </div>
                          )}
                          {t.isNew && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-[#B8FBC5]  rounded-full">
                              🌟New
                            </div>
                          )}
                          {t.isCustom && (
                            <div className="px-1.5 font-roboto text-[13px] leading-[138%] text-text-primary bg-purple-lavender  rounded-full">
                              Custom
                            </div>
                          )}
                        </div>
                        <div className="text-ellipsis overflow-x-clip text-nowrap w-[calc(100%-32px)]">
                          {t.topic}
                        </div>
                        <img
                          src={TrashIcon}
                          alt="delete"
                          className="w-6 h-6 flex-shrink-0 interact link"
                          onClick={() =>
                            dispatch(
                              setTopics(
                                mainTopics.filter((_, i) => i !== index)
                              )
                            )
                          }
                        />
                      </div>
                    ))}
                    <div className="w-full flex items-center justify-start text-text-secondary font-figtree leading-5 gap-2 text-[14px]">
                      <img src={Info} alt="info" className="flex-shrink-0 size-[18px]" />
                      <span>You can add as much Topics as you want. </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col items-center gap-2 border-t border-t-off-white-dark p-4">
              {/* <button
                className={`interact w-[388px] max-w-full rounded-[40px] border ${
                  wait
                    ? " bg-action-disabled-background text-action-disabled"
                    : "border-primary-main bg-primary-main text-white"
                } py-2 px-5 font-figtree text-[15px] font-[700] leading-[26px] tracking-[0.46px]`}
                disabled={wait}
                onClick={() => createSchedule()}
              >
                {wait ? "Wait..." : "Start Your Call Now"}
              </button> */}
              <Button
                variant={ButtonVariant.FILL}
                className={`w-[388px] rounded-[40px] max-w-full py-2 px-5 font-figtree text-[15px] font-[700] leading-[26px] tracking-[0.46px]`}
                onClick={() =>
                  dispatch(setPreparationStep(PREPARATION_STEP.DATE_SELECTION))
                }
                disabled={wait}
                text="Select Time and Date"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopicSelector;
