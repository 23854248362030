import React, { useEffect, useMemo } from "react";
import ArrowBack from "assets/images/personalize/ArrowBack.svg";
import SourceSelection from "./components/SourceSelection";
import { useDispatch, useSelector } from "react-redux";
import {
  PAGE_TYPES,
  PERSONALIZATION_SOURCE,
  PERSONALIZATION_STEP,
} from "Utils";
import PostsSelection from "./components/PostsSelection";
import PostsInput from "./components/PostsInput";
import { useNavigate } from "react-router-dom";
import {
  setPersonalizationSource,
  setPersonalizationStep,
} from "store/personalizeSlice";
import { setPageOn } from "store/mainSlice";
import useUserInfo from "hooks/useUserInfo";
import bg from "assets/images/personalize/bg.png";
import { setInterviewId } from "store/interviewSlice";
import useInterview from "hooks/useInterview";

const Personalization = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { step, source } = useSelector((state) => state.personalize);
  const { isChecking } = useUserInfo();
  const { interviewId } = useInterview();

  useEffect(() => {}, [dispatch]);

  const iId = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("interviewId");
  }, []);

  useEffect(() => {
    if( interviewId !== iId) setInterviewId(iId)
  }, [dispatch, iId, interviewId]);

  const skip = () => {
    if (interviewId) {
      navigate(`/posts/${interviewId}?skip`);
    } else {
      navigate(`/schedule`);
    }
  };

  const goBack = () => {
    if (step === PERSONALIZATION_STEP.selectingSource) skip();
    if (step === PERSONALIZATION_STEP.selectingPosts)
      dispatch(setPersonalizationStep(PERSONALIZATION_STEP.selectingSource));
  };

  useEffect(() => {
    dispatch(setPageOn(PAGE_TYPES.PERSONALIZATION));
    dispatch(setPersonalizationStep(PERSONALIZATION_STEP.selectingSource));
    dispatch(setPersonalizationSource(PERSONALIZATION_SOURCE.myPosts));
  }, [dispatch]);

  if (isChecking) {
    return (
      <div className="absolute bg-black-bg w-full min-h-full flex items-center justify-center text-white">
        <span className="text-[36px] text-center">Loading...</span>
      </div>
    );
  }

  return (
    <div
      className="absolute bg-black-bg w-full min-h-full text-white overflow-auto flex flex-col bg-right-top md:bg-center bg-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="flex justify-between items-center  px-4 py-2 md:py-4">
        <div
          className="flex gap-2 font-figtree text-[14px] font-[700] tracking-[0.4px] items-center text-blue500 link interact"
          onClick={goBack}
        >
          <img src={ArrowBack} alt="back" className="w-5 h-5" />
          <span className="inline sm:hidden">Back</span>
          <span className="hidden sm:inline">
            {step === PERSONALIZATION_STEP.selectingSource
              ? "Back to the end of interview"
              : "Back to choices"}
          </span>
        </div>
        <div
          className="border border-white rounded-full text-white px-5 py-2 font-figtree text-[15px] font-[700] tracking-[0.46px] link interact"
          onClick={skip}
        >
          <span className="hidden max-400:inline">Skip</span>
          <span className="inline max-400:hidden">Skip Personalization</span>
        </div>
      </div>
      <div className="md:py-2 px-6 lg:py-4 lg:px-12 text-off-white-super-light font-figtree text-[20px] md:text-[26px] lg:text-[32px]">
        Personalize Your Content
      </div>
      {step === PERSONALIZATION_STEP.selectingSource && <SourceSelection />}
      {step === PERSONALIZATION_STEP.selectingPosts &&
        (source === PERSONALIZATION_SOURCE.myPosts ? (
          <PostsSelection />
        ) : (
          <PostsInput />
        ))}
    </div>
  );
};

export default Personalization;
