import React from "react";

const TapCard = ({
  icon,
  text,
  isSelected = false,
  onClick = () => {},
  disabled,
}) => {
  return (
    <button
      className={`p-4 flex items-center gap-2 rounded-[20px] border  flex-shrink-0 no-select border-light-blue`}
      style={{
        background: isSelected
          ? "linear-gradient(180deg, #FEFFFF 0%, #D8DDF8 100%)"
          : "",
      }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <img src={icon} alt="icon" className="w-6 h-6 flex-shrink-0 no-select" />
      <span className="font-figtree text-black leading-[21px] text-[16px]">{text}</span>
    </button>
  );
};

export default TapCard;
