import Avatar from "components/Avatar";
import Button, { ButtonVariant } from "components/Button";
import React, { useMemo } from "react";
import PinkJessica from "assets/images/my-calls/jessica-pink.png";
import { getCurrentDateTime, RECURRING_VALUES } from "Utils";
import EditIcon from "assets/images/my-calls/edit-02.svg";
import TrashIcon from "assets/images/my-calls/trash-01.svg";

const MyCallCard = ({ schedule }) => {
  console.log(schedule);

  const topic = useMemo(() => {
    const additionalTopics =
      schedule.interviews?.length > 1
        ? `+ ${schedule.interviews?.length - 1} more topics`
        : "";

    return `${schedule.topic} ${additionalTopics}`;
  }, [schedule.interviews?.length, schedule.topic]);

  const timeString = useMemo(() => {
    const { year, month, date, hour, min, timezone } = schedule.schedule;
    let startDate = new Date(year, month, date, hour, min);

    if (hour < 0) {
      const today = getCurrentDateTime(timezone);
      startDate = new Date(
        today.year,
        today.month,
        today.day,
        today.hour,
        today.minute
      );
    }

    const formatDate = (date) => {
      const options = { month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);
      const suffix = getNumberSuffix(date.getDate());
      return `${formattedDate}${suffix}, ${year}`;
    };

    const getNumberSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }

      const lastDigit = day % 10;

      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedStartDate = `${startDate.getHours()}:${String(
      startDate.getMinutes()
    ).padStart(2, "0")}`;

    const result = `${formattedStartDate}, ${formatDate(startDate)}`;
    return result;
  }, [schedule.schedule]);

  return (
    <div className="flex flex-nowrap flex-col lg:flex-row w-full justify-between gap-4 border rounded-[30px] sm:rounded-[30px] border-off-white-dark p-2 sm:p-4">
      <div className="w-full md:w-auto flex justify-between gap-2 items-start">
        <div className="flex items-end flex-shrink-0">
          <Avatar
            src={
              schedule.guestMode?.guest?.firstName
                ? schedule.guestMode?.guest?.picture
                : schedule.picture
            }
            alt="user"
            className="size-[60px] flex-shrink-0 z-[1] bg-off-white-light"
          />
          <Avatar
            src={schedule.guestMode?.assistant?.picture}
            defaultUser={PinkJessica}
            alt="jessica"
            className="-ml-4 w-12 h-auto flex-shrink-0"
          />
        </div>
        <div className="flex-1 space-y-1">
          <div className="font-figtree text-[18px] md:text-[24px] leading-[131%] text-black-bg">
            {topic}
          </div>
          <div className="font-figtree font-[600] text-[16px] md:text-[20px] leading-[130%]">
            {timeString}
          </div>
        </div>
      </div>
      <div className="w-full md:w-auto flex items-center justify-between gap-4 flex-shrink-0">
        <div
          className={`py-2 px-2.5 rounded-[16px] text-text-primary font-roboto text-[13px] leading-[18px] tracking-[0.16px] text-nowrap ${
            schedule.recurring === RECURRING_VALUES.MONTHLY
              ? "bg-orange-mishmish"
              : schedule.recurring === "weekly"
              ? "bg-light-blue"
              : "bg-yellow-summer"
          }`}
        >{`${
          schedule.recurring === RECURRING_VALUES.MONTHLY
            ? "Monthly"
            : schedule.recurring === "weekly"
            ? "Weekly"
            : "Single"
        } Call`}</div>
        <div className="flex items-center gap-4">
          <Button
            variant={ButtonVariant.OUTLINE}
            icon={EditIcon}
            text="Edit"
            className="gap-2 py-2 px-5 flex items-center font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] flex-shrink-0"
            iconClassName="size-6"
          />
          <Button
            variant={ButtonVariant.GHOST}
            icon={TrashIcon}
            className="flex-shrink-0"
            iconClassName="size-6 flex-shrink-0"
          />
        </div>
      </div>
    </div>
  );
};

export default MyCallCard;
