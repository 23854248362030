import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Card from "components/Card";
import RegisterForm from "./components/RegisterForm";
import Welcome from "./components/Welcome";
import useUserInfo from "hooks/useUserInfo";
import AnimationCard from "./components/AnimationCard";
import CarousalCard from "./components/CarousalCard";
import AudioFile from "assets/audios/register/Voice-over-signup.mp3";
import { useNavigate } from "react-router-dom";
import { initProfile } from "store/mainSlice";
import { useDispatch } from "react-redux";

const Register = () => {
  const dispatch = useDispatch()
  const { isAuth } = useUserInfo();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(AudioFile));

  const handleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const endAudio = () => {
      setIsPlaying(false);
    };
    const audio = audioRef.current;
    audio.addEventListener("ended", endAudio);
    return () => {
      audio.removeEventListener("ended", endAudio);
    };
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if(isAuth){
      navigate(`/schedule`)
    }
  }, [isAuth, navigate])

  useEffect(() => {
    if(!isAuth){
      dispatch(initProfile())
    }
  }, [dispatch, isAuth])

  
  useEffect(() => {
    const audio = audioRef.current;
    return () => {
      if (!audio.paused) {
        audio.pause();
      }
    };
  }, []);

  return (
    <div className="register">
      <div className="main">
        <div className="w-full md:hidden text-center font-eighties text-[32px] font-[600] leading-[130%]">
          Your journey to create a content starts here
        </div>
        <AnimationCard
          className="md:hidden h-[50vw] max-h-[240px] min-h-[160px]"
          handleAudio={handleAudio}
          isPlaying={isPlaying}
        />
        {isAuth ? (
          <Welcome />
        ) : (
          <Card>
            <RegisterForm />
          </Card>
        )}
        <div className="h-full flex flex-col gap-4 lg:gap-6">
          <AnimationCard
            className="hidden md:flex flex-1"
            handleAudio={handleAudio}
            isPlaying={isPlaying}
          />
          <CarousalCard />
        </div>
      </div>
    </div>
  );
};

export default Register;
