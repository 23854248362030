import React, { useMemo } from "react";
import TimeSelector from "./TimeSelector";
import Calendar from "./Calendar";
import TimeZoneSelector from "./TimeZoneSelector";
import RecurringSelector from "./RecurringSelector";
import TopicSorter from "./TopicSorter/TopicSorter";
import PreparationStep from "./PreparationStep/PreparationStep";
import { useSelector } from "react-redux";
import { getWeekNumber, RECURRING_VALUES } from "Utils";

const DateSelector = ({ waitStart, waitAdd, createSchedule }) => {
  const { year, month, date, recurring, hour, min } = useSelector(
    (state) => state.preparation
  );
  const wait = waitStart || waitAdd;
  const dateTitle = useMemo(() => {
    function getWeekDayString(date) {
      const weekDays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = date.getDay();
      return weekDays[dayIndex];
    }
    const formatDate = (date) => {
      const options = { weekday: "long", month: "long", day: "numeric" };
      const formattedDate = date.toLocaleDateString("en-US", options);

      const suffix = getNumberSuffix(date.getDate());

      return `${formattedDate}${suffix}, ${year}`;
    };
    const getNumberSuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return "th";
      }

      const lastDigit = day % 10;

      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const timeString =
      (hour > 9 ? "" : "0") + hour + ":" + (min > 9 ? "" : "0") + min;
    let weekNumber = 0;
    switch (recurring) {
      case RECURRING_VALUES.MONTHLY:
        weekNumber = getWeekNumber(year, month, date);
        return `${
          weekNumber > 4 ? "Last" : weekNumber + getNumberSuffix(weekNumber)
        } ${getWeekDayString(
          new Date(year, month, date, 0, 0, 0, 0)
        )} at ${timeString}`;

      case RECURRING_VALUES.WEEKLY:
        weekNumber = getWeekNumber(year, month, date);
        return `Every ${getWeekDayString(
          new Date(year, month, date, 0, 0, 0, 0)
        )} at ${timeString}`;

      case RECURRING_VALUES.NOT_REPEAT:
        weekNumber = getWeekNumber(year, month, date);
        return `${formatDate(
          new Date(year, month, date, 0, 0, 0, 0)
        )} at ${timeString}`;

      default:
        return "";
    }
  }, [date, hour, min, month, recurring, year]);

  return (
    <div className="flex-1 w-full flex justify-center items-center max-w-full">
      <div className="w-full max-w-[min(100%,1200px)] flex flex-col gap-4 min-h-full">
        <div className="text-black-bg font-figtree text-[24px] leading-[87.5%]">
          Let's set up your call
        </div>
        <PreparationStep />
        <div className="flex flex-1 gap-5 w-full items-stretch">
          <div className="p-4 lg:p-8 rounded-[30px] bg-off-white-super-light flex flex-col gap-7 flex-1 w-full md:w-[calc(100%-350px)] lg:w-[calc(100%-460px)]">
            <div className="w-full flex flex-col justify-between items-start">
              <div className="text-black-bg font-figtree font-[500] text-[16px] leading-[225%] text-start gap-4 flex flex-col items-start">
                <span>{dateTitle}</span>
                <span>Chosen Topics:</span>
              </div>
              <TopicSorter />
            </div>
          </div>
          <div className="selected-topic-card w-screen md:w-[330px] lg:w-[440px] bottom-0 md:bottom-auto fixed md:sticky h-[60vh] md:h-auto flex-shrink-0">
            <div className="w-full border border-off-white-dark rounded-t-[30px] md:rounded-b-[30px] bg-off-white-light h-full flex flex-col">
              <div className="flex-1 w-full px-4">
                <div className="w-full h-full relative">
                  <div className="w-full h-full absolute flex-1 space-y-3 overflow-auto scroll-dark top-0 left-0 py-4">
                    <RecurringSelector />
                    <div className="text-black-bg font-figtree font-[500] text-[16px] text-start py-4">
                      Choose time and date or start the call now
                    </div>
                    <TimeSelector />
                    <Calendar />
                    <TimeZoneSelector />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-center gap-2 p-4 border-t border-t-off-white-dark">
                <button
                  className={`interact w-[388px] max-w-full rounded-[40px] border ${
                    wait
                      ? " bg-action-disabled-background text-action-disabled"
                      : "border-primary-main bg-primary-main text-white"
                  } py-2 px-5 font-figtree text-[15px] font-[700] leading-[26px] tracking-[0.46px]`}
                  disabled={wait}
                  onClick={() => createSchedule(true)}
                >
                  {waitAdd ? "Wait..." : "Add to Calendar"}
                </button>
                <button
                  className={`interact w-[388px] rounded-[40px] max-w-full interact border-none ${
                    wait ? "text-action-disabled" : "text-primary-main"
                  }  py-2 px-5 font-figtree text-[15px] font-[700] leading-[26px] tracking-[0.46px]`}
                  onClick={() => createSchedule(false)}
                  disabled={wait}
                >
                  {waitStart ? "Wait..." : "Start Now"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateSelector;
