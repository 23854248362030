import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Cancel,
  CheckCircleRounded,
  KeyboardVoice,
  Pause,
  PlayArrow,
  PlayArrowRounded,
  RepeatRounded,
} from "@mui/icons-material";
import EndCall from "assets/images/meeting/end.png";
import Clock from "assets/images/meeting/time.png";
import InfoIcon from "assets/images/info-icon.svg";
import AudioProgress from "./AudioProgress";
import axios from "axios";
import { SERVER_URL } from "api";
import {
  // INTERCOM_EVENTS,
  INTERVIEW_STATE,
  INTERVIEW_STATUS,
  showErrorMessage,
} from "Utils";
import {
  setAudio,
  setForceFinished,
  setInterviewState,
  setPlayingAudio,
  setProgress,
} from "store/meetingSlice";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import { useIntercom } from "react-use-intercom";
import Tooltip from "components/Tooltip/Tooltip";
import { setInterviews, setRegisterEmail } from "store/mainSlice";
import useUserInfo from "hooks/useUserInfo";
import { setFetchedPosts } from "store/postSlice";
import { setInterviewStatus } from "store/interviewSlice";

const Controller = ({
  // audioContext,
  // setAudioContext,
  startSpeaking,
  stopSpeaking,
  sendAnswer,
  reAnswer,
}) => {
  const dispatch = useDispatch();
  const speaking = false;
  const redColor = "#FF5C5C";
  const {
    interviewState,
    isPlayingAudio,
    progress,
    messages,
    duration,
    countTime,
    // topic,
  } = useSelector((state) => state.meeting);
  const { status, interviewId } = useSelector((state) => state.interview);
  const [interviewTime, setInterviewTime] = useState(0);
  const [helperRequired, setHelperRequired] = useState(true);
  const { email /* firstName */ } = useUserInfo().profile;
  const minutes = Math.floor(interviewTime / 60000);
  const seconds = Math.floor((interviewTime / 1000) % 60);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const timeString = `${formattedMinutes}:${formattedSeconds}`;

  useEffect(() => {
    const interval = setInterval(() => {
      setInterviewTime((prevTime) => prevTime + 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (messages.length > 1) setHelperRequired(false);
  }, [messages.length]);

  useEffect(() => {
    setInterviewTime(countTime * 1000);
  }, [countTime]);

  const repeatLastQ = () => {
    // if (!audioContext) setAudioContext();
    dispatch(setAudio(`${messages[messages.length - 1].record}`));
    if (isPlayingAudio) dispatch(setPlayingAudio(false));
    else dispatch(setPlayingAudio(true));
  };

  const handleSpeakingClick = async () => {
    if (helperRequired) setHelperRequired(false);
    if (interviewState === 0) {
      const result = await startSpeaking();
      if (!result) return;
      dispatch(setPlayingAudio(false));
      dispatch(setInterviewState(1));
      dispatch(setProgress(0));
    } else if (interviewState === 1 || interviewState === 2) {
      stopSpeaking();
    }
  };

  const handleSendAnswer = async () => {
    if (interviewState === 3) sendAnswer();
  };

  const handleReview = async () => {
    if (interviewState !== 3) return;
    // if (!audioContext) setAudioContext();
    dispatch(setPlayingAudio(!isPlayingAudio));
  };

  // const intercom = useIntercom();
  const navigate = useNavigate();

  const endCall = async () => {
    if (messages.length < 4) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Your answers are not enough to end the interview",
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
      return;
    }
    const { isConfirmed } = await Swal.fire({
      icon: "question",
      title: "Question",
      html: "Would you like to end the interview now?",
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
    if (!isConfirmed) return;
    try {
      await axios.post(
        `${SERVER_URL}/interview-sessions/${interviewId}/finish`
      );
      // intercom.trackEvent(INTERCOM_EVENTS.finishInterview, {
      //   topic,
      //   interview_length: interviewTime / 1000,
      //   number_of_posts: 5,
      //   number_of_articles: 2,
      //   number_of_questions: Math.ceil(messages.length / 2),
      //   firstName,
      // });
      const response = await axios.post(`${SERVER_URL}/interview-sessions/`, {
        email,
      });
      dispatch(setInterviews(response.data.items));
      dispatch(setInterviewStatus(INTERVIEW_STATUS.DONE));
      dispatch(setInterviewState(INTERVIEW_STATE.INTERVIEWER_SPEAKING));
      dispatch(setRegisterEmail(email));
      dispatch(setForceFinished(true));
      dispatch(setFetchedPosts(false));
    } catch (error) {
      console.log(error);
      showErrorMessage(error.response?.data?.message);
    }
  };

  const pauseSpeaking = async () => {
    if (interviewState === 1) {
      dispatch(setInterviewState(2));
    } else if (interviewState === 2) dispatch(setInterviewState(1));
  };

  const goToPost = () => {
    navigate(`/posts/${interviewId}`);
  };

  function formatTime(seconds) {
    if (!Number.isFinite(seconds)) {
      return "0:00";
    }
    seconds = seconds - (seconds % 1);
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min}:${sec < 10 ? "0" : ""}${sec}`;
  }

  return (
    <div className="control-bar">
      <div className="controls-container speak">
        {status === INTERVIEW_STATUS.OPENED && (
          <>
            <div className="control disabled">
              <Pause
                className="control-icon pause"
                style={{ fill: "#8A8A8A", opacity: "20%" }}
              />
              <div className="control-text" style={{ opacity: "20%" }}>
                Pause
              </div>
            </div>
            <div className="control disabled">
              <KeyboardVoice
                style={{
                  fill: "#8A8A8A",
                  opacity: "20%",
                }}
                className="control-icon speak"
              />
              <div style={{ opacity: "20%" }} className="control-text">
                Speak
              </div>
            </div>
          </>
        )}
        {status === INTERVIEW_STATUS.INTERVIEWING &&
          (interviewState === INTERVIEW_STATE.BEFORE_START ? (
            <div
              className="link interact max-w-full w-[413px] text-center rounded-[40px] bg-primary-main px-10 py-2 text-color font-figtree text-[15px] font-[700] leading-[170%] tracking-[0.46px]"
              style={{
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}
              onClick={() => {
                dispatch(
                  setInterviewState(INTERVIEW_STATE.INTERVIEWER_SPEAKING)
                );
                repeatLastQ();
              }}
            >
              Continue Interview
            </div>
          ) : (
            <>
              {interviewState === INTERVIEW_STATE.REVIEWING ||
                interviewState === INTERVIEW_STATE.SENDING || (
                  <div className="button mobile">
                    <img src={Clock} alt="time" className="icon clock-icon" />
                    <span>{timeString}</span>
                  </div>
                )}
              {interviewState === INTERVIEW_STATE.INTERVIEWER_SPEAKING && (
                <div className="control" onClick={repeatLastQ}>
                  <RepeatRounded
                    className="control-icon repeat"
                    style={{ fill: isPlayingAudio ? redColor : "#8A8A8A" }}
                  />
                  <div className="control-text">Repeat Last Q</div>
                </div>
              )}
              {interviewState === INTERVIEW_STATE.RECORDING && (
                <div className="control" onClick={pauseSpeaking}>
                  <Pause
                    className="control-icon pause"
                    style={{ fill: "#8A8A8A" }}
                  />
                  <div className="control-text">Pause</div>
                </div>
              )}
              {interviewState === INTERVIEW_STATE.PAUSED_RECORDING && (
                <div className="control" onClick={pauseSpeaking}>
                  <PlayArrowRounded
                    className="control-icon pause"
                    style={{ fill: redColor }}
                    fontSize="small"
                  />
                  <div className="control-text">Continue</div>
                </div>
              )}
              {interviewState === INTERVIEW_STATE.REVIEWING ||
                interviewState === INTERVIEW_STATE.SENDING || (
                  <div
                    className="control"
                    onClick={handleSpeakingClick}
                    style={{
                      cursor: interviewState === 2 ? "not-allowed" : "pointer",
                    }}
                  >
                    <KeyboardVoice
                      style={{
                        fill: interviewState === 1 ? redColor : "#8A8A8A",
                        opacity: interviewState === 2 ? "20%" : "100%",
                      }}
                      className="control-icon speak"
                    />
                    <div
                      style={{
                        fill: speaking ? "#FF5C5C" : "#8A8A8A",
                        opacity: interviewState === 2 ? "20%" : "100%",
                      }}
                      className="control-text"
                    >
                      Speak
                    </div>
                    {helperRequired && (
                      <div
                        className="helper"
                        onClick={(e) => e.stopPropagation()}
                      >
                        Click on the speak button when you want to start
                        answering
                      </div>
                    )}
                  </div>
                )}
              {(interviewState === INTERVIEW_STATE.REVIEWING ||
                interviewState === INTERVIEW_STATE.SENDING) && (
                <>
                  {interviewState === INTERVIEW_STATE.REVIEWING && (
                    <>
                      <div className="control" onClick={reAnswer}>
                        <Cancel className="control-icon speak" />
                        <div className="control-text">Start over</div>
                      </div>
                      <div className="audio-panel">
                        <div className="audio-control" onClick={handleReview}>
                          {!isPlayingAudio ? (
                            <PlayArrowRounded className="control-icon" />
                          ) : (
                            <Pause className="control-icon" />
                          )}
                        </div>
                        <div className="audio-status">
                          <AudioProgress progress={progress} />
                          <div className="show-time">
                            <div>{formatTime((progress / 100) * duration)}</div>
                            <div>{formatTime(duration)}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="control" onClick={handleSendAnswer}>
                    {interviewState === INTERVIEW_STATE.SENDING ? (
                      <CircularProgress color="secondary" size={28} />
                    ) : (
                      <CheckCircleRounded className="control-icon speak" />
                    )}
                    <div className="control-text">Send</div>
                  </div>
                </>
              )}
              {interviewState === INTERVIEW_STATE.REVIEWING ||
                interviewState === INTERVIEW_STATE.SENDING || (
                  <div className="control mobile" onClick={endCall}>
                    <img src={EndCall} alt="end" className="icon" />
                    <div className="control-text">Close</div>
                  </div>
                )}
            </>
          ))}
        {status === INTERVIEW_STATUS.DONE && (
          <div className="control" onClick={goToPost}>
            <PlayArrow className="control-icon repeat" />
            <div className="control-text">Go to post</div>
          </div>
        )}
      </div>
      {status === INTERVIEW_STATUS.INTERVIEWING &&
        interviewState !== INTERVIEW_STATE.BEFORE_START && (
          <>
            <div className="controls-end special">
              <div className="button">
                <img src={Clock} alt="time" className="icon clock-icon" />
                <span>{timeString}</span>
              </div>
            </div>
            <div className="controls-end desktop">
              <div className="button">
                <img src={Clock} alt="time" className="icon clock-icon" />
                <span>{timeString}</span>
              </div>
              <div className="button" onClick={endCall}>
                <img src={EndCall} alt="end" className="icon end-call-icon" />
                <span>End call</span>
                <Tooltip text="Clicking end call will end the interview.">
                  <img src={InfoIcon} alt="info" />
                </Tooltip>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default Controller;
