import Button, { ButtonVariant } from "components/Button";
import React, { useRef } from "react";
import CameraIcon from "assets/images/podcast/Camera Icon.svg";
import DefaultUser from "assets/images/default-user.png";

const PodcastImage = ({ image = "", setImage = () => { }, imageRef, extensionRef }) => {
    const inputRef = useRef(null);

    const handleImageSelect = (e) => {
        const file = e.target.files[0];

        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split(".").pop();
            if (fileExtension) {
                extensionRef.current = fileExtension;
            }
            const reader = new FileReader();

            reader.onload = () => {
                const blob = new Blob([reader.result], { type: file.type });
                imageRef.current = file;
                const blobUrl = URL.createObjectURL(blob);
                console.log(blobUrl);

                setImage(blobUrl);
                // Use the 'blob' here (e.g., upload to a server, display in the UI, etc.)
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const startSelectingImage = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return <div className="w-full flex justify-center">
        <div className="relative">
            <div className="w-[197px] max-w-full aspect-square avatar overflow-hidden rounded-full" style={{ backgroundImage: `url(${image || DefaultUser}` }}>
            </div>
            <Button variant={ButtonVariant.OUTLINE} className="border-off-white-dark bg-off-white-super-light rounded-full overflow-hidden p-2 absolute -translate-x-1/2 -translate-y-1/2 top-[15%] left-[15%]" icon={CameraIcon} iconClassName="w-[18px]" onClick={startSelectingImage} />
        </div>
        <input
            type="file"
            accept="image/*"
            onChange={handleImageSelect}
            className="hidden"
            ref={inputRef}
        />
    </div>
}

export default PodcastImage;
