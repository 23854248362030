import React, { useEffect } from "react";
import CloseIcon from "assets/images/checkout-failed/close.svg";
import graph from "assets/images/billing/graph.svg";
import UserAvatar from "assets/images/default-user.png";
import useUserInfo from "hooks/useUserInfo";
import useRedirect from "hooks/useRedirect";
import { useDispatch } from "react-redux";
import { setCheckoutCongrats } from "store/mainSlice";
import CheckoutCongrats from "components/CheckoutCongrats/CheckoutCongrats";
import { useNavigate } from "react-router-dom";

const CheckoutSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirectOrNavigate } = useRedirect();
  const { interviews, profile } = useUserInfo();
  const skip = () => {
    let nextUrl = "/schedule";
    if (interviews.length > 0) {
      const lastInterview = interviews[interviews.length - 1];
      nextUrl = `/posts/${lastInterview._id}`;
    }
    redirectOrNavigate(nextUrl);
  };
  const schedule = () => {
    navigate("/schedule");
  };

  useEffect(() => {
    dispatch(setCheckoutCongrats(true));
    return () => {
      dispatch(setCheckoutCongrats(false));
    };
  }, [dispatch]);

  return (
    <div className="absolute w-full min-h-full p-4 pb-8 flex items-center">
      <div className="w-full min-h-full flex items-center justify-center gap-8">
        <CheckoutCongrats />
        <div
          className="flex w-[753px] max-w-full p-6 items-center gap-6 rounded-[30px] bg-off-white-light flex-col relative"
          style={{
            boxShadow:
              "box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
          }}
        >
          <div className="flex justify-center items-center absolute p-2.5 right-4 top-4 cursor-pointer">
            <img
              src={CloseIcon}
              alt="close"
              onClick={skip}
              className="w-6 h-6"
            />
          </div>
          <div className="text-black self-stretch font-figtree text-[26px] sm:text-[36px] font-semibold leading-[30px] sm:leading-[45px] tracking-[-0.72px] text-center mt-6 sm:mt-0 capitalize">
            Congratulations, {profile.firstName}!
          </div>
          <div className="relative h-[40vw] sm:h-[240px] w-full">
            <img
              src={graph}
              alt="graph"
              className="absolute h-full w-auto left-1/2 top-0 translate-x-[-70%] md:translate-x-[-84%]"
            />
            <img
              src={profile.picture || UserAvatar}
              alt="user"
              className="absolute h-[70%] sm:h-[153px] w-auto left-1/2 bottom-[10%] sm:bottom-8 rounded-full border-off-white-dark border-solid border-[2vw] sm:border-[12px] translate-x-[25%] md:translate-x-0"
            />
          </div>
          <div className="h-full text-black font-figtree text-[16px] sm:text-[22px] font-normal leading-[130%]">
            New clients and opportunities are just a 5 minute call with Jessica
            away. Schedule weekly interviews with Jessica to stay top of mind
            with your audience!
          </div>
          <div className="w-full flex items-center gap-2 flex-row flex-wrap-reverse">
            <div
              className="w-full py-2.5 px-4 rounded-[40px] border-cta-blue text-cta-blue font-figtree text-[16px] font-semibold uppercase text-center border-[1px] sm:flex-[1] cursor-pointer"
              style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
              onClick={skip}
            >
              Skip
            </div>
            <div
              className="w-full py-2.5 px-4 rounded-[40px] bg-cta-blue text-white font-figtree text-[16px] font-semibold uppercase text-center sm:flex-[3] cursor-pointer"
              style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
              onClick={schedule}
            >
              Set up your weekly interview
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
