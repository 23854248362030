import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import RestartIcon from "assets/images/post-edit/refresh-ccw-04.svg";
import "../styles/EditButtons.css";
import Button, { ButtonVariant } from "components/Button";
import EditIcon from "assets/images/post-edit/edit-01.svg";
import LinkedinIcon from "assets/images/post-edit/linkedin-light.svg";
import { AUTH_STEP, INTERCOM_EVENTS, md2plain, showErrorMessage } from "Utils";
import SuccessPopup from "components/Popups/SuccessPopup/SuccessPopup";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { LINKEDIN_CLIENT_ID, SERVER_URL } from "api";
import useUserInfo from "hooks/useUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { useNavigate, useParams } from "react-router-dom";
import useInterview from "hooks/useInterview";
import axios from "axios";
import { setAuth, setProfile } from "store/mainSlice";

const EditButtons = ({
  savePost,
  isReview,
  setIsReview = () => {},
  text = "",
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intercom = useIntercom();
  const { interviewId: iId, postId: pId } = useParams();
  const { topic } = useInterview();
  const goToPreview = async () => {
    await savePost();
    setIsReview(true);
  };

  const {
    version,
    post: { platform },
  } = useSelector((state) => state.post);

  useEffect(() => {
    console.log(isReview);
  }, [isReview])

  const copyContent = () => {
    navigator.clipboard
      .writeText(md2plain(text)) // Copy the URL to the clipboard
      .then(() => {
        console.log("URL copied to clipboard: " + text);
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard: ", error);
      });
  };

  const [showPopup, setShowPopup] = useState(false);
  const [wait, setWait] = useState(false);
  const [run, setRun] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const linkedinCodeRef = useRef(null);
  const { profile } = useUserInfo();
  const options = useMemo(
    () => ({
      clientId: LINKEDIN_CLIENT_ID,
      redirectUri: `${window.location.origin}/linkedin`,
      scope: "openid profile w_member_social email",
      onSuccess: (code) => {
        linkedinCodeRef.current = code;
        setRun(true);
        setIsConnecting(false);
      },
      onError: (error) => {
        console.log(error);
        if (error.error !== "user_closed_popup") {
          showErrorMessage("Failed to connect your linkedin account");
        }
        setIsConnecting(false);
      },
    }),
    []
  );
  const { linkedInLogin } = useLinkedIn(options);
  const submit = () => {
    if (isConnecting) return;
    if (profile.linkedinConnected) {
      publishPost();
    } else {
      setIsConnecting(true);
      linkedInLogin();
    }
  };
  const publishPost = useCallback(async () => {
    if (wait) return;
    setRun(false);
    setWait(true);
    const body = {
      version: version - 1,
    };
    if (linkedinCodeRef.current) {
      body.code = linkedinCodeRef.current;
      linkedinCodeRef.current = null;
    }
    try {
      intercom.trackEvent(INTERCOM_EVENTS.clickOnPostIt, { topic });
      await axios.post(`${SERVER_URL}/posts/${iId}/${pId}/publish`, body);
      if (linkedinCodeRef.current) {
        dispatch(setProfile({ linkedinConnected: true }));
      }
      setShowPopup(true);
      intercom.trackEvent(INTERCOM_EVENTS.succeededPublish, {
        link: `https://www.linkedin.com/in/${profile.linkedin}/recent-activity/all/`,
        firstName: profile.firstName,
      });
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        dispatch(setAuth(AUTH_STEP.sign_in));
        navigate(`/posts/${iId}?skip`);
        return;
      }
      showErrorMessage(
        error.response?.data?.error || "Failed to publish your post"
      );
    }
    linkedinCodeRef.current = null;
    setWait(false);
  }, [
    dispatch,
    iId,
    intercom,
    navigate,
    pId,
    profile.firstName,
    profile.linkedin,
    topic,
    version,
    wait,
  ]);

  useEffect(() => {
    if (run) {
      publishPost();
    }
  }, [publishPost, run]);

  const isLinkedin = platform?.toLowerCase() === "linkedin";  

  return (
    <div className="editor-buttons">
      <div className="left-buttons">
        {/* {isReview || <div className="interact link font-figtree text-[14px] font-[700] leading-[170%] tracking-[0.4px] text-gray-500 flex gap-2 items-center">
          <img
            src={RestartIcon}
            alt="save draft"
            className="size-5 flex-shrink-0"
          />
          <span className="hidden sm:inline">Restart Chat</span>
          <span className="inline sm:hidden">Restart</span>
        </div>} */}
      </div>

      {/* <div className="buttons">
    
      <div className="button later">
        <img src={CalendarMark} alt="post later" />
        <div>POST LATER</div>
      </div>
    </div> */}
      <div className="right-buttons">
        {/* <div className="desktop">
          <span className="feedback">GIVE FEEDBACK</span>
          <img src={Unlike} alt="unlike" className="link" />
          <img src={Like} alt="like" className="link" />
        </div> */}
        {isReview ? (
          <>
            <Button
              text="Edit"
              icon={EditIcon}
              alt="edit"
              iconClassName="size-5"
              className="py-1.5 px-4 gap-2 text-[14px] font-[700] leading-[171%] tracking-[0.4px]"
              variant={ButtonVariant.OUTLINE}
              onClick={() => {
                setIsReview(false);
              }}
            />
            <Button
              text="Copy to clipboard"
              className="py-1.5 px-4 gap-2 text-[14px] font-[700] leading-[171%] tracking-[0.4px]"
              variant={isLinkedin ? ButtonVariant.OUTLINE : ButtonVariant.FILL}
              onClick={copyContent}
            />
            {isLinkedin && <Button
              text="Publish Now"
              icon={LinkedinIcon}
              alt="publish"
              iconClassName="size-5"
              className="py-1.5 px-4 gap-2 text-[14px] font-[700] leading-[171%] tracking-[0.4px]"
              variant={ButtonVariant.FILL}
              disabled={wait}
              onClick={submit}
            />}
          </>
        ) : (
          <>
            <Button
              text="Preview & Publish"
              // icon={LinkedinIcon}
              // alt="publish"
              // iconClassName="size-5"
              className="py-1.5 px-4 gap-2 font-figtree text-[14px] font-[700] leading-[171%] tracking-[0.4px]"
              variant={ButtonVariant.FILL}
              onClick={goToPreview}
            />
          </>
        )}
      </div>
      {showPopup && <SuccessPopup setOpenPopup={setShowPopup} />}
    </div>
  );
};

export default EditButtons;
