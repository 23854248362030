import { AUTH_STEP, showErrorMessage } from "Utils";
import { BASE_SERVER_URL } from "api";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setAuth } from "store/mainSlice";

const ResetForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);
  const inviteToken = urlParams.get("inviteToken");
  const [wait, setWait] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const submit = async (event) => {
    event.preventDefault();
    if (wait) return;
    if (password.length === 0) {
      showErrorMessage("Please enter your new password.");
      return false;
    }
    if (password2 !== password) {
      showErrorMessage(
        "Passwords do not match. Please re-enter the correct passwords."
      );
      return false;
    }
    setWait(true);
    try {
      await axios.post(`${BASE_SERVER_URL}/auth/reset-password`, {
        password,
        inviteToken,
      });
      setSubmitted(true);
    } catch (error) {
      console.log(error);
      if (error.response?.status === 302) {
        showErrorMessage(
          "New password must be different from the old password"
        );
        return;
      }
      showErrorMessage(
        error.response?.data?.msg?.split(":")[1] ||
          "Failed to reset your password, please try again"
      );
    }
    setWait(false);
  };

  const goToLogIn = () => {
    dispatch(setAuth(AUTH_STEP.sign_in));
  };

  return (
    <div className="auth-right">
      <div className="auth-title">
        {submitted ? "Password Changed Successfully!" : "Set your new password"}
      </div>
      <div className="auth-description">
        {submitted &&
          "Enter the email you used to create your account. We’ll send a link for you to reset your password. Please don’t share this link with anyone."}
      </div>
      <form onSubmit={submitted ? goToLogIn : submit}>
        {submitted || (
          <>
            <div className="auth-field">
              <input
                type="password"
                name="password2"
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <img src={HelpCircle} alt="help" className="auth-input-help" /> */}
            </div>
            <div className="auth-field">
              <input
                type="password"
                name="password"
                placeholder="Confirm New Password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
              {/* <img src={HelpCircle} alt="help" className="auth-input-help" /> */}
            </div>
          </>
        )}
        <button
          type="submit"
          className={"auth-submit" + (wait ? " wait" : "")}
          disabled={wait}
        >
          {submitted ? "Go to Log In" : "Reset Password"}
        </button>
        <div style={{ flex: 1 }}></div>
      </form>
    </div>
  );
};

export default ResetForm;
