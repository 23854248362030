import React, { useCallback, useEffect, useRef, useState } from "react";

const AudienceComplete = ({
  onKeywordChange,
  options,
  onSelect,
  label,
  placeholder,
}) => {
  const [value, setValue] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [needMenu, setNeedMenu] = useState(true);
  const menuRef = useRef(null);
  const inputRef = useRef(null);
  const timerRef = useRef(0);
  const handleKeywordChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      onKeywordChange(newValue);
      setNeedMenu(newValue.trim() !== "");
      timerRef.current = 0;
    }, 100);
  };
  const resetMenuSize = useCallback(() => {
    if (
      !inputRef?.current ||
      document.getElementsByTagName("main").length === 0
    )
      return;
    const inputRect = inputRef.current.getBoundingClientRect();
    const mainRect = document
      .getElementsByTagName("main")[0]
      .getBoundingClientRect();
    const popupRect = document
      .getElementById("audience-popup")
      .getBoundingClientRect();
    setMenuHeight(
      Math.max(
        mainRect.top + mainRect.height,
        popupRect.top + popupRect.height
      ) -
        inputRect.top -
        inputRect.height -
        4
    );
  }, []);
  const closeMenu = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    resetMenuSize();
    window.addEventListener("resize", resetMenuSize);
    window.addEventListener("scroll", resetMenuSize);
    window.addEventListener("click", closeMenu);
    const pop = document.getElementsByClassName("pop");
    if (pop.length > 0) pop[0].addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("resize", resetMenuSize);
      window.removeEventListener("scroll", resetMenuSize);
      window.removeEventListener("click", closeMenu);
      if (pop.length > 0) pop[0].removeEventListener("click", closeMenu);
    };
  }, [value, openMenu, resetMenuSize]);

  useEffect(() => {
    return () => setOpenMenu(false);
  }, []);

  useEffect(() => {
    if (options.length > 0 && needMenu) setOpenMenu(true);
    else setOpenMenu(false);
  }, [needMenu, options.length]);

  return (
    <div className="w-full relative">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleKeywordChange}
        ref={inputRef}
        className="bg-transparent w-full pr-11 py-4 pl-4 outline-none border border-other-outlined-border rounded-full"
      />
      <div className="max-h-0 relative w-full overflow-visible" ref={menuRef}>
        {openMenu && options.length > 0 && (
          <div
            style={{
              maxHeight: `${menuHeight}px`,
            }}
            className="absolute z-10 w-full overflow-y-auto overflow-x-hidden rounded-[30px] border border-gray-400"
          >
            <div className="bg-white w-full py-2">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="link py-2 px-4 text-text-primary font-figtree text-[16px] leading-[150%] tracking-[0.15px] hover:bg-gray-200"
                  onClick={() => {
                    onSelect(option);
                    setValue("");
                    setNeedMenu(false);
                  }}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="absolute top-0 left-4 -translate-y-1/2 text-text-secondary bg-off-white-light">
        {label}
      </div>
    </div>
  );
};

export default AudienceComplete;
