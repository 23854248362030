import React, { useEffect, useMemo, useRef, useState } from "react";
import PictureDarkIcon from "assets/images/image-manager/picture.svg";
import AIIcon from "assets/images/image-manager/Generate Image Icon.svg";
import PictureLightIcon from "assets/images/image-manager/Icon.svg";
import { IMAGE_MANAGER_TABS } from "Utils";
import TapCard from "./TapCard";
import { useDispatch, useSelector } from "react-redux";
import { setImageManagerTab } from "store/postSlice";

const controllerWidth = 584.05;

const TabHeader = () => {
  const dispatch = useDispatch();
  const [left, setLeft] = useState(28);
  const { imageManagerTab } = useSelector((state) => state.post);
  const dragStartRef = useRef(null);
  const containerRef = useRef(null);
  const controllerRef = useRef(null);
  const paddingRef = useRef(28);
  const items = useMemo(
    () => [
      {
        icon: PictureDarkIcon,
        text: "Upload Image",
        value: IMAGE_MANAGER_TABS.UPLOADER,
      },
      {
        icon: AIIcon,
        text: "Generate Image",
        value: IMAGE_MANAGER_TABS.GENERATOR,
      },
      {
        icon: PictureLightIcon,
        text: "Add Image Template",
        value: IMAGE_MANAGER_TABS.TEMPLATE,
      },
    ],
    []
  );

  useEffect(() => {
    const handleDrag = (e) => {
      const clientX = e.clientX || e.touches[0].clientX;
      if (dragStartRef.current === null) return;
      const padding = paddingRef.current;

      const containerWidth = containerRef.current.getBoundingClientRect().width;
      console.log(controllerWidth, containerWidth);
      setLeft((prev) => {
        if (2 * padding + controllerWidth < containerWidth) return padding;
        const newValue = prev + clientX - dragStartRef.current;
        if (newValue > padding) return padding;
        if (newValue + controllerWidth - containerWidth + padding < 0)
          return -controllerWidth + containerWidth - padding;
        dragStartRef.current = newValue - prev + dragStartRef.current;
        return newValue;
      });
    };
    const handleDown = (e) => {
      const clientX = e.clientX || e.touches[0].clientX;

      if (controllerRef.current && controllerRef.current.contains(e.target)) {
        dragStartRef.current = clientX;
      }
    };
    const handleUp = () => {
      dragStartRef.current = null;
    };
    const handleScroll = (e) => {
      const scrollAmount = e.deltaY / 5;
      const padding = paddingRef.current;
      const containerWidth = containerRef.current.getBoundingClientRect().width;

      setLeft((prev) => {
        if (2 * padding + controllerWidth < containerWidth) return padding;
        const newValue = prev - scrollAmount;
        console.log(prev, newValue, padding);
        if (newValue >= padding) return padding;
        if (newValue + controllerWidth - containerWidth + padding < 0)
          return -controllerWidth + containerWidth - padding;
        return newValue;
      });
    };
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("mousedown", handleDown);
    window.addEventListener("mouseup", handleUp);
    window.addEventListener("touchmove", handleDrag);
    window.addEventListener("touchstart", handleDown);
    window.addEventListener("touchend", handleUp);

    const container = containerRef.current;
    container.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("mousemove", handleDrag);
      window.removeEventListener("mousedown", handleDown);
      window.removeEventListener("mouseup", handleUp);
      window.removeEventListener("touchmove", handleDrag);
      window.removeEventListener("touchstart", handleDown);
      window.removeEventListener("touchend", handleUp);

      if (container) {
        container.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  const handleClick = (value) => {
    dispatch(setImageManagerTab(value));
  };

  return (
    <div
      className="w-full overflow-hidden hide-scrollbar relative h-[60px] flex-shrink-0"
      ref={containerRef}
    >
      <div
        className="absolute flex items-center gap-3"
        style={{ left: `${left}px` }}
        ref={controllerRef}
      >
        {items.map((item, index) => (
          <TapCard
            key={index}
            text={item.text}
            icon={item.icon}
            onClick={() => handleClick(item.value)}
            isSelected={item.value === imageManagerTab}
            disabled={item.disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default TabHeader;
