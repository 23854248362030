import React from "react";
import checkIcon from "assets/images/billing/checkIcon.png";
import awardIcon from "assets/images/billing/award-03.svg";
import closeIcon from "assets/images/billing/x-close.svg";
import useRedirect from "hooks/useRedirect";

const RightCard = ({ checkout, wait }) => {
  const { redirectOrNavigate } = useRedirect();

  return (
    <div className="w-[500px] 2xl:w-[753px] p-6 2xl:p-10 bg-off-white-light flex-col justify-start items-start gap-2 2xl:gap-6 inline-flex rounded-[30px] self-stretch lg:h-[660px] max-w-full relative">
      <div
        className="absolute right-4 top-4 cursor-pointer"
        onClick={() => redirectOrNavigate("/")}
      >
        <img src={closeIcon} alt="close" />
      </div>
      <div className="flex-col justify-start items-start gap-2 flex">
        <div className="text-black text-2xl font-figtree font-bold mt-3 2xl:mt-0">
          Subscribe now to continue your interview streak!
        </div>
        <div className="text-neutral-900 text-sm font-inter leading-tight">
          With a Jessica plan, you can continue accessing features that enable
          you to:
        </div>
      </div>
      <div className="2xl:p-3 rounded-[30px] justify-start items-start gap-2 inline-flex flex-col">
        <div className="justify-start items-start gap-3 inline-flex">
          <img src={checkIcon} alt="check" className="w-[30px] h-[30px]" />
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="self-stretch">
              <span className="text-neutral-900 text-sm font-inter leading-tight">
                <strong>Uncover trending topics</strong> – Get interviewed about
                the most relevant topics in your niche and create content that
                is sure to be a hit with your audience.{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="justify-start items-start gap-3 inline-flex">
          <img src={checkIcon} alt="check" className="w-[30px] h-[30px]" />
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="self-stretch">
              <span className="text-neutral-900 text-sm font-inter leading-tight">
                <strong>Hands-off content creation</strong> – No typing, just
                talking. A five minute voice interview is all you need to create
                authentic & insightful social media and blog posts.{" "}
              </span>
            </div>
          </div>
        </div>
        <div className="justify-start items-start gap-3 inline-flex">
          <img src={checkIcon} alt="check" className="w-[30px] h-[30px]" />
          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
            <div className="self-stretch">
              <span className="text-neutral-900 text-sm font-inter leading-tight">
                <strong>A week of content in 5 minutes</strong> – Jessica
                uncovers your insights in a way that feels natural & effortless.
                All you need is five easy minutes to create all your content for
                the week.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 justify-center items-start gap-2 md:gap-3 2xl:gap-6 flex flex-col bg-white w-full rounded-[30px] p-4 2xl:p-[24px]">
        <div className="flex-col justify-start items-start gap-0.5 md:gap-1 lg:gap-2 flex">
          <div className="text-black-bg text-2xl md:text-3xl lg:text-[3xl] 2xl:text-[39px] font-medium font-eighties italic leading-9">
            Get Unlimited Interviews
          </div>
          <div>
            <span className="text-neutral-900 text-2xl md:text-2xl 2xl:text-[28px] font-medium font-inter leading-9">
              $29
            </span>
            <span className="text-slate-700 text-xs font-medium font-inter leading-9">
              / month
            </span>
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-1.5 flex w-full">
          <div
            className="justify-start items-start px-4 py-2.5 bg-main-blue w-full rounded-[40px] shadow text-white text-lg 2xl:text-[22px] font-semibold font-figtree capitalize leading-tight text-center cursor-pointer"
            onClick={checkout}
          >
            Unlock full access to jessica now
          </div>
          <div className="flex-col justify-start items-start gap-1.5 flex">
            <div className="w-[311px] text-neutral-900 text-[13px] font-figtree leading-none">
              You’ll be charged monthly. Cancel anytime.
            </div>
            <div className="flex gap-2 items-center">
              <img
                className="w-[19px] h-[19px] left-0 top-0 flex-col justify-start items-start inline-flex"
                src={awardIcon}
                alt="award"
              ></img>
              <div className="left-[24px] top-0 text-neutral-900 text-xs font-inter leading-tight italic">
                30-day money-back guarantee
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightCard;
