import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Close from "assets/images/post-edit/x-close.svg";
import { setImageManagerTab } from "store/postSlice";
import { IMAGE_MANAGER_TABS } from "Utils";
import TabHeader from "./TabHeader/TabHeader";
import ImageUploader from "./ImageUploader";
import ImageTemplate from "./ImageTemplate";
import ImageGenerator from "./ImageGenerator";

const ImageManager = () => {
  const dispatch = useDispatch();
  const { imageManagerTab } = useSelector((state) => state.post);
  const closePopup = () => {
    dispatch(setImageManagerTab(IMAGE_MANAGER_TABS.NO_TAB));
  };
  return (
    <div className="fixed w-full h-full left-0 right-0 z-[100] overflow-auto flex items-center justify-center bg-[#0008]">
      <div className="max-h-full h-full overflow-auto md:p-4 w-full scroll-dark">
        <div className="relative left-1/2 -translate-x-1/2 bg-off-white-light md:rounded-[20px] xl:rounded-[30px] w-[1062px] max-w-full flex flex-col border border-solid border-gray-500 gap-4 min-h-full">
          <div className="flex flex-row justify-between border-b border-solid border-gray-300 items-center px-4 h-[50px] xl:px-5 xl:h-[70px] flex-shrink-0">
            <div
              className="p-[10px,10px,32px] text-black font-figtree text-[20px] font-[500] leading-[160%] tracking-[0.15px]"
              style={{
                fontFeatureSettings: " 'clig' off, 'liga' off",
                color: "black",
              }}
            >
              Add Image
            </div>
            <img src={Close} alt="close" onClick={closePopup} />
          </div>
          <TabHeader />
          {imageManagerTab === IMAGE_MANAGER_TABS.UPLOADER && <ImageUploader />}
          {imageManagerTab === IMAGE_MANAGER_TABS.GENERATOR && <ImageGenerator />}
          {imageManagerTab === IMAGE_MANAGER_TABS.TEMPLATE && <ImageTemplate />}
        </div>
      </div>
    </div>
  );
};

export default ImageManager;
