import React from "react";
import PreparationStepItem from "./PreparationStepItem";
import { useSelector } from "react-redux";
import { PREPARATION_STEP } from "store/preparationSlice";

const PreparationStep = () => {
  const { step } = useSelector((state) => state.preparation);
  return (
    <div className="w-full flex flex-col sm:flex-row items-start sm:items-center justify-start gap-2">
      <PreparationStepItem
        text="1. Choose Topics"
        active={step === PREPARATION_STEP.TOPIC_SELECTION}
      />
      <PreparationStepItem
        text="2. Choose time and define Topics order"
        active={step === PREPARATION_STEP.DATE_SELECTION}
      />
      <PreparationStepItem text="3. Talk with Jessica" />
    </div>
  );
};

export default PreparationStep;
