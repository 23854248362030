import { useLocation, useNavigate } from "react-router-dom";

const useRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // Access individual query parameters
  const redirect = queryParams.get("redirect");
  const navigateWithRedirect = (url) => {
    if (redirect) url += `?redirect=${redirect}`;
    navigate(url);
  };
  const navigateCreatingRedirect = (url, to = null) => {
    url += `?redirect=${to || location.pathname}`;
    navigate(url);
  };
  const redirectOrNavigate = (url) => {
    if (redirect) navigate(redirect);
    else navigate(url);
  };
  const redirectIfNeeded = () => {
    if (redirect) navigate(redirect);
  };
  return {
    redirectUrl: redirect,
    navigateWithRedirect,
    redirectOrNavigate,
    navigateCreatingRedirect,
    redirectIfNeeded,
  };
};

export default useRedirect;
