import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button";
import DownloadIcon from "assets/images/image-manager/download.svg";
import {
  setDefaultPrompt,
  setImageManagerTab,
  setImageVersion,
  setPost,
} from "store/postSlice";
import {
  downloadFile,
  IMAGE_MANAGER_TABS,
  showErrorMessage,
  // sleep,
} from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import useInterview from "hooks/useInterview";
import { useNavigate } from "react-router-dom";
import ImageStyleDropdown from "./ImageStyleDropdown";
import Loader from "components/Loader";
import ImageVersions from "./ImageVersions";
// import AiImage from "assets/images/image-manager/ai-image.png";

const ImageGenerator = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [style, setStyle] = useState(null);
  const { post, defaultPrompt, imageVersion } = useSelector(
    (state) => state.post
  );
  const { interviewId } = useInterview();
  const [generatingIdea, setGeneratingIdea] = useState(false);
  const [generatingImage, setGeneratingImage] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [text, setText] = useState("");

  const src = post?.aiImages?.[imageVersion];

  const closePopup = () => {
    dispatch(setImageManagerTab(IMAGE_MANAGER_TABS.NO_TAB));
  };

  const submit = async () => {
    if (submitting) return;
    setSubmitting(true);
    try {
      await axios.post(
        `${SERVER_URL}/posts/${interviewId}/${post?._id}/image`,
        {
          image: src,
        }
      );
      dispatch(
        setPost({
          ...post,
          images: [src],
        })
      );
      closePopup();
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        showErrorMessage("Your session is expired. Please log in again.");
        navigate(`/posts/${interviewId}?skip`);
      } else showErrorMessage("Failed to submit image. Please try again");
    }
    setSubmitting(false);
  };

  const download = () => {
    downloadFile(src);
  };

  const generateImage = async () => {
    if (generatingImage) return;
    if (post?.aiImages && post.aiImages.length >= 5) {
      showErrorMessage("You have reached the limit of 5 image generations.");
      return;
    }
    setGeneratingImage(true);
    try {
      const response = await axios.post(
        `${SERVER_URL}/posts/${interviewId}/${post?._id}/generate`,
        {
          prompt: text,
        }
      );
      const newImages = [...(post?.aiImages || []), response.data.image];
      dispatch(
        setPost({
          ...post,
          aiImages: newImages,
        })
      );
      dispatch(setImageVersion(newImages.length - 1));
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        showErrorMessage("Your session is expired. Please log in again.");
        navigate(`/posts/${interviewId}?skip`);
      } else showErrorMessage("Failed to generate image. Please try again");
    }
    setGeneratingImage(false);
  };
  const generateIdea = async () => {
    if (generatingIdea) return;
    setGeneratingIdea(true);
    try {
      const response = await axios.post(
        `${SERVER_URL}/posts/${interviewId}/${post?._id}/generate/prompt`,
        {
          style: style.title,
          tooltip: style.description,
        }
      );
      dispatch(setDefaultPrompt(response.data));
      const newImageSuggestions = [...(post?.imageSuggestions || [])];
      if (newImageSuggestions) {
        const suggestionIndex = post.imageSuggestions.findIndex(
          (suggestion) => suggestion.style === style.title
        );
        if (suggestionIndex > -1) {
          newImageSuggestions.splice(suggestionIndex, 1, {
            ...newImageSuggestions[suggestionIndex],
            suggestion: response.data,
          });
          dispatch(
            setPost({
              ...post,
              imageSuggestions: newImageSuggestions,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        showErrorMessage("Your session is expired. Please log in again.");
        navigate(`/posts/${interviewId}?skip`);
      } else showErrorMessage("Failed to generate idea. Please try again");
    }
    setGeneratingIdea(false);
  };

  useEffect(() => {
    if (defaultPrompt) setText(defaultPrompt);
  }, [defaultPrompt]);

  useEffect(() => {
    if (!post?.imageSuggestions || !style) return;
    if(!Array.isArray(post.imageSuggestions)) return;
    const suggestionIndex = post.imageSuggestions.findIndex(
      (suggestion) => suggestion.style === style.title
    );
    if (suggestionIndex < 0) return;
    dispatch(
      setDefaultPrompt(post?.imageSuggestions[suggestionIndex].suggestion)
    );
  }, [dispatch, post?.imageSuggestions, style]);

  const pending = submitting || generatingImage || generatingIdea;

  return (
    <>
      <div className="px-7 flex-1 flex flex-col gap-4 items-start justify-center md:pb-10">
        <div className="font-figtree text-[20px] font-[500] leading-[160%] tracking-[0.15px]">
          Generate image for the post
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-7 gap-y-10 md:gap-4 lg:gap-14">
          <div className="md:col-span-3 space-y-6">
            <ImageStyleDropdown style={style} setStyle={setStyle} />
            <div className="w-full space-y-3">
              <div className="font-figtree text-[14px] leading-[133.4%] text-black">
                Use our Image Idea or write your own
              </div>
              <div className="w-full relative">
                <textarea
                  name="text"
                  id="idea"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                  className="resize-none h-[500px] py-4 px-3 rounded-[30px] border border-other-outlined-border w-full bg-transparent scroll-dark"
                  style={{
                    opacity: generatingIdea || !defaultPrompt ? 0.3 : 1,
                  }}
                  maxLength={1000}
                ></textarea>
                <div className="absolute top-0 left-3 -translate-y-1/2 bg-off-white-light text-text-secondary font-figtree text-[12px] leading-[100%] tracking-[0.15px]">
                  Image Idea
                </div>
                {(generatingIdea || !defaultPrompt) && (
                  <div className="absolute w-full h-full flex items-center justify-center top-0 left-0">
                    <div className="size-[53px] p-2.5 bg-white rounded-full">
                      <Loader />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center gap-2.5 flex-row max-400:flex-wrap">
              <Button
                className="rounded-[40px] py-1 font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px] flex-1"
                onClick={generateIdea}
                disabled={pending}
                text={
                  generatingIdea ? "Generating..." : "Generate another idea"
                }
                variant="outline"
              />
              <Button
                className="rounded-[40px] py-1 font-figtree text-[13px] font-[700] leading-[169%] tracking-[0.46px] flex-1"
                onClick={generateImage}
                disabled={pending}
                text={
                  generatingImage
                    ? "Generating..."
                    : src
                    ? "Generate another image"
                    : "Generate image"
                }
              />
            </div>
          </div>
          <div className="md:col-span-4 flex flex-col justify-between lg:min-h-full">
            <div className="w-full space-y-1">
              <div
                className="w-full rounded-[30px] relative border border-off-white-dark"
                style={{ aspectRatio: "auto 1/1" }}
              >
                <ImageVersions isVertical />
                {src ? (
                  <img
                    src={src}
                    alt="ai"
                    className="w-full h-full rounded-[30px]"
                    style={{ opacity: generatingImage ? 0.3 : 1 }}
                  />
                ) : (
                  <div className="absolute top-7 left-6 text-text-secondary font-figtree text-[14px] leading-[143%] tracking-[0.15%]">
                    Your image will appear here...
                  </div>
                )}
                {generatingImage && (
                  <div className="absolute w-full h-full flex items-center justify-center top-0 left-0">
                    <div className="size-[53px] p-2.5 bg-white rounded-full">
                      <Loader />
                    </div>
                  </div>
                )}
              </div>
              {src && (
                <div className="w-full flex justify-end items-center">
                  <Button
                    icon={DownloadIcon}
                    text="Download"
                    variant="ghost"
                    className="gap-2 py-1 px-1.5 font-figtree text-[13px] font-[700] tracking-[0.46px]"
                    iconClassName="size-[18px]"
                    onClick={download}
                  />
                </div>
              )}
            </div>
            <div className="w-full justify-end gap-2 flex-row flex-shrink-0 border-t md:border-t-0 border-off-white-dark pt-6 hidden md:flex">
              <Button
                className="rounded-[40px] px-[22px] max-400:px-3 py-2 max-400:py-1  font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
                onClick={closePopup}
                disabled={pending}
                text={"Back"}
                variant="outline"
              />
              <Button
                className="rounded-[40px] px-[22px] max-400:px-3 py-2 max-400:py-1 font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
                onClick={submit}
                disabled={pending || !src}
                text={submitting ? "Adding..." : "Add image to post"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end gap-2 flex-row flex-shrink-0 border-t md:border-t-0 border-off-white-dark py-6 md:hidden px-7">
        <Button
          className="rounded-[40px] px-[22px] max-400:px-3 py-2 max-400:py-1  font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
          onClick={closePopup}
          disabled={pending}
          text={"Back"}
          variant="outline"
        />
        <Button
          className="rounded-[40px] px-[22px] max-400:px-3 py-2 max-400:py-1 font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
          onClick={submit}
          disabled={pending || !src}
          text={submitting ? "Adding..." : "Add image to post"}
        />
      </div>
    </>
  );
};

export default ImageGenerator;
