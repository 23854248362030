import React from "react";

const TemplateCard = ({ src, isSelected = false, onClick = () => {} }) => {
  return (
    <button
      className={`rounded-[20px] w-[227px] flex-shrink-0 max-w-full h-[128px] max-h-full border-[6px] overflow-hidden ${
        isSelected ? "border-light-blue" : "border-transparent"
      }`}
      onClick={onClick}
    >
      <img src={src} alt="template" className="w-full h-auto" />
    </button>
  );
};

export default TemplateCard;
