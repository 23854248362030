import React, { useState } from "react";
import MessageRow from "./MessageRow";
import { useSelector } from "react-redux";

const RawDataBlock = () => {
  const { messages, AvgAnswerLength, totalDuration, totalWordsCount } =
    useSelector((state) => state.post);
  const [playIndex, setPlayIndex] = useState(-1);
  return (
    <>
      <div className="w-full flex justify-between font-figtree text-[16px] leading-[181%] text-black flex-wrap">
        <span>
          <strong>Total Duration:</strong> {(totalDuration / 60).toFixed(1)}{" "}
          minutes
        </span>
        <span>
          <strong>Avg. Answer Length:</strong>{" "}
          {(AvgAnswerLength / 60).toFixed(1)} minutes
        </span>
        <span>
          <strong>Total Word count:</strong> {totalWordsCount}
        </span>
      </div>
      <div className="divide-y divide-[#0000001f] rounded-[30px] overflow-hidden w-full bg-off-white-super-light">
        <div className="w-full flex">
          <div className="py-4 text-text-primary font-roboto text-[14px] leading-[171%] tracking-[0.17px] w-[60px] sm:w-[72px] font-semibold pl-4">
            Role
          </div>
          <div className="hidden sm:block py-4 text-text-primary font-roboto text-[14px] leading-[171%] tracking-[0.17px] font-semibold flex-1 pl-2 sm:pl-4">
            Content
          </div>
          <div className="py-4 text-text-primary font-roboto text-[14px] leading-[171%] tracking-[0.17px] font-semibold w-[100px] xl:w-[160px] pl-2 sm:pl-4 flex-1 sm:flex-none">
            Recording
          </div>
          <div className="py-4 text-text-primary font-roboto text-[14px] leading-[171%] tracking-[0.17px] font-semibold w-[100px]  flex-1 sm:flex-none xl:w-[160px] pl-2 sm:pl-4">
            Duration
          </div>
          <div className="p-4 pl-2 sm:pl-4 text-text-primary font-roboto text-[14px] leading-[171%] tracking-[0.17px] font-semibold w-[120px] sm:w-[160px]">
            Word Count
          </div>
        </div>
        {messages.map((message, index) => (
          <MessageRow key={index} message={message} index={index} playIndex={playIndex} setPlayIndex={setPlayIndex} />
        ))}
      </div>
    </>
  );
};

export default RawDataBlock;
