import React, { useEffect, useMemo, useRef, useState } from "react";
import TemplateCard from "./TemplateCard";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button";
import CameraIcon from "assets/images/image-manager/CameraAlt.svg";
import RestoreIcon from "assets/images/image-manager/Undo.svg";
import DownloadIcon from "assets/images/image-manager/download.svg";
import { setImageManagerTab, setPost } from "store/postSlice";
import { downloadFile, IMAGE_MANAGER_TABS, showErrorMessage } from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import useInterview from "hooks/useInterview";
import { useNavigate } from "react-router-dom";

// const replaceTextInURL = (url, oldText, newText) => {
//   console.log(oldText);

//   if (!oldText?.trim()) return url;
//   const result = url.replace(
//     encodeURIComponent(oldText),
//     encodeURIComponent(newText)
//   );
//   console.log(result);
//   return result;
// };

const ImageTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPublicId, setSelectedPublicId] = useState(null);
  const { post } = useSelector((state) => state.post);
  const { interviewId } = useInterview();
  const [wait, setWait] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [text, setText] = useState(false);
  const [defaultText, setDefaultText] = useState(null);
  const [src, setSrc] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [restoring, setRestoring] = useState(false);
  const extensionRef = useRef("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (post?.templateImage)
      setSelectedPublicId(prev => {
        const selectedIndex = post.templateImage.findIndex(t => t.publicId === prev)
        return selectedIndex < 0 ? post.templateImage[0]?.publicId || null : prev});
    else setSelectedPublicId(null);
  }, [post?.templateImage]);

  const closePopup = () => {
    dispatch(setImageManagerTab(IMAGE_MANAGER_TABS.NO_TAB));
  };

  const selectedTemplate = useMemo(() => {
    if(!post?.templateImage) return null
    const templateIndex = post.templateImage.findIndex(
      (template) => template.publicId === selectedPublicId
    );
    if (templateIndex > -1) return post.templateImage[templateIndex];
    else return null;
  }, [post?.templateImage, selectedPublicId]);

  const ableToRestore = src && selectedTemplate && selectedTemplate.initial !== src;

  const submit = async () => {
    if (submitting) return;
    setSubmitting(true);
    try {
      await axios.post(
        `${SERVER_URL}/posts/${interviewId}/${post?._id}/image`,
        {
          image: src,
        }
      );
      dispatch(
        setPost({
          ...post,
          images: [src],
        })
      );
      closePopup();
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        showErrorMessage("Your session is expired. Please log in again.");
        navigate(`/posts/${interviewId}?skip`);
      } else showErrorMessage("Failed to submit image. Please try again");
    }
    setSubmitting(false);
  };
  const download = () => {
    downloadFile(src);
  };
  const changeText = async () => {
    if (wait) return;
    setWait(true);
    try {
      const templateResponse = await axios.post(
        `${SERVER_URL}/posts/${interviewId}/${post._id}/upload`,
        {
          text,
          publicId: selectedPublicId,
          imageLink: selectedTemplate.imageLink,
        }
      );
      const newImageTemplates = [...(post?.templateImage || [])];
      if (!newImageTemplates) return;
      const templateIndex = newImageTemplates.findIndex(
        (t) => t.publicId === selectedPublicId
      );
      if (templateIndex < 0) return;
      newImageTemplates.splice(templateIndex, 1, {
        ...newImageTemplates[templateIndex],
        imageLink: templateResponse.data.url,
        text
      });
      dispatch(
        setPost({
          ...post,
          templateImage: newImageTemplates,
        })
      );
    } catch (error) {
      console.log(error);
    }
    setWait(false);
  };

  useEffect(() => {
    setSrc(selectedTemplate?.imageLink);
    setText(decodeURIComponent(selectedTemplate?.text) || post?.title || "");
    setDefaultText(decodeURIComponent(selectedTemplate?.text) ||  post?.title || "");
  }, [post?.title, selectedTemplate]);

  const restore = async () => {
    if (restoring) return;
    setRestoring(true);
    try {
      const response = await axios.put(
        `${SERVER_URL}/posts/${interviewId}/${post?._id}/templates/restore`,
        { publicId: selectedPublicId }
      );
      dispatch(setPost(response.data));
    } catch (error) {
      console.log(error);
      if (error.response?.status === 401) {
        showErrorMessage("Your session is expired. Please log in again.");
        navigate(`/posts/${interviewId}?skip`);
      } else showErrorMessage("Failed to restore templates. Please try again");
    }
    setRestoring(false);
  };

  const startSelectingImage = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
      if (fileExtension) {
        extensionRef.current = fileExtension;
      }
      setUploading(true);
      await uploadImage(file);
      setUploading(false);
    }
  };

  const uploadImage = async (file) => {
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
      try {
        let response = await axios.get(
          `${SERVER_URL}/posts/${interviewId}/${post?._id}/signed-url`,
          {
            params: {
              fileExtension,
            },
          }
        );
        const signedUrl = response.data;
        await axios.put(signedUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
          body: file,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // setUploadProgress(percentCompleted);
            console.log(`Upload Progress: ${percentCompleted}%`);
          },
        });
        const templateResponse = await axios.post(
          `${SERVER_URL}/posts/${interviewId}/${post._id}/upload`,
          {
            image: signedUrl.split("?").shift(),
            publicId: selectedPublicId,
            imageLink: selectedTemplate.imageLink,
          }
        );
        const newImageTemplates = [...(post?.templateImage || [])];
        if (!newImageTemplates) return;
        const templateIndex = newImageTemplates.findIndex(
          (t) => t.publicId === selectedPublicId
        );
        if (templateIndex < 0) return;
        newImageTemplates.splice(templateIndex, 1, {
          ...newImageTemplates[templateIndex],
          imageLink: templateResponse.data.url,
          image: signedUrl.split("?").shift()
        });
        dispatch(
          setPost({
            ...post,
            templateImage: newImageTemplates,
          })
        );
      } catch (error) {
        console.log(error);
        if (error.response?.status === 401) {
          showErrorMessage("Your session is expired. Please log in again.");
          navigate(`/posts/${interviewId}?skip`);
          return false;
        }
        showErrorMessage("Failed to upload image. Please try again");
        return false;
      }
    }
    return true;
  };

  const pending = submitting || restoring || wait || uploading;

  return (
    <>
      <div className="px-7 flex-1 flex flex-col gap-4 items-start justify-center">
        <div className="font-figtree text-[20px] font-[500] leading-[160%] tracking-[0.15px]">
          Choose a Template
        </div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-7 gap-7">
          <div className="relative lg:col-span-2 h-[168px] lg:h-auto lg:w-full overflow-hidden rounded-[30px]">
            <div className="absolute top-0 left-0 h-full w-full overflow-auto scroll-dark flex flex-row lg:flex-col gap-4  py-5 lg:py-9 px-5 rounded-[30px] border border-off-white-dark">
              {post?.templateImage?.map((template, index) => (
                <TemplateCard
                  key={index}
                  src={template.imageLink}
                  isSelected={template.publicId === selectedPublicId}
                  onClick={() => {
                    setSelectedPublicId(template.publicId);
                  }}
                />
              ))}
            </div>
          </div>
          <div className="lg:col-span-5 space-y-3">
            <div className="w-full relative rounded-[30px] overflow-hidden">
              <img
                src={selectedTemplate?.imageLink}
                alt="template"
                className="w-full"
                style={{ aspectRatio: "auto 912 / 513" }}
              />
            </div>
            <div className="w-full flex justify-between items-end sm:items-center gap-x-2 flex-wrap">
              <div>
                <Button
                  icon={CameraIcon}
                  text={
                    uploading
                      ? "Uploading..."
                      : selectedTemplate?.withLogo
                      ? "Change Your Avatar Photo"
                      : "Change Photo"
                  }
                  variant="ghost"
                  className="gap-2 py-1 px-1.5 font-figtree text-[13px] font-[700] tracking-[0.46px]"
                  iconClassName="size-[18px]"
                  disabled={pending}
                  onClick={startSelectingImage}
                />
              </div>
              <div className="flex items-center gap-1">
                <Button
                  icon={RestoreIcon}
                  text="Restore Template"
                  variant="ghost"
                  className="gap-2 py-1 px-1.5 font-figtree text-[13px] font-[700] tracking-[0.46px] text-text-secondary"
                  iconClassName="size-[18px]"
                  disabled={!ableToRestore || pending}
                  onClick={restore}
                />
                <Button
                  icon={DownloadIcon}
                  text="Download"
                  variant="ghost"
                  className="gap-2 py-1 px-1.5 font-figtree text-[13px] font-[700] tracking-[0.46px]"
                  iconClassName="size-[18px]"
                  onClick={download}
                />
              </div>
            </div>
            <div className="w-full flex items-end lg:items-center justify-center gap-y-2 gap-x-4 flex-col lg:flex-row ">
              <div className="lg:flex-1 relative w-full lg:w-auto">
                <textarea
                  type="text"
                  rows={2}
                  className="resize-none w-full rounded-[30px] py-2 pl-3 pr-[48px] border border-other-outlined-border scroll-dark bg-transparent"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <span className="text-text-secondary font-figtree text-[12px] leading-[12px] tracking-[0.15px] bg-off-white-light absolute top-0 -translate-y-1/2 left-4">
                  Template Text
                </span>
              </div>
              <Button
                text={wait ? "Wait.." : "Change"}
                variant="outline"
                className="py-2 px-5 font-figtree text-[15px] font-[700] tracking-[0.46px] rounded-[40px] leading-[173%] flex-shrink-0 w-full sm:w-auto text-center lg:mb-2.5"
                disabled={pending || defaultText === text}
                onClick={changeText}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end gap-2 p-4 flex-row border-t border-off-white-dark flex-shrink-0">
        <Button
          className="rounded-[40px] px-[22px] py-2 font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
          onClick={closePopup}
          disabled={pending}
          text={"Back"}
          variant="outline"
        />
        <Button
          className="rounded-[40px] px-[22px] py-2 font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
          onClick={submit}
          disabled={pending}
          text={submitting ? "Adding..." : "Add image to post"}
        />
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageSelect}
        className="hidden"
        ref={inputRef}
      />
    </>
  );
};

export default ImageTemplate;
