import { useEffect } from "react";
import LogRocket from "logrocket";
import useUserInfo from "./useUserInfo";
import setupLogRocketReact from "logrocket-react";

// use only App.js
const useLogRocket = () => {
  const { profile } = useUserInfo()
  useEffect(() => {
    const ignoredDomainsForRogRockets = [
      "localhost",
      "stg.assistant.q-ueue.ai",
      "dev.assistant.q-ueue.ai",
    ];
    if (!ignoredDomainsForRogRockets.includes(window.location.hostname))
      LogRocket.init("iraia3/jessica-iuk3v");
    if (window.location.hostname === "stg.assistant.q-ueue.ai")
      LogRocket.init("iraia3/stg-jessica");
  }, []);

  useEffect(() => {
    if (profile.email) {
      LogRocket.identify(profile.email, {
        name: profile.firstName,
        email: profile.email,
      });
      setupLogRocketReact(LogRocket);
    }
  }, [profile.email, profile.firstName]);
  return {};
};

export default useLogRocket;
