import {
  AUTH_STEP,
  PAGE_TYPES,
  isValidEmail,
  obfuscateEmail,
  showErrorMessage,
} from "Utils";
import { BASE_SERVER_URL } from "api";
import axios from "axios";
import useUserInfo from "hooks/useUserInfo";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setAuth } from "store/mainSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [wait, setWait] = useState(false);
  const [email, setEmail] = useState("");
  const [delay, setDelay] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { pageOn } = useSelector((state) => state.main.status);
  const { profile } = useUserInfo();
  useEffect(() => {
    if (
      (pageOn === PAGE_TYPES.SCHEDULE ||
        pageOn === PAGE_TYPES.REGISTRATION ||
        pageOn === PAGE_TYPES.MANAGE) &&
      profile.email
    ) {
      setEmail(profile.email);
    }
  }, [pageOn, profile.email]);
  const submit = async (event) => {
    event.preventDefault();
    if (wait) return;
    if (email.trim().length === 0) {
      showErrorMessage("Email is required.");
      return false;
    }
    if (!isValidEmail(email.trim())) {
      showErrorMessage("Please enter a valid email address.");
      return false;
    }
    setWait(true);
    try {
      await axios.post(`${BASE_SERVER_URL}/auth/forgot-password`, {
        email,
        redirectUrl:
          pageOn === PAGE_TYPES.SCHEDULE ||
          pageOn === PAGE_TYPES.REGISTRATION ||
          pageOn === PAGE_TYPES.MANAGE
            ? "/"
            : location.pathname,
      });
      setSubmitted(true);
      setDelay(true);
      setTimeout(() => {
        setDelay(false);
      }, 10 * 1000);
    } catch (error) {
      console.log(error);
      showErrorMessage("Failed to submit your email, please try again");
    }
    setWait(false);
  };
  const goBack = () => {
    if (submitted) setSubmitted(false);
    else dispatch(setAuth(AUTH_STEP.sign_in));
  };
  const hiddenEmail = obfuscateEmail(email);
  return (
    <div className="auth-right">
      <div className="auth-title">
        {submitted ? "Check your email" : "Forgot Password"}
      </div>
      <div className="auth-description">
        {submitted
          ? `Email with password reset information sent to ${hiddenEmail}. Didn't get the email? Check your spam folder before requesting a new reset link.`
          : "Enter the email you used to create your account. We’ll send a link for you to reset your password. Please don’t share this link with anyone."}
      </div>
      <form onSubmit={submit}>
        {submitted || (
          <div className="auth-field">
            <input
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <img src={HelpCircle} alt="help" className="auth-input-help" /> */}
          </div>
        )}
        <button
          type="submit"
          className={"auth-submit" + (wait ? " wait" : "")}
          disabled={wait || (submitted && delay)}
        >
          {submitted ? (wait ? "Sending..." : "Resend Email") : "Submit"}
        </button>
        <button
          type="button"
          className={"auth-submit go-back"}
          onClick={goBack}
        >
          Go Back
        </button>
        <div style={{ flex: 1 }}></div>
      </form>
    </div>
  );
};

export default ForgotPassword;
