import React from "react";
import MonthSelector from "./components/MonthSelector";
import DateSelector from "./components/DateSelector";

const Calendar = () => {
  return (
    <div className="calendar flex flex-col justify-center items-start gap-3 text-black bg-white p-4 rounded-[30px]">
      <div className="p-3 text-[#191919] font-figtree font-[600]">Choose Date</div>
      <MonthSelector />
      <DateSelector />
    </div>
  );
};

export default Calendar;
