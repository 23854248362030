import { PAGE_TYPES } from "Utils";
import useInterview from "hooks/useInterview";
import useUserInfo from "hooks/useUserInfo";
import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const CustomHelmet = ({ type }) => {
  const { post, meeting } = useSelector((state) => state);
  const { profile } = useUserInfo();
  const { guestMode } = useInterview();
  let title = "Jessica Queue",
    description =
      "Personalized content creation interview<br>Voice interview, no need for a web camera";
  if (type === PAGE_TYPES.MEETING) {
    if(guestMode)
    title = `${guestMode?.assistant?.name || "Jessica Queue"} <> ${guestMode?.guest?.firstName || profile.fullName}`;
    description = `Topic interview : ${meeting.topic}`;
  } else if (type === PAGE_TYPES.POSTS) {
    title = `Jessica Queue <> ${profile.fullName}`;
    description = `Personalized contents : ${post.topic}`;
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default CustomHelmet;
