import { createSlice } from "@reduxjs/toolkit";
import { INTERVIEW_STATUS } from "Utils";

const initialState = {
  interviewId: null,
  status: INTERVIEW_STATUS.CREATED,
  topic: "",
  score: 0,
  evaluation: "",
  podcastUrl: null,
  guestMode: null
};

const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    setInterviewId: (state, action) => {
      state.interviewId = action.payload;
    },
    setInterviewStatus: (state, action) => {
      state.status = action.payload;
    },
    setTopic: (state, action) => {
      state.topic = action.payload
    },
    initInterviewData: () => {
      return { ...initialState };
    },
    setInterviewScore: (state, action) => {
      state.score = action.payload.score
      state.evaluation = action.payload.evaluation
    },
    setPodcastUrl: (state, action) => {
      state.podcastUrl = action.payload
    },
    setGuestMode: (state, action) => {
      state.guestMode = action.payload
    }
  },
});

export const {
  setInterviewId,
  setInterviewStatus,
  setTopic,
  initInterviewData,
  setInterviewScore,
  setPodcastUrl,
  setGuestMode,
} = interviewSlice.actions;

export default interviewSlice.reducer;
