import { createSlice } from "@reduxjs/toolkit";
import { get15MinsDate, RECURRING_VALUES } from "Utils";

export const PREPARATION_STEP = {
  TOPIC_SELECTION: "selecting-topic",
  DATE_SELECTION: "selecting-date",
};

const initState = () => ({
  step: PREPARATION_STEP.TOPIC_SELECTION,
  topics: [],
  isSettingUpAudience: false,
  isSettingUpGuestMode: false,
  year: get15MinsDate().getFullYear(),
  month: get15MinsDate().getMonth(),
  date: get15MinsDate().getDate(),
  hour: get15MinsDate().getHours(),
  min: get15MinsDate().getMinutes(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  recurring: RECURRING_VALUES.NOT_REPEAT,
  scheduledId: null,
  guestMode: null, // {assistant: {name, picture}, guest: {firstName, lastName, company, headline, picture, email}}
});

const preparationSlice = createSlice({
  name: "preparation",
  initialState: initState(),
  reducers: {
    setPreparationStep: (state, action) => {
      state.step = action.payload;
    },
    initPreparation: () => {
      return { ...initState() };
    },
    setMonth: (state, action) => {
      state.month = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setHour: (state, action) => {
      state.hour = action.payload;
    },
    setMin: (state, action) => {
      state.min = action.payload;
    },
    setTimezone: (state, action) => {
      state.timezone = action.payload;
    },
    setRecurring: (state, action) => {
      state.recurring = action.payload;
    },
    setIsSettingUpAudience: (state, action) => {
      state.isSettingUpAudience = !!action.payload;
    },
    setIsSettingUpGuestMode: (state, action) => {
      state.isSettingUpGuestMode = !!action.payload;
    },
    setTopics: (state, action) => {
      // Check if action.payload exists and map over it
      state.topics = action.payload?.map?.(t => ({
        topic: t.topic,
        isAI: !!t.isAI,
        isCustom: !!t.isCustom,
        isNew: !!t.isNew,
        isTrending: !!t.isTrending,
        tag: t.tag || ""
      })) || [];
    },
    setScheduledId: (state, action) => {
      state.scheduledId = action.payload;
    },
    setGuestMode: (state, action) => {
      state.guestMode = action.payload
    }
  },
});

export const {
  setPreparationStep,
  initPreparation,
  setDate,
  setHour,
  setMin,
  setMonth,
  setTimezone,
  setYear,
  setRecurring,
  setIsSettingUpAudience,
  setIsSettingUpGuestMode,
  setTopics,
  setScheduledId,
  setGuestMode
} = preparationSlice.actions;

export default preparationSlice.reducer;
