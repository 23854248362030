import React from 'react';
import PropTypes from 'prop-types';

export const ButtonVariant = {
  FILL: 'fill',
  OUTLINE: 'outline',
  GHOST: 'ghost',
};

const Button = ({
  text = '',
  icon,
  alt = 'icon',
  iconClassName = '',
  variant = ButtonVariant.FILL,
  onClick = () => {},
  disabled = false,
  className = '',
  tag = "button",
  ...props
}) => {
  const getButtonClasses = () => {
    let baseClasses = 'rounded-[40px] flex items-center justify-center text-center ';

    switch (variant) {
      case ButtonVariant.OUTLINE:
        baseClasses += disabled
          ? 'border border-action-disabled-background text-action-disabled '
          : 'border border-primary-main text-primary-main interact ';
        break;
      case ButtonVariant.FILL:
        baseClasses += disabled
          ? 'border border-none bg-action-disabled-background text-action-disabled '
          : 'border border-primary-main bg-primary-main interact text-white ';
        break;
      case ButtonVariant.GHOST:
        baseClasses += disabled
          ? 'text-action-disabled opacity-60 '
          : 'text-primary-main interact ';
        break;
      default:
        break;
    }

    return `${baseClasses} ${className}`;
  };

  const Tag = tag; // Will be the HTML tag you want

  return (
    <Tag
      className={getButtonClasses()}
      onClick={onClick}
      {...(Tag === 'button' ? { disabled } : {})}
      {...props}
    >
      {icon && (
        <img
          src={icon}
          alt={alt}
          className={`flex-shrink-0 ${iconClassName}`}
        />
      )}
      <span className="text-nowrap flex-shrink-0">{text}</span>
    </Tag>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  alt: PropTypes.string,
  iconClassName: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(ButtonVariant)),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  tag: PropTypes.string,
  download: PropTypes.string,
  href: PropTypes.string
};

export default Button;
