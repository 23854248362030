import React from "react";
import RadioChecked from "assets/images/personalize/RadioButtonChecked.svg";
import RadioUnchecked from "assets/images/personalize/RadioButtonUnchecked.svg";
import Linkedin from "assets/images/personalize/linkedin.svg";
import UserAvatar from "assets/images/users/Avatar Image.png";
import UserAvatar1 from "assets/images/users/Avatar Image (1).png";
import UserAvatar2 from "assets/images/users/Avatar Image (2).png";
import UserAvatar3 from "assets/images/users/Avatar Image (3).png";
import UserAvatar4 from "assets/images/users/Avatar Image (4).png";
import UserAvatar5 from "assets/images/users/Avatar Image (5).png";
import UserAvatar6 from "assets/images/users/Avatar Image (6).png";
import { useDispatch } from "react-redux";
import { setPersonalizationSource } from "store/personalizeSlice";
import { PERSONALIZATION_SOURCE } from "Utils";

const OthersPosts = ({ selected = false }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setPersonalizationSource(PERSONALIZATION_SOURCE.OthersPosts));
  };
  return (
    <div
      className={`link w-[410px] h-[240px] max-w-full border-[10px] bg-off-white-light rounded-[40px] text-black flex flex-col justify-between items-center px-4 sm:px-9 py-4 sm:py-5 overflow-hidden ${
        selected ? "border-blue500" : "border-black-bg"
      }`}
      onClick={onClick}
    >
      <div className="w-full flex justify-between items-center">
        <div className="flex gap-1 sm:gap-3 items-center">
          <img
            src={selected ? RadioChecked : RadioUnchecked}
            alt={selected ? "checked" : "unchecked"}
            className="w-6 h-6"
          />
          <span className="text-[24px] sm:text-[30px] font-[700] tracking-[-0.3px] leading-[133%]">
            Others’ posts
          </span>
        </div>
        <img src={Linkedin} alt="linkedin" className="w-10 h-10" />
      </div>
      <div className="w-full flex justify-center items-center">
        <img
          src={UserAvatar}
          alt="person"
          className="rounded-full -mx-[5.25px]"
        />
        <img
          src={UserAvatar1}
          alt="person"
          className="rounded-full -mx-[5.25px]"
        />
        <img
          src={UserAvatar2}
          alt="person"
          className="rounded-full -mx-[5.25px]"
        />
        <img
          src={UserAvatar3}
          alt="person"
          className="rounded-full -mx-[5.25px]"
        />
        <img
          src={UserAvatar4}
          alt="person"
          className="rounded-full -mx-[5.25px]"
        />
        <img
          src={UserAvatar5}
          alt="person"
          className="rounded-full -mx-[5.25px]"
        />
        <img
          src={UserAvatar6}
          alt="person"
          className="rounded-full -mx-[5.25px]"
        />
      </div>
      <div className="font-inter text-[14px] font-[500] leading-[128%]">
        Choose this if you’d like your content to sound like your favorite
        thought leaders
      </div>
    </div>
  );
};

export default OthersPosts;
