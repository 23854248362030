import { configureStore } from "@reduxjs/toolkit";
import registerReducer from "./registerSlice";
import meetingReducer from "./meetingSlice";
import postReducer from "./postSlice";
import manageReducer from "./manageSlice";
import mainSlice from "./mainSlice";
import personalizeSlice from "./personalizeSlice";
import interviewSlice from "./interviewSlice";
import preparationSlice from "./preparationSlice";
import circleTopicSlice from "./circleTopicSlice";

const store = configureStore({
  reducer: {
    main: mainSlice,
    register: registerReducer,
    meeting: meetingReducer,
    post: postReducer,
    manage: manageReducer,
    personalize: personalizeSlice,
    interview: interviewSlice,
    preparation: preparationSlice,
    circle: circleTopicSlice,
  },
});

export default store;
