import { SERVER_URL } from "api";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setImageManagerTab, setPost } from "store/postSlice";
import { IMAGE_MANAGER_TABS, showErrorMessage } from "Utils";
import Trash from "assets/images/post-edit/trash-blue.svg";
import Jessica from "assets/images/meeting/meeting-jessica.png";

const ImageUploader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { interviewId: iId, postId: pId } = useParams();
  const { post } = useSelector((state) => state.post);
  const [uploading, setUploading] = useState(false);

  const inputRef = useRef(null);
  const imageRef = useRef(null);
  const extensionRef = useRef("");
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (post?.images?.length > 0) setImage(post.images[0]);
  }, [post?.images]);
  const startSelectingImage = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const deleteImage = async () => {
    if (!imageRef.current) {
      try {
        await axios.post(`${SERVER_URL}/posts/${iId}/${pId}/image`);
        dispatch(setPost({ ...post, images: [] }));
      } catch (error) {
        console.log(error);
        showErrorMessage("Failed to delete the image");
      }
    }
    imageRef.current = null;
    setImage(null);
  };
  const back = () => {
    imageRef.current = null;
    if (post?.images?.length > 0) setImage(post.images[0]);
    else setImage(null);
  };
  const handleImageSelect = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
      if (fileExtension) {
        extensionRef.current = fileExtension;
      }
      const reader = new FileReader();

      reader.onload = () => {
        const blob = new Blob([reader.result], { type: file.type });
        imageRef.current = file;
        const blobUrl = URL.createObjectURL(blob);
        setImage(blobUrl);
        // Use the 'blob' here (e.g., upload to a server, display in the UI, etc.)
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const finishEditing = async () => {
    if (uploading) return;
    const shouldUpload =
      imageRef.current || (!image && post?.images?.length > 0);
    let result = false;
    if (shouldUpload) {
      setUploading(true);
      result = await uploadImage();
      setUploading(false);
    }
    if (result) dispatch(setImageManagerTab(IMAGE_MANAGER_TABS.NO_TAB));
  };
  const uploadImage = async () => {
    if (imageRef.current) {
      try {
        let response = await axios.get(
          `${SERVER_URL}/posts/${iId}/${pId}/signed-url`,
          {
            params: {
              fileExtension: extensionRef.current,
            },
          }
        );
        const signedUrl = response.data;
        console.log(imageRef.current);
        const uploadResponse = await axios.put(signedUrl, imageRef.current, {
          headers: {
            "Content-Type": imageRef.current.type,
          },
          body: imageRef.current,
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // setUploadProgress(percentCompleted);
            console.log(`Upload Progress: ${percentCompleted}%`);
          },
        });
        console.log(uploadResponse);
        await axios.post(`${SERVER_URL}/posts/${iId}/${pId}/image`, {
          image: signedUrl.split("?").shift(),
        });
        dispatch(
          setPost({
            ...post,
            images: [signedUrl.split("?").shift()],
          })
        );
      } catch (error) {
        console.log(error);
        if (error.response?.status === 401) {
          showErrorMessage("Your session is expired. Please log in again.");
          navigate(`/posts/${iId}?skip`);
          return false;
        }
        showErrorMessage("Failed to upload image. Please try again");
        return false;
      }
    }
    return true;
  };
  return image ? (
    <>
      <div className="w-full flex-1 relative">
        <div className="absolute top-0 left-0 p-4 w-full h-full flex items-center justify-center">
          <div className="flex-1 w-fit flex max-h-full">
            <img
              // src={post.images[0]}
              src={image}
              alt="post"
              className="max-w-full max-h-full rounded-lg object-contain flex-1"
            />
            <div
              className="rounded-full p-1.5 bg-white stroke-[1px] stroke-blue-400 border border-solid border-blue-400 absolute right-4 top-5 cursor-pointer"
              onClick={deleteImage}
            >
              <img
                src={Trash}
                alt="remove"
                className="w-4 h-4 sm:w-6 sm:h-6 aspect-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-end gap-2 p-4 flex-row">
        <button
          className="rounded-[40px] border border-solid border-primary-main px-[22px] py-2 text-primary-main font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] min-w-[50%] sm:min-w-[140px]"
          style={{
            fontFeatureSettings: " 'clig' off, 'liga' off",
            color: "rgb(14 36 214 / var(--tw-text-opacity))",
          }}
          onClick={back}
        >
          Back
        </button>
        <button
          className="rounded-[40px] px-[22px] py-2 bg-primary-main text-white font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px] min-w-[50%] sm:min-w-[140px]"
          onClick={finishEditing}
          disabled={uploading}
          style={{ color: "white" }}
        >
          {uploading ? "Uploading..." : "Next"}
        </button>
      </div>
    </>
  ) : (
    <div className="flex-1 w-full flex flex-shrink-0 items-center justify-center">
      <div className="flex flex-col items-center gap-3 sm:gap-8 overflow-auto">
        <img
          src={Jessica}
          alt="Jessica"
          className="w-[120px] sm:w-[140px] h-auto"
        />
        <div className="flex flex-col sm:gap-[7px] items-center">
          <div
            style={{ color: "black" }}
            className="font-figtree text-[28px] sm:text-[34px] font-[400] leading-[123.5%] tracking-[0.25px] text-center"
          >
            Select files to begin
          </div>
          <div
            style={{ color: "black" }}
            className="font-figtree text-[16px] font-[400] leading-[150%] tracking-[0.15px]"
          >
            Share images in your post
          </div>
        </div>
        <button
          className="rounded-[40px] px-[22px] py-2 max-w-full bg-primary-main text-white font-figtree text-[15px] font-[700] leading-[173%] tracking-[0.46px]"
          onClick={startSelectingImage}
          style={{ color: "white" }}
        >
          Upload from your computer
        </button>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageSelect}
          className="hidden"
          ref={inputRef}
        />
      </div>
    </div>
  );
};

export default ImageUploader;
