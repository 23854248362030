import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
import { Provider } from "react-redux";
import { IntercomProvider } from "react-use-intercom";
import axios from "axios";

const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
axios.defaults.withCredentials = true;
// axios.defaults.headers = {
//   "Access-Control-Allow-Origin": "*",
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <IntercomProvider appId={intercomAppId} autoBoot>
      <Provider store={store}>
        <App />
      </Provider>
    </IntercomProvider>
  </BrowserRouter>
);
