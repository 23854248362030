import React from "react";

const PodcastInput = ({ className = "", text = "", readonly = false, loading = false, setText = () => { }, label = "", isTextArea = false, rows = 3, placeholder = "" }) => {
  return <div className="relative w-full">
    {isTextArea ? <textarea
      type="text"
      className={`resize-none w-full rounded-[30px] py-4 px-3 border border-other-outlined-border bg-white ${className} text-text-primary text-[16px] font-figtree leading-[24px] tracking-[0.15px] outline-none scroll-dark`}
      value={text}
      onChange={(e) => {
        if (loading || readonly) return;
        setText(e.target.value)
      }}
      rows={rows}
      placeholder={placeholder}
    /> :
      <input
        type="text"
        className={`resize-none w-full rounded-[30px] py-4 px-3 border border-other-outlined-border bg-white ${className} text-text-primary text-[16px] font-figtree leading-[24px] tracking-[0.15px]`}
        value={text}
        onChange={(e) => {
          if (loading || readonly) return;
          setText(e.target.value)
        }}
        placeholder={placeholder} />}
    {label && <div className="text-text-secondary font-figtree text-[12px] leading-[12px] tracking-[0.15px] bg-off-white-super-light absolute top-0 -translate-y-1/2 left-6 h-0.5 overflow-visible flex items-center">
      {label}
    </div>}
  </div>
}

export default PodcastInput;
