import React, { useState } from "react";
import SignOutIcon from "assets/images/log-out-01.svg";
import checkIcon from "assets/images/billing/checkIcon.png";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { initProfile, setBillingInfo, setShowUpgrade } from "store/mainSlice";
import MasterCardIcon from "assets/images/Mastercard.svg";
import useUserInfo from "hooks/useUserInfo";
import { showErrorMessage } from "Utils";
import axios from "axios";
import { SERVER_URL } from "api";
import useRedirect from "hooks/useRedirect";
import { useNavigate } from "react-router-dom";
import Avatar from "components/Avatar";

const ProfileMenu = ({ setOpen }) => {
  const navigate = useNavigate();
  const { profile, signOut, isSubscribed, billingInfo, isCanceled } =
    useUserInfo();
  const { firstName, picture } = profile;
  const getNewPaymentDateString = () => {
    const newPaymentDate = billingInfo?.nextPaymentAt;
    if (!newPaymentDate) return "";
    const newPaymentDateArray = newPaymentDate.split(" ");
    return `${newPaymentDateArray[2]} ${newPaymentDateArray[1]} ${newPaymentDateArray[3]}`;
  };
  const {navigateCreatingRedirect} = useRedirect()
  const dispatch = useDispatch();
  const [wait, setWait] = useState(false);

  const goToSubscribe = () => {
    navigateCreatingRedirect("/subscribe");
    setOpen(false);
  };
  const goToUpgrade = () => {
    setOpen(false);
    dispatch(setShowUpgrade(true));
  };
  const renewSubscription = async () => {
    if (!billingInfo || !isCanceled) {
      return;
    }
    setWait(true)
    try {
      await axios.post(`${SERVER_URL}/billing/uncancel`);
      const newBillingInfo = { ...billingInfo };
      newBillingInfo.isCanceled = false;
      dispatch(setBillingInfo(newBillingInfo));
    } catch (error) {
      console.log(error);
      showErrorMessage(
        error.response?.data?.message || "Failed renew subscription, please try again"
      );
    }
    setWait(false)
  };
  return (
    <div
      className="absolute inline-flex flex-col gap-5 flex-shrink-0 rounded-[30px] bg-off-white-light cursor-default z-10 right-4 md:right-0 w-[calc(100%-32px)] md:w-[760px] top-20 md:top-full py-6"
      style={{
        boxShadow:
          "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
      }}
    >
      <div className="pl-6 md:pl-9 gap-4 inline-flex items-center justify-start">
        <Avatar
          src={picture}
          alt="avatar"
          className="w-[65px] h-auto rounded-full"
        />
        <div className="pr-4">
          <div className="self-stretch text-black font-figtree text-[24px] font-semibold leading-6 md:leading-[45px] tracking-[-0.48px] mb-2 capitalize">
            Hello {firstName}
          </div>
          <div className="max-w-[335px] w-full text-black-bg font-figtree leading-5 font-normal">
            {isSubscribed
              ? "Jessica is here to amplify your authentic voice & unique insights."
              : "Subscribe now to unlock unlimited interviews & continue your streak!"}
          </div>
        </div>
      </div>
      {isSubscribed || (
        <div className="py-3 px-6 md:px-9 flex flex-col gap-2">
          <div className="flex items-start gap-3 self-stretch">
            <img src={checkIcon} alt="check" className="w-30px h-auto" />
            <div className="self-stretch text-black-bg font-inter text-sm font-normal">
              <strong>Uncover trending topics</strong> – Get interviewed about
              the most relevant topics in your niche and create content that is
              sure to be a hit with your audience.
            </div>
          </div>
          <div className="flex items-start gap-3 self-stretch">
            <img src={checkIcon} alt="check" className="w-30px h-auto" />
            <div className="self-stretch text-black-bg font-inter text-sm font-normal">
              <strong>Hands-off content creation</strong> – No typing, just
              talking. A five minute voice interview is all you need to create
              authentic & insightful social media and blog posts.
            </div>
          </div>
          <div className="flex items-start gap-3 self-stretch">
            <img src={checkIcon} alt="check" className="w-30px h-auto" />
            <div className="self-stretch text-black-bg font-inter text-sm font-normal">
              <strong>A week of content in 5 minutes</strong> – Jessica uncovers
              your insights in a way that feels natural & effortless. All you
              need is five easy minutes to create all your content for the week.
            </div>
          </div>
        </div>
      )}
      <div className="w-full h-[1px] bg-[#00000033]"></div>
      <div className="px-6 md:px-9 flex flex-col items-start gap-3.5">
        {isCanceled && (
          <div className="flex px-2.5 py-1 rounded-[6px] bg-ros-500 text-white font-inter text-[14px] font-[500] leading-[20px]">
            Canceled
          </div>
        )}
        <div className="flex items-start sm:items-center justify-between gap-4 w-full flex-col sm:flex-row">
          <div className="flex items-center justify-between w-full sm:w-auto sm:justify-start gap-4 sm:gap-11 flex-wrap">
            <div className="flex gap-2 flex-col justify-start">
              {isSubscribed ? (
                <div className="text-black-bg font-figtree font-semibold text-[14px] leading-5">
                  Your Jessica Plan
                </div>
              ) : (
                <div className="text-black-bg font-figtree font-semibold text-[14px] leading-5">
                  Membership Plan
                </div>
              )}
              <div className="text-black-bg font-figtree font-[700] text-[20px] leading-5">
                Monthly $29
              </div>
              {isSubscribed && (
                <>
                  <div className="text-black-bg font-figtree font-normal text-[14px] leading-5">
                    {`Renews ${getNewPaymentDateString()}`}
                  </div>
                  <div
                    className="text-[#707070] font-figtree font-normal text-[14px] leading-5 cursor-pointer"
                    onClick={goToUpgrade}
                  >
                    Cancel
                  </div>
                </>
              )}
            </div>
            {!!billingInfo && (
              <div className="flex flex-col justify-center items-start gap-2">
                <div className="text-black-bg font-figtree font-semibold text-[14px] leading-5">
                  Payment Method
                </div>
                <div className="flex gap-2 justify-start items-center">
                  <img
                    src={MasterCardIcon}
                    alt="mastercard"
                    className="h-4 w-auto"
                  />
                  <div className="text-black-bg font-figtree font-normal text-[14px] leading-5">
                    {`Master Card ending in (${billingInfo?.cardDetails?.cardEnding})`}
                  </div>
                </div>
              </div>
            )}
          </div>
          {isSubscribed || (
            <div
              className="px-4 py-2.5 flex-shrink-0 rounded-[40px] bg-cta-blue text-white font-figtree text-[16px] font-semibold leading-5 capitalize cursor-pointer w-44 text-center"
              style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
              onClick={isCanceled ? renewSubscription : goToSubscribe}
            >
              {wait ? "Wait..." : isCanceled ? "Renew Subscription" : "Subscribe Now"}
            </div>
          )}
        </div>
      </div>

      <div className="w-full h-[1px] bg-[#00000033]"></div>
      <div
        className="pl-6 md:pl-9 py-2 flex gap-2 items-center justify-start self-stretch cursor-pointer"
        onClick={() => {
          signOut(() => {
            setOpen(false);
            dispatch(initProfile());
            navigate("/");
          });
        }}
      >
        <img src={SignOutIcon} alt="sign out" className="w-5 h-5" />
        <div className="flex-1 text-[#A22] font-heebo text-[16px] leading-5 font-normal">
          Sign out
        </div>
      </div>
    </div>
  );
};

export default ProfileMenu;
