import React, { useEffect, useRef, useState } from "react";
import LeftCard from "./components/LeftCard";
import RightCard from "./components/RightCard";
import useUserInfo from "hooks/useUserInfo";
import { INTERCOM_EVENTS, showErrorMessage } from "Utils";
import useRedirect from "hooks/useRedirect";
import useFastSpring from "hooks/useFastSpring";
import { useIntercom } from "react-use-intercom";

const Billing = () => {
  const { isChecking, isAuth, profile, isSubscribed } = useUserInfo();
  const [checkedSubscription, setCheckSubscription] = useState(false);
  const {
    email,
    firstName: name,
    officialFirstName: firstName,
    officialLastName: lastName,
  } = profile;
  const intercom = useIntercom();
  const subscribedRef = useRef(false);

  const { isCompleted, isFailed, openPaymentPopup, isSubscribing } =
    useFastSpring();
  const { redirectOrNavigate, navigateWithRedirect } = useRedirect();

  useEffect(() => {
    if (isCompleted) {
      console.log("success");
      intercom.trackEvent(INTERCOM_EVENTS.succeededSubscription, {
        schedule_link: `${window.location.origin}/schedule`,
      });
      subscribedRef.current = true;
      setTimeout(() => {
        navigateWithRedirect("/subscribe/success");
      }, 100);
    }
    if (isFailed) {
      console.log("failed");
      navigateWithRedirect("/subscribe/failed");
    }
  }, [intercom, isCompleted, isFailed, navigateWithRedirect]);

  useEffect(() => {
    if (!isChecking) {
      if (!isAuth) {
        showErrorMessage("Please sign in first");
        redirectOrNavigate("/");
        return;
      } else {
        if (isSubscribed && !checkedSubscription) {
          showErrorMessage("You already subscribed");
          redirectOrNavigate("/");
        }
        setCheckSubscription(true);
      }
    }
  }, [
    checkedSubscription,
    isAuth,
    isChecking,
    isSubscribed,
    redirectOrNavigate,
  ]);

  const checkout = async () => {
    openPaymentPopup("v2-assistant-subscription", {
      email,
      firstName,
      lastName,
    });
  };

  const triggeredEvent = useRef(false);

  useEffect(() => {
    console.log(intercom && name && !triggeredEvent.current);
    if (intercom && name && !triggeredEvent.current) {
      intercom.trackEvent(INTERCOM_EVENTS.startSignup, {
        subscription_link: `${window.location.origin}/subscribe`,
        firstName: name,
      });
      triggeredEvent.current = true;
    }
  }, [intercom, name]);

  const needToSubmitRef = useRef(false);
  useEffect(() => {
    return () => {
      console.log(needToSubmitRef.current, subscribedRef.current);
      if(needToSubmitRef.current && !subscribedRef.current) 
          intercom.trackEvent(INTERCOM_EVENTS.notCompletedSubscription, {
            subscription_link: `${window.location.origin}/subscribe`,
            firstName: name,
          });
        }
  }, [intercom, name])

  useEffect(() => {
    needToSubmitRef.current = !isChecking && !isSubscribed && isAuth
    console.log(needToSubmitRef.current);
  }, [isAuth, isChecking, isSubscribed]);

  if (isChecking) {
    return (
      <div className="absolute w-full min-h-full p-4 pb-8 flex items-center">
        <div className="w-full min-h-full flex items-center justify-center gap-8 text-3xl">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="absolute w-full min-h-full p-4 pb-8 flex items-center">
      <div className="w-full min-h-full flex items-center justify-center gap-8">
        {isSubscribing || (
          <>
            <LeftCard />
            <RightCard checkout={checkout} />
          </>
        )}
      </div>
    </div>
  );
};

export default Billing;
