import React, { useState } from "react";
import Copy from "assets/images/dashboard/copy.svg";
import ArrowUp from "assets/images/dashboard/chevron-up.svg";
import ArrowDown from "assets/images/dashboard/chevron-down.svg";
import useUserInfo from "hooks/useUserInfo";
import { useSelector } from "react-redux";
import useInterview from "hooks/useInterview";

const TranscriptionBlock = () => {
  const { messages } = useSelector((state) => state.post);
  const [open, setOpen] = useState(false);
  const { profile } = useUserInfo();
  const { guestMode } = useInterview();
  const copyContent = (e) => {
    e.stopPropagation();
    let transcription = "";
    messages.forEach(
      (message) =>
        (transcription += `${
          message.role === 0 ? profile.firstName : "Jessica"
        }: ${message.content}\n\n`)
    );
    navigator.clipboard
      .writeText(transcription) // Copy the URL to the clipboard
      .then(() => {
        console.log("Copied to clipboard: ", "text");
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard: ", error);
      });
  };
  return (
    <div className="border border-off-white-base rounded-[20px] w-full">
      <div
        onClick={() => {
          setOpen((prev) => !prev);
        }}
        className="w-full py-9 px-5 flex justify-between items-center link"
      >
        <div className="font-figtree text-[18px] font-[600] leading-[116%]">
          Transcription
        </div>
        <div className="flex gap-4 items-center">
          <div
            className="flex px-4 py-1.5 flex-row items-center gap-2 rounded-[40px] border border-solid border-primary-main cursor-pointer active:opacity-60 no-select"
            onClick={copyContent}
          >
            <img src={Copy} alt="copy" />
            <span className="font-figtree text-[14px] font-[700] leading-[160%] tracking-[0.4px] text-primary-main">
              Copy
            </span>
          </div>
          <img
            src={open ? ArrowUp : ArrowDown}
            alt="more"
            className="size-6 interact link"
          />
        </div>
      </div>
      <div
        className="height-transition w-full overflow-auto scroll-dark"
        style={{ maxHeight: open ? "640px" : 0 }}
      >
        <div className="p-5 w-full space-y-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className="w-full space-y-2 text-black font-figtree text-[14px] leading-[21px]"
            >
              <strong className="capitalize block w-full">
                {message.role === 0
                  ? guestMode?.guest?.firstName || profile.firstName
                  : guestMode?.assistant?.name || "Jessica"}
                :
              </strong>
              <span className="block w-full">{message.content}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TranscriptionBlock;
