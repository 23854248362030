import Button, { ButtonVariant } from "components/Button";
import useUserInfo from "hooks/useUserInfo";
import React from "react";
import MyCallCard from "./components/MyCallCard";
import Loader from "components/Loader";

const MyCalls = () => {
  const { schedules } = useUserInfo();
  return (
    <div className="my-calls w-full flex-1 relative p-2 sm:p-7 pt-0 sm:space-y-7">
      <div className="w-full flex flex-col sm:flex-row items-center justify-between py-4">
        <div className="hidden sm:block text-black-bg text-[32px] leading-[65%]">
          My Calls
        </div>
        <Button
          text="Schedule Another Call"
          variant={ButtonVariant.FILL}
          className="py-2 px-5 font-figtree text-[15px] font-[700] tracking-[0.46px] leading-[173.333%] w-full sm:w-auto"
          tag="a"
          href="/schedule"
        />
      </div>
      <div className="w-full space-y-3 sm:space-y-6">
        {schedules === null ? (
          <div className="w-full text-center font-figtree text-[24px] italic pt-10">
            <Loader />
          </div>
        ) : schedules.length === 0 ? (
          <div className="w-full text-center font-figtree text-[24px] italic pt-10">
            No Schedules
          </div>
        ) : (
          <>
            {schedules?.map((s, index) => (
              <MyCallCard key={index} schedule={s} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MyCalls;
