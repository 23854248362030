import { createSlice } from "@reduxjs/toolkit";
import { PERSONALIZATION_SOURCE, PERSONALIZATION_STEP } from "Utils";

const initialState = {
  source: PERSONALIZATION_SOURCE.myPosts,
  myPosts: null,
  step: PERSONALIZATION_STEP.selectingSource,
  selectedSource: PERSONALIZATION_SOURCE.myPosts,
};

const personalizeSlice = createSlice({
  name: "personalize",
  initialState,
  reducers: {
    init: () => ({ ...initialState }),
    setPersonalizationSource: (state, action) => {
      state.source = action.payload;
    },
    setMyPosts: (state, action) => {
      state.myPosts = action.payload;
    },
    setPersonalizationStep: (state, action) => {
      state.step = action.payload;
    },
    setSelectedSource: (state, action) => {
      state.selectedSource = action.payload;
    },
  },
});

export const {
  init,
  setMyPosts,
  setPersonalizationSource,
  setPersonalizationStep,
  setSelectedSource,
} = personalizeSlice.actions;

export default personalizeSlice.reducer;
