import React from "react";

const PreparationStepItem = ({ text = "", active = false }) => {
  return (
    <div
      className={`py-1 px-2.5 text-text-primary w-full sm:w-auto font-roboto text-[13px] leading-[138%] tracking-[0.16px] rounded-[16px] ${
        active ? "bg-off-white-dark" : "bg-off-white-base"
      }`}
    >
      {text}
    </div>
  );
};

export default PreparationStepItem;
