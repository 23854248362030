import React, { useEffect, useRef } from "react";
import avatar0 from "assets/images/users/Avatar Image.png";
import avatar1 from "assets/images/users/Avatar Image (1).png";
import avatar2 from "assets/images/users/Avatar Image (2).png";
import avatar3 from "assets/images/users/Avatar Image (3).png";
import avatar4 from "assets/images/users/Avatar Image (4).png";
import avatar5 from "assets/images/users/Avatar Image (5).png";
import avatar6 from "assets/images/users/Avatar Image (6).png";
import avatar7 from "assets/images/users/Avatar Image (7).png";
import avatar8 from "assets/images/users/Avatar Image (8).png";
import avatar9 from "assets/images/users/Avatar Image (9).png";
import user1 from "assets/images/register/user1.png";
import user2 from "assets/images/register/user2.png";
import user3 from "assets/images/register/user3.png";
import Star from "assets/images/register/Star.svg";

const userAvatars = [
  avatar0,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9,
];

const CarousalCard = () => {
  const client1Ref = useRef(null);
  const client2Ref = useRef(null);
  const client3Ref = useRef(null);

  const countRef = useRef(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const clientRefs = [client1Ref, client2Ref, client3Ref];
      if (clientRefs[countRef.current].current)
        clientRefs[countRef.current].current.style.opacity = 0;
      countRef.current = (countRef.current + 1) % 3;
      if (clientRefs[countRef.current].current)
        clientRefs[countRef.current].current.style.opacity = 1;
    }, 10 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const users = [
    {
      ref: client1Ref,
      title: "Showcase your expertise",
      name: "Sandra Stepan",
      content:
        "The high-ticket clients that I work with demand that I’m at the cutting edge. Jessica makes it effortless to find trending topics to write about and showcase my skills.",
      image: user1,
      tracking: 0,
    },
    {
      ref: client2Ref,
      title: "Get more leads",
      name: "Matt Cretzman",
      content:
        "Jessica enabled me to rapidly position our agency as an industry authority. The leads we get today already know what we do, why we do it, and how we do it. By the time they come to us, they’re ready to buy.",
      image: user2,
      tracking: "-1px",
    },
    {
      ref: client3Ref,
      title: "Save time",
      name: "Noam Cadouri",
      content:
        "I struggled to consistently create content that would showcase my expertise. With Jessica, what used to take 3 hours a week I can now do in about 30 minutes. And the results are better!",
      image: user3,
      tracking: "0px",
    },
  ];

  return (
    <div className="flex-1 w-full bg-off-white-light rounded-[30px] flex flex-col justify-around overflow-hidden p-3 py-4 max-400:py-3 lg:py-4 lg:p-6 max-400:min-h-[270px] min-h-[280px] md:min-h-0">
      <div className="relative flex-1 max-400:min-h-[160px] min-h-[150px] md:min-h-0">
        {users.map((user, index) => (
          <div
            key={index}
            className="absolute bg-off-white-super-light p-2 pb-1.5 lg:p-4 lg:pb-3 xl:p-6 xl:pb-4 gap-2 lg:gap-3 rounded-[30px] top-1/2 -translate-y-1/2 items-center transition-opacity duration-[1000] ease-linear flex"
            ref={user.ref}
            style={{ opacity: index > 0 ? 0 : 1 }}
          >
            <div className="relative p-2 pl-0">
              <div className="inline-flex justify-center items-center rounded-full border border-solid border-off-white-dark bg-off-white-light absolute right-0 top-0 w-[29px] h-[29px]">
                ❤️
              </div>
              <img
                src={user.image}
                alt="user"
                className="max-400:w-[90px] max-400:h-[90px] w-[100px] h-[100px] md:w-[90px] md:h-[90px] lg:w-[100px] lg:h-[100px] xl:w-[132px] xl:h-[132px] shrink-0 rounded-[8px]"
              />
              <div className="bottom-0 left-1/2 -translate-x-1/2 flex justify-center items-center px-2.5 py-1 absolute bg-off-white-base rounded-[16px] w-[80px] xl:w-[100px]">
                <img src={Star} alt="star" className="w-3 h-3 xl:m-0.5" />
                <img src={Star} alt="star" className="w-3 h-3 xl:m-0.5" />
                <img src={Star} alt="star" className="w-3 h-3 xl:m-0.5" />
                <img src={Star} alt="star" className="w-3 h-3 xl:m-0.5" />
                <img src={Star} alt="star" className="w-3 h-3 xl:m-0.5" />
              </div>
            </div>
            <div className="flex flex-col items-start gap-0.5 sm:gap-1 md:gap-0 xl:gap-2 flex-1 h-full pt-1 lg:pt-0 xl:pt-2">
              <div className="text-black font-figtree max-400:text-[15px] text-[16px] md:text-[15px] lg:text-[16px] font-[700] leading-[130%]">
                {user.title}
              </div>
              <div
                className="text-black font-figtree max-400:text-[12px] text-[14px] md:text-[12px] lg:text-[14px] font-[400] leading-none lg:leading-[130%] min-h-[98px] md:min-h-[72px] lg:min-h-[95px] xl:min-h-[70px] flex items-center"
                // style={{ letterSpacing: user.tracking }}
              >
                {user.content}
              </div>
              <div className="text-black font-figtree max-400:text-[13px] text-[14px] md:text-[13px] lg:text-[14px] font-[600] leading-[130%]">
                {user.name}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-center flex-col gap-2">
        <div className="flex justify-center">
          {userAvatars.map((avatar, index) => (
            <img
              key={index}
              src={avatar}
              alt="avatar"
              className="relative my-0 -mx-[5px] w-8 min-400:w-10 xl:w-12"
            />
          ))}
        </div>
        <div className="text-black font-figtree text-[14px] lg:text-[16px] font-[600] leading-[130%]">
          Join 2000+ experts in Jessica’s network
        </div>
      </div>
    </div>
  );
};

export default CarousalCard;
