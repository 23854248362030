import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import "../styles/Transcription.css";
import { capitalize } from "Utils";
import useUserInfo from "hooks/useUserInfo";
import useInterview from "hooks/useInterview";

const Transcription = () => {
  const [openTranscript, setOpenTranscript] = useState(false);
  const changeTranscriptState = () => {
    setOpenTranscript(!openTranscript);
  };
  const simpleBarRef = useRef();

  const {
    interviewState,
    messages,
    progress,
    transcript,
    isPlayingAudio,
  } = useSelector((state) => state.meeting);
  const { profile } = useUserInfo()
  const { guestMode } = useInterview();
  const [displayMessages, setDisplayMessages] = useState([]);

  useEffect(() => {
    if (interviewState === 0 && isPlayingAudio) {
      let newMessages =
        messages.length > 1 ? messages.slice(0, messages.length - 1) : [];
      let lastMessage = { ...messages[messages.length - 1] };
      lastMessage.content = lastMessage.content.slice(
        0,
        Math.round((lastMessage.content.length * progress) / 100)
      );
      newMessages.push(lastMessage);
      setDisplayMessages(newMessages);
    } else setDisplayMessages(messages);
  }, [messages, isPlayingAudio, interviewState, progress]);

  useEffect(() => {
    if (simpleBarRef.current) {
      const scrollElement = simpleBarRef.current.getScrollElement();
      scrollElement.scrollTop = scrollElement.scrollHeight;
    }
  }, [displayMessages, transcript]);

  return (
    <div className="transcription-container">
      <div className={"transcription" + (openTranscript ? " active" : "")}>
        {openTranscript && (
          <div className="transcription-show">
            <div className="transcription-head" onClick={changeTranscriptState}>
              TRANSCRIPT
              <KeyboardArrowDown />
            </div>
            <div className="transcription-body">
              <SimpleBar className="transcription-history" ref={simpleBarRef}>
                {displayMessages.map((message, index) => (
                  <div key={index} className="message">
                    {message.role === 0
                      ? capitalize(
                          guestMode
                            ? guestMode?.guest?.firstName
                            : profile.firstName
                        )
                      : guestMode?.assistant?.name || "Jessica"}
                    : {message.content}
                  </div>
                ))}
                {transcript.length > 0 && (
                  <div className="message">
                    {capitalize(profile.firstName)}:
                    {transcript.map(({ content }) => content).join()}
                  </div>
                )}
              </SimpleBar>
            </div>
          </div>
        )}
        <div className="transcription-action" onClick={changeTranscriptState}>
          TRANSCRIPT
          <KeyboardArrowUp />
        </div>
      </div>
    </div>
  );
};

export default Transcription;
